import React, { useEffect, useState, useContext } from "react";
import Hader from "../Component/Hader";
import Footer from "../Component/Footer";
import BradeCrum from "../Component/BradeCrum";
import Form from "react-bootstrap/Form";
import "./../assets/css/ProductDetails.css";
import ProductImg from "./../assets/img/productListingImg.png";
import { Card, Col, Container, Row } from "react-bootstrap";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Counter from "../Component/Counter";
import { BsInfoCircleFill } from "react-icons/bs";

import { Link } from "react-router-dom";
import { listActiveSubscriptionMaster } from "../functions/Subscription/SubscriptionMaster";
import {
  getProductVariantsInfoInCart,
  getAvailablityOfProduct,
  getProductVariantsInfo,
} from "../functions/ProductDetails/ProductDetails";
import {
  getProductsDetails,
  getProductsOptions,
} from "../functions/ProductDetails/Productlist";
import {
  getParameterValue,
  listActiveParameterValue,
} from "../functions/ProductDetails/parameterValue";
import { createUserCart } from "../functions/Auth/UserCart";
import { getUserCartByUserId } from "../functions/Auth/UserCart";
import { listPolicyMaster } from "../functions/PolicyMaster/PolicyMaster";
import { Puff } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { MyContext } from "../MyContext";
import { ToastContainer, toast } from "react-toastify";
import Cart from "../Component/Cart";
import MultipleItemCarousel from "../Component/ReletedProductCaresol";
import MobileHeader from "../Component/MobileHeader";
import { lightBlue } from "@mui/material/colors";

const initialState = {
  userId: localStorage.getItem("NicksCoUser"),
  productId: "",
  subsId: null,
  productVariantsId: "",
  quantity: 1,
  productComment: "",
};

const ProductDetails = () => {
  const {
    count,
    setCount,
    setCartCount,
    cartCount,
    isCanvasOpen,
    setcartDetails,
    setSubTotal,
    setIsCanvasOpen,
    setProdVariantsData,
  } = useContext(MyContext);

  const [values, setValues] = useState(initialState);
  const {
    userId,
    productId,
    subsId,
    productVariantsId,
    productComment,
    quantity,
  } = values;

  const params = useParams();
  const PID = params.pid;

  useEffect(() => {
    setCount(1);
  }, []);

  const [productData, setProductData] = useState([]);
  const [productOptionsData, setProductOptionsData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [subsData, setSubsData] = useState([]);
  const [optionsId, setOptionsId] = useState("");
  const [paramVal, setParamVal] = useState("");

  const [popUpModal, setPopModal] = useState(false);
  const [popData, setPopData] = useState({});

  const tog_pop_up = () => {
    // setPopModal(!popUpModal);
    console.log("hey hey");
    setPopModal(true);
  };

  const loadProductDetails = () => {
    getProductsDetails(PID).then((res) => {
      setProductData([res.data]);
      if (res.data.isSubscription) {
        listActiveSubscriptionMaster().then((res1) => {
          const invertedData = res1.data.reverse(); // Reverse the order of the array
          setSubsData(invertedData);
        });
      }
    });
  };
  const loadPolicyMaster = () => {
    listPolicyMaster().then((res) => {
      console.log("res in pm", res.data);
      setPolicyData(res.data);
    });
  };
  // let checkCategory = {};

  // let CategoriesOption = {};
  const [categoriesOption, setCategoriesOption] = useState({});
  const loadProductOptionsData = () => {
    getProductsOptions(PID).then((res) => {
      setProductOptionsData(res.data);
      setValues({ ...values, productId: PID });
      const responseofOptions = res.data;
      const initialCategoriesOption = {};

      for (let i = 0; i < responseofOptions.length; i++) {
        const names = responseofOptions[i].name;
        initialCategoriesOption[names] = "";
      }
      setCategoriesOption(initialCategoriesOption);

      console.log("CategoriesOption:", initialCategoriesOption);
      console.log("res.data", res.data);
      // if (res.data === []) {
      //   if (productData.isSubscription) {
      //     console.log("subscription id stored", productData.isSubscription);
      //     setSubsDetails(true);
      //   }
      // }

      console.log("product options data", res.data);
    });
  };

  const [price, setPrice] = useState(0);
  const handleChangeCategory = (field, value) => {
    console.log("faa", field, value);
    setCategoriesOption((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));

    getParameterValue(value).then((res) => {
      console.log("res in pop", res.data);
      // setPopModal(true);
      setPopData((prevPopData) => ({
        ...prevPopData,
        [field]: res.data.parameterDescription,
      }));
      setOptionsId(res.data.parameterDescription);
      setParamVal(res.data.parameterValue);
    });
  };

  // useEffect(() => {
  //   setPopModal(true);
  //   console.log("heee");
  // }, [categoriesOption]);

  // const [subsDetails, setSubsDetails] = useState(false);
  // const [addcart, setAddCart] = useState(false);

  useEffect(() => {
    // This effect runs whenever categoriesOption changes
    console.log("new che", categoriesOption);
    const allCategoriesFilled = Object.values(categoriesOption).every(
      (val) => val !== ""
    );
    if (allCategoriesFilled) {
      getProductVariantsInfo(PID, categoriesOption).then((res) => {
        console.log("variants info", res.data);
        if (res.data !== null) {
          setValues({ ...values, productVariantsId: res.data._id });
          // if (res.data.isSubscription) {
          //   setSubsDetails(true);
          //   // setAddCart(true);
          // }
          setPrice(res.data.priceVariant);
        }
      });
    }
  }, [categoriesOption]);

  const [formErrors, setFormErrors] = useState({});
  const validateCategoriesOption = (categoriesOption) => {
    const errors = {};
    for (const key in categoriesOption) {
      console.log("key", key);
      if (!categoriesOption[key]) {
        errors[key] = `${key} is required!`;
      }
    }
    console.log("rrr", errors);
    return errors;
  };

  const handleCommentChange = (event) => {
    // Update the productComment state when the textarea value changes
    setValues({ ...values, productComment: event.target.value });
  };

  // const ScrollToTopOnMount = () => {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  //   return null;
  // };
  const [errMessage, setErrMessage] = useState("");
  const handleAddToCart = async () => {
    const UserID = localStorage.getItem("NicksCoUser");
    if (UserID) {
      const errors = validateCategoriesOption(categoriesOption);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        try {
          const categValues = Object.values(categoriesOption);
          const availablity = await getAvailablityOfProduct(
            productId,
            categValues
          );
          console.log("ddd", availablity.data);
          if (!availablity.data.isOk) {
            // toast.error(availablity.data.message);
            setErrMessage(availablity.data.message);
          } else {
            const res = await createUserCart({
              ...values,
              userId: UserID,
            });
            console.log(res);
            if (res.data.isOk) {
              let initialCategoriesOption = {};
              for (let i = 0; i < productOptionsData.length; i++) {
                const names = productOptionsData[i].name;
                initialCategoriesOption[names] = "";
              }
              setCategoriesOption(initialCategoriesOption);
              // setIsSubs("");
              setValues({ ...values, productComment: "" });
              setCount(1);
              toast.success("Product added to cart");

              const res1 = await getUserCartByUserId(UserID);
              const resp = res1.data.data;
              console.log("resp", resp);
              const invertedData = resp.reverse(); // Reverse the order of the array
              setcartDetails(invertedData);
              setSubTotal(res1.data.subTotal);
              setCartCount(resp.length);
              // setIsCanvasOpen(!isCanvasOpen);

              const promises = resp.map((item) => {
                if (item.productVariantsId !== null)
                  return getProductVariantsInfoInCart(
                    item.productVariantsId
                  ).then((res1) => {
                    console.log("product variants info", res1.data[0]);
                    return res1.data[0]; // Return the object directly
                  });
              });

              const variantDataArray = await Promise.all(
                promises.filter(Boolean)
              );

              setProdVariantsData(variantDataArray);
              setErrMessage("");
            }else if(res.data.flag == 1){
              toast.error(res.data.message);
            } else {
              toast.error("Product is out of Stock");
            }

            const res2 = await getUserCartByUserId(userId);
            const resp = res2.data.data;
            setCartCount(resp.length);
          }
        } catch (error) {
          console.error("Error creating cart or fetching cart:", error);
        }
      }
    } else {
      // window.location.replace("/login");
      const errors = validateCategoriesOption(categoriesOption);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        try {
          const categValues = Object.values(categoriesOption);
          const availablity = await getAvailablityOfProduct(
            productId,
            categValues
          );
          console.log("ddd out", availablity.data);
          if (!availablity.data.isOk) {
            // toast.error(availablity.data.message);
            setErrMessage(availablity.data.message);
          } else {
            const res = await createUserCart({
              ...values,
              guestCartData: localStorage.getItem("guestCartData")
                ? JSON.parse(localStorage.getItem("guestCartData"))
                : [],
            });
            if (res.data.isOk) {
              let initialCategoriesOption = {};
              for (let i = 0; i < productOptionsData.length; i++) {
                const names = productOptionsData[i].name;
                initialCategoriesOption[names] = "";
              }
              setCategoriesOption(initialCategoriesOption);
              // setIsSubs("");
              setValues({ ...values, productComment: "" });
              setCount(1);
              toast.success("Product added to cart");

              const dataString = JSON.stringify(res.data.data);
              localStorage.setItem("guestCartData", dataString);

              // const res1 = await getUserCartByUserId(UserID);
              const resp = res.data.data;
              const invertedData = resp.reverse(); // Reverse the order of the array
              setcartDetails(invertedData);

              let subAmt = 0;

              const mappedCart = resp.map((item) => {
                const totalAmount = item.amount * item.quantity;
                subAmt = subAmt + totalAmount;
                // return { ...item, totalAmount: totalAmount };
              });
              setSubTotal(subAmt);
              // setIsCanvasOpen(!isCanvasOpen);
              const cartArray = JSON.parse(
                localStorage.getItem("guestCartData")
              );
              setCartCount(cartArray.length);
              console.log("Cart count:", cartArray);

              const promises = cartArray.map((item) => {
                console.log("variantid", item.productVariantsId);
                if (item.productVariantsId !== null)
                  return getProductVariantsInfoInCart(
                    item.productVariantsId
                  ).then((res1) => {
                    console.log("product variants info", res1.data[0]);
                    return res1.data[0]; // Return the object directly
                  });
              });

              const variantDataArray = await Promise.all(
                promises.filter(Boolean)
              );

              setProdVariantsData(variantDataArray);
              setErrMessage("");
            }else if(res.data.flag == 1){
              toast.error(res.data.message);
            } else {
              toast.error("Product is out of Stock");
            }

            // const res2 = await getUserCartByUserId(userId);
            // const resp = res2.data.data;
            // setCartCount(resp.length);
          }
        } catch (error) {
          console.error("Error creating cart or fetching cart:", error);
        }
      }
    }
  };

  const handleAddSubscription = async (subsid) => {
    if (userId) {
      setValues({ ...values, subsId: subsid });
      const errors = validateCategoriesOption(categoriesOption);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        createUserCart({ ...values, subsId: subsid })
          .then((res) => {
            // console.log("create carting",res);

            if (res.data.isOk) {
              let initialCategoriesOption = {};
              for (let i = 0; i < productOptionsData.length; i++) {
                const names = productOptionsData[i].name;
                initialCategoriesOption[names] = "";
              }
              setCategoriesOption(initialCategoriesOption);
              // setIsSubs("");
              setOptionsId("");
              setCount(1);
              setValues({ ...values, subsId: null, productComment: "" });
              toast.success("Product added to cart");
              getUserCartByUserId(userId).then((res1) => {
                const resp = res1.data.data;
                const invertedData = resp.reverse(); // Reverse the order of the array
                setcartDetails(invertedData);
                setSubTotal(res1.data.subTotal);
                setCartCount(resp.length);
                // setIsCanvasOpen(!isCanvasOpen);
              });
            }else if(res.data.flag == 1){
              toast.error(res.data.message);
            } else {
              toast.error("Product is out of Stock");
            }

            return getUserCartByUserId(userId);
          })
          .then((res1) => {
            const resp = res1.data.data;
            setCartCount(resp.length);
          })
          .catch((error) => {
            console.error("Error creating cart or fetching cart:", error);
          });
      }
    } else {
      // window.location.replace("/login");
      const errors = validateCategoriesOption(categoriesOption);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        try {
          const categValues = Object.values(categoriesOption);
          const availablity = await getAvailablityOfProduct(
            productId,
            categValues
          );
          console.log("ddd out", availablity.data);
          if (!availablity.data.isOk) {
            // toast.error(availablity.data.message);
            setErrMessage(availablity.data.message);
          } else {
            const res = await createUserCart({
              ...values,
              subsId: subsid,
              guestCartData: localStorage.getItem("guestCartData")
                ? JSON.parse(localStorage.getItem("guestCartData"))
                : [],
            });
            if (res.data.isOk) {
              let initialCategoriesOption = {};
              for (let i = 0; i < productOptionsData.length; i++) {
                const names = productOptionsData[i].name;
                initialCategoriesOption[names] = "";
              }
              setCategoriesOption(initialCategoriesOption);
              setValues({ ...values, productComment: "" });
              setCount(1);
              toast.success("Product added to cart");

              const dataString = JSON.stringify(res.data.data);
              localStorage.setItem("guestCartData", dataString);

              // const res1 = await getUserCartByUserId(UserID);
              const resp = res.data.data;
              const invertedData = resp.reverse(); // Reverse the order of the array
              setcartDetails(invertedData);

              let subAmt = 0;

              const mappedCart = resp.map((item) => {
                const totalAmount = item.amount * item.quantity;
                subAmt = subAmt + totalAmount;
              });
              setSubTotal(subAmt);
              // setIsCanvasOpen(!isCanvasOpen);
              const cartArray = JSON.parse(
                localStorage.getItem("guestCartData")
              );
              setCartCount(cartArray.length);
              console.log("Cart count:", cartArray);

              const promises = cartArray.map((item) => {
                console.log("variantid", item.productVariantsId);
                if (item.productVariantsId !== null)
                  return getProductVariantsInfoInCart(
                    item.productVariantsId
                  ).then((res1) => {
                    console.log("product variants info", res1.data[0]);
                    return res1.data[0]; // Return the object directly
                  });
              });

              const variantDataArray = await Promise.all(
                promises.filter(Boolean)
              );

              setProdVariantsData(variantDataArray);

              setErrMessage("");
            }else if(res.data.flag == 1){
              toast.error(res.data.message);
            } else {
              toast.error("Product is out of Stock");
            }
          }
        } catch (error) {
          console.error("Error creating cart or fetching cart:", error);
        }
      }
    }
  };

  // const [issubs, setIsSubs] = useState("paypalPayment");

  useEffect(() => {
    setValues({ ...values, quantity: count });
  }, [count]);

  useEffect(() => {
    loadProductDetails();
    loadPolicyMaster();
    loadProductOptionsData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Cart />

          <Hader Color="#000" />
          <ToastContainer />
          <MobileHeader />
          <BradeCrum Title="Product Details" />
          <div className="productMainDiv">
            <Container>
              {productData.map((item) => (
                <Row>
                  <Col lg={6}>
                    <div className="proudctImgDiv">
                      <img
                        // src={ProductImg}
                        src={`${process.env.REACT_APP_API_URL_COFFEE}/${item.productImage}`}
                        // style={{ width: "300px", height: "350px" }}
                        alt="img"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <h1>
                        {/* BLACK TEA LATTE Bright, Brisk, Smooth Classic Black Tea, Milk */}
                        {item.productName}
                      </h1>
                      <div>
                        {/* <h5>Product Description</h5> */}
                        <p>
                          {/* A dreamy, tropical coconut white tea. White Coconut Cr
                  <em>è</em>me tea has a light body and smooth creamy texture. A
                  customer favorite both hot and iced. */}
                          {item.productDescription}
                        </p>
                      </div>
                      <div
                        className="priceDiv"
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        ${/* 3.50Price */}
                        {price ? ` ${(item.basePrice + price).toFixed(2)}` : item.basePrice}
                      </div>
                      <div className="sizeDiv">
                        {productOptionsData.map((pod) => (
                          <>
                            <div className="sizeDiv">{pod.name}</div>
                            <ul className="details_item_color ul_li">
                              <select
                                key={pod._id}
                                // className="optionSelector"
                                className={`optionSelector form-control ${
                                  formErrors && formErrors[pod.name]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={pod.name}
                                value={categoriesOption[pod.name]}
                                onChange={(e) =>
                                  handleChangeCategory(pod.name, e.target.value)
                                }
                              >
                                <option value="1">Select</option>
                                {pod.parameterValues.map((pv) => (
                                  <option key={pv.id} value={pv.id}>
                                    {pv.name}
                                  </option>
                                ))}
                              </select>
                              {formErrors && formErrors[pod.name] && (
                                <div className="text-danger">
                                  {formErrors[pod.name]}
                                </div>
                              )}
                              {/* {popData[pod.name] ? popData[pod.name] : null} */}
                            </ul>
                          </>
                        ))}
                        {errMessage ? (
                          <div className="text-danger">{errMessage}</div>
                        ) : null}
                      </div>

                      {/* <div className="QfrfFD cell">
                        <div data-hook="text-option" className="XgzkJs">
                          <label
                            data-hook="text-option-title"
                            className="L2vyqK"
                          >
                         
                            Special Comment
                          </label>
                          <div
                            data-content-hook="popover-content-error-tooltip-undefined"
                            className="s__7JjfCi sVIBAiO sb0jSGm oVyBdJb---skin-5-error hyRJP6"
                            data-hook="error-tooltip"
                          >
                            <div
                              className="sErZtr5"
                              data-hook="popover-element"
                            >
                              <div className="K96Ioq">
                                <textarea
                                  data-hook="text-option-text-area"
                                  maxLength={500}
                                  className="productTextArea"
                                  rows={4}
                                  style={{ width: "100%" }}
                                  // placeholder="500"
                                  // defaultValue={""}
                                  name="productComment"
                                  value={productComment}
                                  onChange={handleCommentChange} // Add onChange event handler
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="sizeDiv">
                  <div className="sizeDiv">DRINK</div>
                  <Form.Select aria-label="Default select example">
                    <option>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                         </div> */}

                      <Row>
                        <Col lg={3} md={4} xs={3}>
                          <div className="sizeDiv">Quantity</div>
                          <Counter />
                        </Col>
                        <Col lg={9} md={8} xs={8}>
                          {optionsId ? (
                            <Card
                              style={{ borderColor: "black" }}
                              className="mt-4"
                            >
                              <div
                                className="infoCard"
                                style={{ margin: "10px" }}
                              >
                                {/* <h5>Product Varinats Info</h5> */}
                                <p>
                                  {" "}
                                  <i>
                                    <b>{paramVal}</b>
                                  </i>
                                </p>
                                <p>
                                  <i> {optionsId}</i>
                                </p>
                              </div>
                            </Card>
                          ) : null}
                        </Col>
                      </Row>

                      <Row>
                        {item.isOutOfStock ? null : (
                          <>
                            <div className="" >
                              {item.isSubscription ? (
                                <>
                                  <p className="" >Price Options</p>
                                </>
                              ) : null}
                            </div>
                              {subsData.map((subs) => (
                                <Col lg={6}>
                                  <div className="radio-div">
                                    <div style={{ width: "90%" }}>
                                      <p style={{ marginBottom: "auto" }}>
                                        {subs.title}
                                      </p>
                                      <p style={{ marginBottom: "auto" }}>
                                        <span className="small-p">
                                          Subscribe and save{" "}
                                          {subs.savePercentage} %
                                        </span>
                                      </p>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      <button
                                        type="button"
                                        style={{ fontSize: "small" }}
                                        className="btn btn-sm btn_secondary text-uppercase"
                                        // className="btn btn-submit"
                                        // to="#!"
                                        onClick={() => {
                                          handleAddSubscription(subs._id);
                                        }}
                                        // disabled={addcart}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                          </>
                        )}
                      </Row>

                      <Row>
                        {subsData.length === 0 ? (
                          <Row style={{ marginTop: "20px" }}>
                            <Col lg={12}>
                              <button
                                type="button"
                                className="btn btn_secondary text-uppercase"
                                // className="btn btn-submit"
                                // to="#!"
                                disabled={item.isOutOfStock}
                                onClick={handleAddToCart}
                                // disabled={addcart}
                              >
                                {item.isOutOfStock
                                  ? "Out of Stock"
                                  : "Add To Cart"}
                              </button>
                            </Col>
                          </Row>
                        ) : null}
                      </Row>

                      {/* <div className="addBtnDiv">
                        <Link className="shopNowBtn sizeDiv" to="#">
                          Add To Cart
                        </Link>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              ))}

              {/* POPUP Modal */}
              {/* <Modal
                isOpen={optionsId && popUpModal}
                toggle={() => {
                  tog_pop_up();
                }}
                centered
              >
                <ModalHeader
                  className="bg-light p-3"
                  toggle={() => {
                    setPopModal(false);
                  }}
                >
                  {paramVal}
                </ModalHeader>
                <form>
                  <ModalBody style={{ backgroundColor: "white" }}>
                
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginTop: "20px",
                      }}
                    >
                      <h4>
                        <i>{optionsId}</i>
                      </h4>
                    </div>
                  </ModalBody>
                </form>
              </Modal> */}

              {policyData &&
                policyData.map((pd, index) =>
                  pd.IsActive ? (
                    <Card className="proudctDetailsCard">
                      <Row key={index}>
                        <Col lg={12}>
                          {/* <Card className="infoCard"> */}
                          <h5>{pd.policyName}</h5>
                          <p>{pd.policyDesc}</p>
                          {/* </Card> */}
                        </Col>
                      </Row>
                    </Card>
                  ) : null
                )}

              {/* <Card className="proudctDetailsCard">
                <div>
                  <h5>PRODUCT INFO</h5>
                  <p>
                    A dreamy, tropical coconut white tea. White Coconut Cr
                    <em>è</em>me tea has a light body and smooth creamy texture.
                    A customer favorite both hot and iced.
                  </p>
                </div>
              </Card> */}
              {/* <Card className="proudctDetailsCard">
                <h5>RETURN & REFUND POLICY</h5>
                <h6>WHAT CAN YOU RETURN AT Nick's Roasting Co.?</h6>
                <p>
                  Nick's Roasing Co gift cards, merchandise, packaged coffee,
                  and packaged tea are returnable at Nick's Roasting Co. To get
                  your money back or store credit, the item has to be unused and
                  unopened, you need to bring in the original receipt, and it
                  has to be within 30 days of purchase date on the receipt
                </p>
                <h6>
                  With food and drink, you can get it replaced with a new one.
                </h6>
                <p>
                  Nick's Roasting Co. does its best to stand behind their
                  barista promise: “Love your beverage or let us know. We’ll
                  always make it right.” If you’re unhappy with what you
                  ordered, just tell the barista and they will make you a new
                  drink or get you another of the food item.
                </p>
                <h6>I don’t like the drink I ordered. Can I return it?</h6>
                <p>
                  If you ordered a drink or food item and you’re not happy with
                  it for any reason, just let the barista know (politely) and
                  they will remake it for you.
                </p>
                <h6></h6>
              </Card>
              <Card className="proudctDetailsCard">
                <h5>Shiping Information</h5>
                <div data-hook="info-section-description" className="zp8uy2">
                  <p>
                    We offer standard shipping on all our coffee subscription
                    boxes &amp; any other product Purchase. Our coffee is
                    roasted fresh and packaged with care to ensure it arrives at
                    your doorstep at its peak flavor. We do offer international
                    shipping at this time.If you have any questions about our
                    shipping policy, please do not hesitate to contact us.
                  </p>
                  <p>
                    Shipping Policy This document sets out the shipping policy
                    that applies to customers that make a purchase
                    at&nbsp;www.nicksroastingco.com. If you have any questions,
                    please contact our customer service team at
                    nicksroastingco@gmail.com
                  </p>
                  <p>Shipping Options &amp; Delivery Costs</p>
                  <p>
                    We offer the following shipping options - you will be asked
                    to select a shipping method at checkout.
                  </p>
                  <p>Standard Shipping</p>
                  <p>5 Days Shipping</p>
                  <p>3 Days Shipping</p>
                  <p>Order Processing Time</p>
                  <p>
                    All orders placed &amp; processed and dispatched the same
                    day, all orders placed after will be dispatched the next
                    day. All orders placed during the weekend or on a public
                    holiday will be sent from our warehouse on Monday or on the
                    next business day.
                  </p>
                  <p>Delivery Address &amp; P.O. Boxes</p>
                  <p>
                    Please note that we are unable to modify the delivery
                    address once you have placed your order. We are sorry but we
                    do not ship to P.O. boxes.
                  </p>
                  <p>International Orders</p>
                  <p>
                    Your package may be subject to import duties and taxes. You,
                    as the customer, are responsible for paying those fees. We
                    recommend that you check with your local customs office
                    before placing an order on our website as these fees can
                    sometimes be significant and we are unable to calculate
                    these for you.
                  </p>
                  <p>Tracking Your Order</p>
                  <p>
                    Once your order has been dispatched, we will send you a
                    confirmation email with tracking information. You will be
                    able to track your package directly on the carrier’s
                    website.
                  </p>
                  <p>Returns, Refunds, and Exchanges</p>
                  <p>
                    We want you to be completely happy with your purchase -
                    please read our return &amp; refund policy for detailed
                    information about our processes.
                  </p>
                </div>
              </Card> */}
              <MultipleItemCarousel Title="Releted Product" productId={PID} />
            </Container>
          </div>
        </div>
      )}
      <Footer />
    </React.Fragment>
  );
};

export default ProductDetails;
