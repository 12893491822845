import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./../assets/css/login.css";
import Hader from "../Component/Hader";
import { Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import MobileHeader from "../Component/MobileHeader";
import { useLocation } from "react-router-dom";

// const loginSchema = Yup.object().shape({
//   password: Yup.string()
//     .min(8, "Too Short!")
//     .max(50, "Too Long!")
//     .required("Required"),
//   email: Yup.string().email("Invalid email").required("Required"),
// });

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  // const handleSubmit = (values, { setSubmitting }) => {
  //   setTimeout(() => {
  //     alert(JSON.stringify(values, null, 2));
  //     setSubmitting(false);
  //   }, 400);
  // };

  const navigate = useNavigate();

  const [values, setValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const { email, password } = values;
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const location = useLocation();

  const login = (e) => {
    e.preventDefault();
    console.log("logged");
    setIsSubmit(true);
    let erros = validate(values);
    setFormErrors(erros);
    if (Object.keys(erros).length === 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL_COFFEE}/api/login`, {
          values,
          guestCartData: localStorage.getItem("guestCartData")
            ? JSON.parse(localStorage.getItem("guestCartData"))
            : [],
        })
        .then((res) => {
          if (res.data.isOk) {
            const Res = res.data;
            localStorage.setItem("NicksCoUser", Res.data._id);
            toast.success("You have successfully logged in");
            if (res.data.guestCartData) {
              localStorage.removeItem("guestCartData");
            }
            if (
              location.state &&
              location.state.from &&
              (location.state.from.pathname === "/forgot-password" ||
                location.state.from.pathname === "/register")
            ) {
              navigate("/");
            } else {
              setTimeout(() => {
                navigate(-1);
              }, 2000);
              // navigate(-1);
            }
          } else {
            toast.error("Authentication failed!");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Authentication failed!");
        });
    }
  };

  const [errEmail, seterrEmail] = useState(false);
  const [errPassword, setErrPassword] = useState(false);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email === "") {
      errors.email = "Email is required!";
      seterrEmail(true);
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
      seterrEmail(true);
    } else {
      seterrEmail(false);
    }
    if (values.password === "") {
      errors.password = "Password is required!";
      setErrPassword(true);
    }
    if (values.password !== "") {
      setErrPassword(false);
    }
    return errors;
  };
  const validClassEmail =
    errEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassPassword =
    errPassword && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  return (
    <React.Fragment>
      <div>
    <Hader Color="#000" />
        <MobileHeader />
      </div>
      <ToastContainer />
      <Container>
        <div
          className="loginDiv"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <Card className="loginCard">
            <h1>Login</h1>

            <form>
              <div className="mb-3">
                <input
                  placeholder="Enter Your Email"
                  type="email"
                  name="email"
                  value={email}
                  style={{ marginBottom: 0 }}
                  className={validClassEmail}
                  onChange={handleChange}
                />
                {isSubmit && (
                  <p className="text-danger" style={{ textAlign: "left" }}>
                    {formErrors.email}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <input
                  placeholder="Enter Your Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  style={{ marginBottom: 0 }}
                  className={validClassPassword}
                />
                {isSubmit && (
                  <p className="text-danger" style={{ textAlign: "left" }}>
                    {formErrors.password}
                  </p>
                )}
              </div>
              <p>
                Don’t have an account ?
                <span>
                  <Link className="signUpLink" to="/register">
                    {" "}
                    Register
                  </Link>
                </span>
              </p>
              <p>
                <Link className="signUpLink" to="/forgot-password">
                  Forget Password?
                </Link>
              </p>
              <button type="submit" onClick={login}>
                Login
              </button>
            </form>
          </Card>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Login;
