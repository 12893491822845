import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../assets/css/relatedProducts.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  getLatestProducts,
  listAllProductsData,
} from "../functions/ProductDetails/ProductDetails";
// const  { productListingImg } from "../assets/img/productListingImg.png";
import ProductImg from "../assets/img/productListingImg.png";
import { FaAngleDoubleRight, FaCoffee } from "react-icons/fa";

const LatestProductCarousel = () => {
  const [latestProductData, setLatestProductsData] = useState([]);

  const loadRelatedProductsData = () => {
    listAllProductsData().then((res) => {
      const latestData = res.data.filter(
        (data) => data.isPopularProduct === true && data.IsActive === true
      );
      console.log("latest products", latestData);
      setLatestProductsData(latestData);
    });
  };

  const loadProductData = (bid) => {
    window.location.replace(`/productDetails/${bid}`);
    // window.location.reload();
  };

  useEffect(() => {
    loadRelatedProductsData();
  }, []);
  // const reletedProductData = [
  //   {
  //     title: "title 1",
  //     Img: ProductImg,
  //     Price: "$101",
  //   },
  //   {
  //     title: "title 1",
  //     Img: ProductImg,
  //     Price: "$101",
  //   },
  //   {
  //     title: "title 1",
  //     Img: ProductImg,
  //     Price: "$101",
  //   },
  //   {
  //     title: "title 1",
  //     Img: ProductImg,
  //     Price: "$101",
  //   },
  //   {
  //     title: "title 1",
  //     Img: ProductImg,
  //     Price: "$101",
  //   },
  // ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1025, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default to 3 items
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Container style={{ marginTop: "80px", marginBottom: "80px" }}>
        {/* <h2 className="open-header">LATEST PRODUCTS</h2> */}
        <div
          className="section_title text-uppercase text-center"
        // style={{ marginTop: "60px"}}
        >
          <h1 className="small_title wow fadeInUp" data-wow-delay=".1s">
            <FaCoffee className="fas fa-coffee" /> POPULAR PRODUCTS
          </h1>
          {/* <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
              Latest Blog
            </h3> */}
        </div>
        {/* <h4 className="reletedTitle">LATEST PRODUCTS</h4> */}

        <Slider {...settings}>
          {latestProductData?.map((item, index) => (
            <Link
              // to={`/productDetails/${data._id}`}
              className="element-item chocolate greantea"
              data-category="chocolate"
              onClick={() => loadProductData(item._id)}
              key={item._id}
            // onClick={() => window.location.reload()}
            >
              <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px" }}>
                <img
                  src={`${process.env.REACT_APP_API_URL_COFFEE}/${item.productImage}`}
                  alt="image_not_found"
                  style={{
                    width: "100%",
                    maxWidth: "240px",
                    height: "auto",
                    maxHeight: "430px",
                    margin: "0 auto",
                  }}
                />
                <h5 style={{ textAlign: "center", margin: "10px 0", color: "black" }} >
                  {item.productName}
                  <br />
                  ${item.basePrice}
                </h5>
              </div>
            </Link>
          ))}
        </Slider>

      </Container>
    </React.Fragment>
  );
};

export default LatestProductCarousel;
