import axios from "axios";

export const listActiveSubscriptionMaster = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/listActive/SubscriptionMaster`
  );
};

export const getSubscriptionProductofUser = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/subscription-products-user/${userId}`
  );
};
