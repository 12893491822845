import React, { useEffect, useState } from "react";
// import bg_01 from "../assets/img/coffee_mug.jpg";
import bg_01 from "../assets/img/whatWeDoBG.jpg";
import "../assets/css/whatwedo.css";
import { Button } from "@mui/material";
import { Card, Col, Container, Row } from "react-bootstrap";

import img_01 from "../assets/img/11062b_54b0313b65a44b479c9f065e126f7c06~mv2.webp";
import img_02 from "../assets/img/11062b_2974cb752e974ea192d619ea0d64120d~mv2.webp";
import img_03 from "../assets/img/11062b_ca88a6f079324d7c98963f33c1f2d48f~mv2.webp";
import { listContent } from "../functions/CMS/cmsContent";

const Whatwedo = () => {
  const [contentData, setContentData] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setContentData(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };

  useEffect(() => {
    loadContentData();
  }, []);
  const whatWeData = [
    {
      title: "LIGHTLY ROASTED COFFEE BEANS",
      img: img_01,
      des: "Our Lightly Roasted Coffee Beans offer the world a chance to brighten every day with a whole lot of flavor and inspiration. Try these to receive the perfect balance between bold flavor and subtle sweetness.",
    },
    {
      title: "TRIPLE BLENDED COFFEE BEANS",
      img: img_02,
      des: "With our Triple Blended Coffee Beans you can finally feel like a professional barista. Bright and clean with an intense burst of flavor, these full-bodied beans strike a perfect balance between the most delicate roasts and fruitful aromas.",
    },
    {
      title: "COLUMBIAN BLENDED ESPRESSO BEANS",
      img: img_03,
      des: "Skillfully roasted and carefully blended, our Columbian Blended Espresso Beans don’t just wake you up in the morning, they pack a lasting punch that is sure to leave you wanting more. Silky smooth and full of layered flavors, there’s really no better way to start your day.",
    },
    // {
    //   title: "BLENDED",
    // },
  ];
  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${bg_01})`,
          backgroundPosition: "center",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
        className="pd-tp-50 open-bg"
      >
        <Container>
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive && c.ContentFor === "what we Offer Heading" && (
                  <>
                    <h1
                      className="open-header text-light"
                      dangerouslySetInnerHTML={{
                        __html: c.ContentUpload,
                      }}
                    ></h1>
                  </>
                )}
              </React.Fragment>
            );
          })}
          <Row>
            {/* {whatWeData?.map((data) => (
              <Col lg={4} md={6}>
                <Card className="whatWeDoCard">
                  <div>
                    <div className="whatWeDoTitle">
                      <div>{data.title}</div>
                    </div>
                    <div
                      style={{ textAlign: "justify", padding: "3%" }}
                      className="m-2 test-dark"
                    >
                      <p>{data.des}</p>
                    </div>
                  </div>
                </Card>
                <br className="line" />
              </Col>
            ))} */}

            <Col lg={4} md={6}>
              <Card className="whatWeDoCard">
                <div>
                  {contentData.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive &&
                          c.ContentFor === "what we Offer Card heading 1" && (
                            <div className="whatWeDoTitle">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></div>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                  {contentData.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive &&
                          c.ContentFor === "what we Offer Card content 1" && (
                            <div
                              style={{ textAlign: "justify", padding: "3%" }}
                              className="m-2 test-dark"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></p>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Card>
              <br className="line" />
            </Col>
            {/* 2 */}
            <Col lg={4} md={6}>
              <Card className="whatWeDoCard">
                <div>
                  {contentData.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive &&
                          c.ContentFor === "what we Offer Card heading 2" && (
                            <div className="whatWeDoTitle">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></div>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                  {contentData.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive &&
                          c.ContentFor === "what we Offer Card content 2" && (
                            <div
                              style={{ textAlign: "justify", padding: "3%" }}
                              className="m-2 test-dark"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></p>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Card>
              <br className="line" />
            </Col>
            {/* 3 */}

            <Col lg={4} md={6}>
              <Card className="whatWeDoCard">
                <div>
                  {contentData.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive &&
                          c.ContentFor === "what we Offer Card heading 3" && (
                            <div className="whatWeDoTitle">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></div>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                  {contentData.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive &&
                          c.ContentFor === "what we Offer Card content 3" && (
                            <div
                              style={{ textAlign: "justify", padding: "3%" }}
                              className="m-2 test-dark"
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                              ></p>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Card>
              <br className="line" />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Whatwedo;
