import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import ReturnImg from "./../assets/img/whatWeDoBG.jpg";
import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";
import { listContent } from "../functions/CMS/cmsContent";
import { Puff } from "react-loader-spinner";

const StorePolicy = () => {
  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
      <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum />
          <section className="about_section sec_ptb_120">
            <div className="container">
              <div className="row align-items-center justify-content-lg-between">
                {/* <div className="col-lg-6 order-last">
                  <div
                    className="about_image2 wow fadeInUp pd-bottom-50"
                    data-wow-delay=".1s"
                  >
                    <img src={ReturnImg} alt="image_not_found" />
                  </div>
                </div> */}
                <div className="">
                  <div className="about_content">
                    <div className="section_title text-uppercase">
                      {/* <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                    <FaCoffee className="fas fa-coffee" /> Store Policy
                  </h2>
                  <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
                    Customer First: Understanding Our Store Policies
                  </h3> */}
                      {cmsContent.map((c) => {
                        return (
                          <React.Fragment key={c._id}>
                            {c.IsActive &&
                              c.ContentFor === "store policy heading" && (
                                <>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: c.ContentUpload,
                                    }}
                                  ></p>
                                </>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    {/* <p className="wow fadeInUp" data-wow-delay=".3s">
                  I'm a paragraph. Click here to add your own text and edit me.
                  It’s easy. Just click “Edit Text” or double click me to add
                  your own content and make changes to the font.
                </p> */}
                    <ul className="about_info ul_li_block">
                      <li className="wow fadeInUp" data-wow-delay=".4s">
                        {/* <h4 className="text-uppercase">Customer Care</h4> */}
                        {/* <p className="mb-0" style={{ textAlign: "justify" }}>
                      I’m a customer care section. I’m a great place to write a
                      long text about your company and your services, and, most
                      importantly, how to contact your store with queries.
                      Writing a detailed customer care policy is a great way to
                      build trust and reassure your customers that they can buy
                      with confidence. <br />
                      <br />
                      I'm the second paragraph in your customer care section.
                      Click here to add your own text and edit me. It’s easy.
                      Just click “Edit Text” or double click me to add details
                      about your policy and make changes to the font. I’m a
                      great place for you to tell a story and let your users
                      know a little more about you.
                    </p> */}
                        {cmsContent.map((c) => {
                          return (
                            <React.Fragment key={c._id}>
                              {c.IsActive &&
                                c.ContentFor === "store policy content" && (
                                  <>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: c.ContentUpload,
                                      }}
                                    ></p>
                                  </>
                                )}
                            </React.Fragment>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div>
                  <div className="about_content">
                    <ul className="about_info ul_li_block">
                      <li className="wow fadeInUp" data-wow-delay=".4s">
                        {/* <h4 className="text-uppercase  mt-4">Privacy & Safety</h4>
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      I’m a privacy & safety policy section. I’m a great place
                      to inform your customers about how you use, store, and
                      protect their personal information. Add details such as
                      how you use third-party banking to verify payment, the way
                      you collect data or when will you contact users after
                      their purchase was completed successfully. <br />
                      <br />
                      Your user’s privacy is of the highest importance to your
                      business, so take the time to write an accurate and
                      detailed policy. Use straightforward language to gain
                      their trust and make sure they keep coming back to your
                      site!
                    </p> */}

                        {cmsContent.map((c) => {
                          return (
                            <React.Fragment key={c._id}>
                              {c.IsActive &&
                                c.ContentFor ===
                                  "store policy privacy content" && (
                                  <>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: c.ContentUpload,
                                      }}
                                    ></p>
                                  </>
                                )}
                            </React.Fragment>
                          );
                        })}
                      </li>
                      {/* <li className="wow fadeInUp" data-wow-delay=".4s">
                    <h4 className="text-uppercase mt-4">Wholesale Inquiries</h4>
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      I’m a wholesale inquiries section. I’m a great place to
                      inform other retailers about how they can sell your
                      stunning products. Use plain language and give as much
                      information as possible in order to promote your business
                      and take it to the next level! <br />
                      <br />
                      I'm the second paragraph in your wholesale inquiries
                      section. Click here to add your own text and edit me. It’s
                      easy. Just click “Edit Text” or double click me to add
                      details about your policy and make changes to the font.
                      I’m a great place for you to tell a story and let your
                      users know a little more about you.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    <h4 className="text-uppercase mt-4">Payment Methods</h4>
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      - Credit / Debit Cards <br />
                      - PAYPAL <br />- Offline Payments
                    </p>
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default StorePolicy;
