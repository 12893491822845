import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import tick from "../../assets/img/wrong2.jpg";
import {
  cancelSubscription,
  paymentCancel,
} from "../../functions/Orders/Order";

const PaymentFailure = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const subscription_id = urlParams.get("subscription_id");
  const ba_token = urlParams.get("ba_token");

  //   const payerID = urlParams.get("PayerID");

  useEffect(() => {
    // if (token) {
    if (token && !subscription_id && !ba_token) {
      paymentCancel(token).then((order) => {
        console.log("cancel order", order);
      });
    }
    if (subscription_id && token && ba_token) {
      cancelSubscription(subscription_id)
        .then((order) => {
          console.log("subscriton  cancel order", order);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  document.title = "Payment Failed";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        {/* <ParticlesAuth> */}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  {/* <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4 text-center">
                    <div className="avatar-lg mx-auto mt-2">
                      {/* <div className="avatar-title bg-light text-success display-3 rounded-circle"> */}
                      {/* <i className="ri-close-circle-fill"></i> */}
                      <img src={tick} height="150px" width="150px" />
                      {/* </div> */}
                    </div>
                    <div className="mt-4 pt-2">
                      <h4>Payment Failed</h4>
                      <p className="text-muted mx-4">
                        There was some issue while processing your payment
                      </p>
                      <p className="text-muted mx-4">
                        If money was debited from your account then it would be
                        refunded in 3-5 business days.
                      </p>
                      <div className="mt-4">
                        <Link
                          to="/"
                          className="btn btn-light w-100"
                          style={{ fontWeight: "bold" }}
                        >
                          Try Again
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* </ParticlesAuth> */}
      </div>
    </React.Fragment>
  );
};

export default PaymentFailure;
