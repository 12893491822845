// import * as React from "react";
import React, { useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CategoryProductListData } from "../functions/ProductDetails/Productlist";
import { MyContext } from "../MyContext";

const SortSelect = ({
  selectedOption,
  setSelectedOption,
  CID,
  // handleSelectChange,
}) => {
  const {
    CategProductData,
    setCategProductData,
    sortOption,
    setSortOption,
    variants,
    setVariants,
  } = useContext(MyContext);

  const [sorts, setSorts] = React.useState("");
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setSortOption(event.target.value);
    const option = event.target.value;

    CategoryProductListData(option, CID, variants).then((res) => {
      console.log("product Data", res.data.data);
      setCategProductData(res.data.data);
    });
  };
  const handleChange = (event) => {
    setSorts(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Sort</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        // value={sorts}
        value={selectedOption}
        label="Sort"
        onChange={handleSelectChange}
      >
        <MenuItem value="1">Newest</MenuItem>
        <MenuItem value="2">Price (low to high)</MenuItem>
        <MenuItem value="3">Price (high to low)</MenuItem>
        <MenuItem value="4">Name A to Z</MenuItem>
        <MenuItem value="5">Name Z to A</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SortSelect;
