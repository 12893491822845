import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ProductImg from "./../assets/img/productImg.png";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import img from "../assets/img/coffee_mug.jpg";
import { listContent } from "../functions/CMS/cmsContent";

function LandingModal() {
  const [show, setShow] = useState(false);
  const [cmsContent, setCmsContent] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [bimage, setimage] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true); // After the delay, set show to true to display the modal
    }, 2000); // Change the delay time (in milliseconds) according to your requirement

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts before the timeout completes
  }, []); // Empty dependency array ensures this effect runs only once after initial render
  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);
  return (
    <>
      <Modal
        size="lg"
        // className="landingModal"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <div className="landingModal">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6} className="m-2 ">
                <div style={{ marginTop: "6%" }}>
                  <h3 className="offerTitle">
                    {cmsContent.map((c) => {
                      return (
                        <React.Fragment key={c._id}>
                          {c.IsActive && c.ContentFor === "pop heading" && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: c.ContentUpload,
                              }}
                              // className="offerTitle"
                            ></p>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </h3>
                </div>
                <div style={{ marginTop: "5%" }}>
                  <p
                    className="descriptionModal"
                    style={{
                      textAlign: "justify",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    {/* Experience the artful blend of flavors, aromas, and textures
                    in our finely crafted coffee, where expertly roasted beans
                    and precise brewing techniques converge to create an
                    unmatched coffee experience. */}
                    {cmsContent.map((c) => {
                      return (
                        <React.Fragment key={c._id}>
                          {c.IsActive &&
                            c.ContentFor ===
                              "Landing page pop up modal content" && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: c.ContentUpload,
                                }}
                                // className="offerTitle"
                              ></p>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
                <div style={{ marginTop: "12%" }}>
                  <Link className="shopLink" to="#">
                    {/* Shop Now */}
                    {cmsContent.map((c) => {
                      return (
                        <React.Fragment key={c._id}>
                          {c.IsActive && c.ContentFor === "pop content 2" && (
                            <Link
                              className="shopLink"
                              style={{ backgroundColor: "gray" }}
                              to="#"
                              dangerouslySetInnerHTML={{
                                __html: c.ContentUpload,
                              }}
                              // className="offerTitle"
                            ></Link>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Link>
                </div>
              </Col>
              {/* <Col lg={4} >
              <img src={ProductImg} alt="img" />
            </Col> */}
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default LandingModal;
