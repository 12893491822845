import React, { useState, useEffect, useContext } from "react";
import Hader from "../Component/Hader";
import {
  FaEye,
  FaComment,
  FaRegCalendar,
  FaSearch,
  FaHeart,
  FaRegHeart,
} from "react-icons/fa";
import { IoIosHeart } from "react-icons/io";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import Footer from "../Component/Footer";
import BannerTwo from "../Component/BannerTwo";
import { Link, useLocation } from "react-router-dom";
import { listActiveCategories } from "../functions/Category/category";
import axios from "axios";
import {
  listActiveBlogs,
  updateViews,
  topPopularPosts,
  AddLikes,
  RemoveLikes,
  getBlogs,
  listBlogCategory,
} from "../functions/CMS/Blog";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BradeCrum from "../Component/BradeCrum";
import Cart from "../Component/Cart";
import MobileHeader from "../Component/MobileHeader";
import { MyContext } from "../MyContext";
import { FaCoffee } from "react-icons/fa";

const Blog = () => {
  const {
    searchResults,
    setSearchResults,
    coffeeHistoryCheck,
    coffeeTrendingCheck,
    setCoffeeHistoryCheck,
    setCoffeeTrendingCheck,
  } = useContext(MyContext);

  const [categoryData, setCategoryData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [popularProducts, setpopularProducts] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  // const [searchResults, setSearchResults] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();

  const userid = localStorage.getItem("NicksCoUser");
  
  const idCategoryLocation = location.state && location.state.id;
  console.log("in loca cat 1", location);

  useEffect(() => {
    listActiveBlogs().then((res) => {
      console.log("res blog", res.data);
      if (idCategoryLocation) {
        console.log("in loca cat", idCategoryLocation);
        setSearchResults(
          res.data.filter(
            (blog) => blog.blogType.length > 0 && blog.blogType.includes(idCategoryLocation)
          )
        );
      } 
    });
  }, [idCategoryLocation]);

  const [blogCategory, setBlogCategory] = useState("");

  const fetchBlogCategory = () => {
    listBlogCategory().then((res) => setBlogCategory(res.data));
  };

  useEffect(() => {
    fetchBlogCategory();
  }, []);
  // const loadSerachResult = async () => {
  //   try {
  //     const response = await axios.get(
  //       `/api/auth/serachTitle/blogs?search=${searchTerm}`
  //     );
  //     console.log("response in search", response.data);

  //     setSearchResults(response.data);
  //   } catch (error) {
  //     console.error("Error searching blogs:", error);
  //   }
  // };
  useEffect(() => {
    // Extract page from query parameter
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(page);
  }, [location.search]);

  useEffect(() => {
    // Define an async function to perform the search operation
    const loadSearchResult = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/serachTitle/blogs?search=${searchTerm}&page=${currentPage}`
        );
        console.log("response in search", response);
        if (coffeeHistoryCheck) {
          console.log("3");

          listActiveBlogs().then((res) => {
            console.log("res blog hsitory", res.data);
            setSearchResults(
              res.data.filter((blog) => blog.blogType === "coffeeHistory")
            );
          });
          setCoffeeHistoryCheck(false);
        } else if (coffeeTrendingCheck) {
          console.log("2");

          listActiveBlogs().then((res) => {
            console.log("res blog trending", res.data);
            setSearchResults(
              res.data.filter((blog) => blog.blogType === "coffeeTrending")
            );
          });
          setCoffeeTrendingCheck(false);
        } else {
          console.log("1");
          setSearchResults(response.data.blogs);
          setCurrentPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error("Error searching blogs:", error);
      }
    };

    // Call the search function whenever searchTerm changes
    loadSearchResult();
  }, [searchTerm, currentPage]); // Dependency on searchTerm

  useEffect(() => {
    // Update currentPage if it exceeds totalPages
    if (totalPages > 0 && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]); // Run this effect whenever totalPages changes

  const highlightMatch = (text, highlight) => {
    const regex = new RegExp(`(${highlight})`, "gi");
    return text.split(regex).map((part, index) => {
      return part.toLowerCase() === highlight.toLowerCase() ? (
        <span
          key={index}
          className="highlight"
          style={{ backgroundColor: "#f0cca7" }}
        >
          {part}
        </span>
      ) : (
        part
      );
    });
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update searchTerm as the user types
  };

  const handleClickCateory = (id) => {
    listActiveBlogs().then((res) => {
      console.log("res blog", res.data);
      if (id) {
        setSearchResults(
          res.data.filter(
            (blog) => blog.blogType.length > 0 && blog.blogType.includes(id)
          )
        );
      } else {
        setSearchResults(res.data);
      }
    });
  };

  const handleCoffeeTrending = () => {
    listActiveBlogs().then((res) => {
      console.log("res blog", res.data);
      setSearchResults(
        res.data.filter((blog) => blog.blogType === "coffeeTrending")
      );
    });
  };

  const handleCoffeeHistory = () => {
    listActiveBlogs().then((res) => {
      console.log("res blog", res.data);
      setSearchResults(
        res.data.filter((blog) => blog.blogType === "coffeeHistory")
      );
    });
  };

  const loadCategoryData = () => {
    listActiveCategories().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setCategoryData(invertedData);
    });
  };

  const handleViewsCount = async (bid) => {
    const s = blogData.find((b) => b._id === bid);
    console.log("s", s);
    let vie = await updateViews(bid, s.views)
      .then((res) => {
        console.log("res in views count", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("err", err);
      });

    console.log("vie", vie);
  };

  const handleLikes = (blogid) => {
    console.log("liked", blogid);
    getBlogs(blogid).then((res) => {
      console.log("res in get blog", res.data);
      const likes = res.data.likes;

      console.log("likes", likes.includes(userid));
      if (userid) {
        console.log("user found");
        if (likes.length === 0 || !likes.includes(userid)) {
          console.log("in clicked on Checked whishlist");
          AddLikes(blogid, userid)
            .then((res1) => {
              console.log("res in addin likes Details", res1.data);
              setSearchResults((prevResults) =>
                prevResults.map((result) =>
                  result._id === blogid
                    ? { ...result, likes: [...result.likes, userid] }
                    : result
                )
              );
            })
            .catch((err) => {
              console.log("error ina adding list", err);
            });
        } else {
          console.log("in clicked on unChecked whishlist");
          RemoveLikes(blogid, userid)
            .then((res2) => {
              console.log("res in removeaddin product Details", res2.data);
              setSearchResults((prevResults) =>
                prevResults.map((result) =>
                  result._id === blogid
                    ? {
                        ...result,
                        likes: result.likes.filter((id) => id !== userid),
                      }
                    : result
                )
              );
            })
            .catch((err) => {
              console.log("error ina remove list", err);
            });
        }
      } else {
        window.location.replace("/login");
      }
    });
  };

  const loadBlogData = () => {
    listActiveBlogs().then((res) => {
      console.log("res blog", res.data);
      setBlogData(res.data);
    });
  };

  const handlePageChange = (id) => {
    console.log("id", id);
    window.location.replace(`/blog-details/${id}`);
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  const loadPopularProducts = () => {
    topPopularPosts().then((res) => {
      console.log("res in popular pro", res.data);
      setpopularProducts(res.data);
    });
  };

  useEffect(() => {
    loadCategoryData();
    loadBlogData();
    loadPopularProducts();
    // loadSerachResult();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Cart />
          <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum Title="Blog" />
          <section className="blog_section sec_ptb_120 bg_default_gray">
            <Container>
            <h2 className="small_title wow fadeInUp mb-4 h2" data-wow-delay=".1s">
              <FaCoffee className="fas fa-coffee" /> Blogs
            </h2>
              <Row className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
                <Col lg="7" md="6">
                  {searchResults.map((bd) => (
                    <div
                      className="blog_standard wow fadeInUp"
                      data-wow-delay=".1s"
                      key={bd._id}
                    >
                      <Link
                        className="item_image"
                        onClick={() => {
                          handleViewsCount(bd._id);
                        }}
                        to={`/blog-details/${bd._id}`}
                      >
                        <img
                          // src={Blog1}
                          src={`${process.env.REACT_APP_API_URL_COFFEE}/${bd.blogImage}`}
                          alt="image_not_found"
                        />
                      </Link>
                      <div className="item_content">
                        <ul className="post_meta ul_li">
                          <li>
                            <Link
                              to="#!"
                              onClick={() => {
                                handleLikes(bd._id);
                              }}
                            >
                              {bd.likes.includes(userid) ? (
                                <IoIosHeart
                                  style={{
                                    color: "#e41717cf",
                                    fontSize: "22px",
                                  }}
                                />
                              ) : (
                                <FaRegHeart
                                  style={{
                                    fontSize: "22px",
                                  }}
                                />
                              )}
                              &nbsp; {bd.likes.length} Like
                            </Link>
                          </li>
                          <li>
                            <Link to="#!">
                              <FaEye className="fal fa-comment-alt" />
                              &nbsp; {bd.views} views
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="#!">
                              <FaComment className="fal fa-comment-alt" />
                              &nbsp;{bd.comments.length} Comments
                            </Link>
                          </li> */}
                          <li>
                            <FaRegCalendar className="fal fa-calendar-alt" />
                            &nbsp;
                            {/* {moment(new Date(bd.createdAt)).format(
                              "DD MMMM YYYY"
                            )} */}
                            {moment
                              .utc(bd.createdAt)
                              .tz("America/Los_Angeles")
                              .format("MM-DD-YY hh:mm a")}
                          </li>
                        </ul>
                        <h3 className="item_title text-uppercase">
                          <Link
                            onClick={() => {
                              handleViewsCount(bd._id);
                            }}
                            to={`/blog-details/${bd._id}`}
                          >
                            {/* {bd.blogTitle} */}
                            {highlightMatch(bd.blogTitle, searchTerm)}
                          </Link>
                        </h3>
                        <p>{bd.blogThumnailDesc}</p>

                        <Link
                          className="btn btn_primary text-uppercase"
                          to={`/blog-details/${bd._id}`}
                          onClick={() => {
                            handleViewsCount(bd._id);
                          }}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))}

                  {/* <ul
                    className="pagination_nav ul_li_right wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <li>
                      <Link
                        to={`/blog?page=${currentPage - 1}`}
                        disabled={currentPage === 1}
                      >
                        <FaAnglesLeft className="fal fa-angle-double-left" />
                      </Link>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <li
                        key={i}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        <Link to={`/blog?page=${i + 1}`}>{i + 1}</Link>
                      </li>
                    ))}
                    <li>
                      <Link
                        to={`/blog?page=${currentPage + 1}`}
                        disabled={currentPage === totalPages}
                      >
                        <FaAnglesRight className="fal fa-angle-double-right" />
                      </Link>
                    </li>
                  </ul> */}
                  <ul
                    className="pagination_nav ul_li_right wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <li>
                      <Link
                        to={`/blog?page=${currentPage - 1}`}
                        disabled={currentPage === 1}
                      >
                        <FaAnglesLeft className="fal fa-angle-double-left" />
                      </Link>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <li
                        key={i}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        <Link to={`/blog?page=${i + 1}`}>{i + 1}</Link>
                      </li>
                    ))}
                    {currentPage < totalPages &&
                      totalPages !== 0 && ( // Check if totalPages is not 0
                        <li>
                          <Link
                            to={`/blog?page=${currentPage + 1}`}
                            disabled={currentPage === totalPages}
                          >
                            <FaAnglesRight className="fal fa-angle-double-right" />
                          </Link>
                        </li>
                      )}
                  </ul>
                </Col>
                <Col lg="5" md="6">
                  <aside className="sidebar_section">
                    <div
                      className="sb_widget sb_search wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h3 className="sb_widget_title text-uppercase">
                        Search here
                      </h3>
                      <form>
                        <div className="form_item mb-0">
                          <input
                            id="sb_search_input"
                            type="search"
                            name="search"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearch} // Call handleSearch on input change
                          />
                          <label
                            className="form_icon"
                            htmlFor="sb_search_input"
                          >
                            <FaSearch className="far fa-search" />
                          </label>
                        </div>
                      </form>
                    </div>
                    <div
                      className="sb_widget sb_category wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h3 className="sb_widget_title text-uppercase">
                        Categories
                      </h3>
                      <ul className="ul_li_block">
                        {blogCategory.map((cd) => (
                          <li>
                            <Link
                              key={cd._id} // Add key prop here
                              // to={`/producListing/${cd._id}`}
                              onClick={() => handleClickCateory(cd._id)}
                            >
                              <span>
                                <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                                {cd.blogCategory}
                              </span>
                            </Link>
                          </li>
                        ))}
                        <li>
                          <Link
                            to="/blog"
                            onClick={() => handleClickCateory(null)}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              See All Blog
                            </span>
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to="#"
                            onClick={handleCoffeeTrending}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              Coffee Trending
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            onClick={handleCoffeeHistory}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              Coffee History
                            </span>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                    <div
                      className="sb_widget sb_popular_post wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h3 className="sb_widget_title text-uppercase">
                        Popular post
                      </h3>
                      {blogData
                        .filter((pp) => popularProducts.includes(pp._id)) // Filter blog posts based on popularProducts
                        .map((pp) => (
                          <div className="recent_post" key={pp._id}>
                            <Link
                              className="item_image"
                              to="#"
                              // to={`/blog-details/${pp._id}`}
                              onClick={() => handlePageChange(pp._id)}
                            >
                              <img
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${pp.blogImage}`}
                                alt="image_not_found"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </Link>
                            <div
                              style={{ marginTop: "10px" }}
                              className="item_content"
                            >
                              <h6 className="item_title">
                                <Link
                                  to="#"
                                  // to={`/blog-details/${pp._id}`}
                                  // onClick={() => window.location.reload()}
                                  onClick={() => handlePageChange(pp._id)}
                                >
                                  {pp.blogTitle}
                                </Link>
                              </h6>
                              <span className="post_date text-uppercase">
                                {/* {moment(new Date(pp.createdAt)).format(
                                  "DD MMMM YYYY"
                                )} */}
                                {moment
                                  .utc(pp.createdAt)
                                  .tz("America/Los_Angeles")
                                  .format("MM-DD-YY hh:mm a")}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </aside>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Blog;
