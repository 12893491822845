import React, { useEffect, useState } from "react";
import Hader from "../../Component/Hader";
import { FaCoffee, FaRegUserCircle, FaAddressBook } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlineStars } from "react-icons/md";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Container,
  Row,
} from "reactstrap";
import { listState, listCountry } from "../../functions/Location/Location";
import Footer from "../../Component/Footer";
import BannerTwo from "../../Component/BannerTwo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getAllShippingAddressofUser,
  createUserShippingAddress,
  getUserShippingAddress,
  updateUserShippingAddress,
  removeUserShippingAddress,
} from "../../functions/Auth/UserShippings";
import { getUsers } from "../../functions/Auth/Users";
import { Link } from "react-router-dom";
import ProfileSideBar from "../../Component/profileSideBar";
import Cart from "../../Component/Cart";
import { Puff } from "react-loader-spinner";
import "../../assets/css/profile.css";
import MobileHeader from "../../Component/MobileHeader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { RxCross1 } from "react-icons/rx";
import { getUserPointTransaction } from "../../functions/Loyalty/PointTransaction";
import moment from "moment";
import {
  downloadInvoicePDF,
  downloadOrderDetailsPDF,
} from "../../functions/Orders/Order";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%", // Adjusted width for extra small screens
    sm: "80%", // Adjusted width for small screens
    md: "70%", // Adjusted width for medium screens
    lg: "60%", // Adjusted width for large screens
    xl: "50%", // Adjusted width for extra large screens
  },
  height: "60vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const MyRewards = () => {
  const [points, setPoints] = useState(0);
  const [refCode, setRefCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [pointData, setPointsData] = useState(0);
  const [buttonLoadingStates, setButtonLoadingStates] = useState(
    Array(pointData.length).fill(false)
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const [lpoints, setLpoints] = useState([
  //   {
  //     sr: 1,
  //     detail: "Title",
  //     dates: "11/11/1111",
  //     points: 10,
  //   },
  //   {
  //     sr: 1,
  //     detail: "Title",
  //     dates: "11/11/1111",
  //     points: -10,
  //   },
  //   {
  //     sr: 1,
  //     detail: "Title",
  //     dates: "11/11/1111",
  //     points: 10,
  //   },
  //   {
  //     sr: 1,
  //     detail: "Title",
  //     dates: "11/11/1111",
  //     points: -10,
  //   },
  //   {
  //     sr: 1,
  //     detail: "Title",
  //     dates: "11/11/1111",
  //     points: 10,
  //   },
  //   {
  //     sr: 1,
  //     detail: "Title",
  //     dates: "11/11/1111",
  //     points: -10,
  //   },
  // ]);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    getUsers(localStorage.getItem("NicksCoUser")).then((res) => {
      setPoints(res.data.points);
      setRefCode(res.data.refCode);
    });
  }, []);

  useEffect(() => {
    getUserPointTransaction(localStorage.getItem("NicksCoUser")).then((res) => {
      console.log("transaction", res.data);
      setPointsData(res.data);
    });
  }, []);

  // const downloadPDF = (orderId, randomOrderId, index) => {
  //   const updatedButtonLoadingStates = [...buttonLoadingStates];
  //   updatedButtonLoadingStates[index] = true;
  //   setButtonLoadingStates(updatedButtonLoadingStates);

  //   console.log("orderId in downloadPDF", orderId);
  //   downloadInvoicePDF(orderId)
  //     .then((response) => {
  //       // Extract the data from the response Blob
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         const data = reader.result;
  //         const blob = new Blob([data], {
  //           type: "application/pdf",
  //         });
  //         console.log("Blob size:", blob.size);
  //         const a = document.createElement("a");
  //         a.href = URL.createObjectURL(blob);
  //         a.download = `Invoice_${randomOrderId}.pdf`;
  //         a.click();

  //         const updatedButtonLoadingStates = [...buttonLoadingStates];
  //         updatedButtonLoadingStates[index] = false;
  //         setButtonLoadingStates(updatedButtonLoadingStates);
  //       };

  //       reader.readAsArrayBuffer(response.data);
  //     })
  //     .catch((error) => {
  //       console.log("error in downloadInvoicePDF", error);
  //       const updatedButtonLoadingStates = [...buttonLoadingStates];
  //       updatedButtonLoadingStates[index] = false;
  //       setButtonLoadingStates(updatedButtonLoadingStates);
  //     });
  // };

  const downloadPDF = (orderId, randomOrderId, index) => {
    const updatedButtonLoadingStates = [...buttonLoadingStates];
    updatedButtonLoadingStates[index] = true;
    setButtonLoadingStates(updatedButtonLoadingStates);

    downloadOrderDetailsPDF(orderId)
      .then((pdfUrl) => {
        const a = document.createElement("a");
        // let d = a.href.data;
        console.log("aa", a);
        a.href = pdfUrl.data;
        console.log("pdfUrl", pdfUrl);
        a.download = `Invoice_${randomOrderId}.pdf`;
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        console.log("Error in downloadOrderDetailsPDF:", error);
      })
      .finally(() => {
        const updatedButtonLoadingStates = [...buttonLoadingStates];
        updatedButtonLoadingStates[index] = false;
        setButtonLoadingStates(updatedButtonLoadingStates);
      });
  };

  // const downloadPDF = (orderId, randomOrderId) => {
  //   const downloadPromise = () => {
  //     return downloadOrderDetailsPDF(orderId)
  //       .then((pdfUrl) => {
  //         const a = document.createElement("a");
  //         a.href = pdfUrl;
  //         a.download = `Invoice_${randomOrderId}.pdf`;
  //         a.target = "_blank";
  //         a.click();
  //       })
  //       .catch((error) => {
  //         console.log("error in downloadInvoicePDF", error);
  //       });
  //   };
  //   toast
  //     .promise(downloadPromise(), {
  //       pending: "Loading Order Details... Please Wait",
  //       success: "Order Details Loaded Successfully!",
  //       error: "Failed to load Order Details",
  //       duration: 3000,
  //       position: toast.POSITION.TOP_CENTER,
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       console.log("error in downloading pdf");
  //     });
  // };

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
          <Cart />
          <div>
        <Hader Color="#000" />
            <MobileHeader />
          </div>
          <BannerTwo Title="My Rewards" subTitle="My Rewards" />
          <Container className="sec_ptb_120 ">
            <Row>
              <Col lg="3">
                <ProfileSideBar />
              </Col>
              <Col lg="9">
                <Row>
                  <Col lg="12">
                    <h5 className="text-center mt-3">
                      Check out all of the rewards that are available to you.
                      <hr />
                    </h5>
                  </Col>
                  <Col lg="12">
                    <div className="upper-reward">
                      <h1 className="text-center mt-3">
                        <MdOutlineStars /> {points}
                      </h1>
                      <h4 className="text-center mt-3">
                        Total Loyalty Points earned
                      </h4>
                      <p
                        className="text-center mt-3"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <Link to="/loyalty" className="text-dark">
                          {" "}
                          <u>Learn how to earn more Loyalty Points</u>
                        </Link>
                      </p>
                      <h4 className="text-center mt-3">{refCode}</h4>
                    </div>
                  </Col>
                  {/* <Col lg="12">
                    <div className="lower-reward">
                      <p>Redeem at checkout</p>
                      <p>Flexible reward</p>
                      <h5>
                        Redeem 50 Loyalty Points for a $5 discount on your order
                      </h5>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.replace("/");
                        }}
                      >
                        Start Browsing
                      </button>
                    </div>
                  </Col> */}
                  <Col>
                    <div className="lpoint-div">
                      {
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          onClick={handleOpen}
                        >
                          Show History
                        </button>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Modal
              open={open}
              onClose={handleClose}
              // style={{height: "800px"}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="close-div" onClick={handleClose}>
                  <h3>Transaction History</h3>
                  <RxCross1 />
                </div>
                <hr />
                <div className="loyaltyMainDiv">
                  <table className="loyalty-table">
                    <tr>
                      <th>Sr. No.</th>
                      <th>Loyalty Program</th>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Loyalty Points</th>
                      {/* <th>Action</th> */}
                    </tr>
                    {pointData.length > 0 &&
                      pointData.map((it, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{it.pointMasterId && it.loyalty.pointType}</td>
                          <td>
                            {it.orderId &&
                            <span className="badge badge-soft-success">
                              <button
                                style={{
                                  borderRadius: "20px",
                                  backgroundColor: "#598c59",
                                  color: "white",
                                  padding: "12px 10px",
                                  borderColor: "#598c59",
                                  fontSize: "15px",
                                  border: "green",
                                }}
                                onClick={() => {
                                  downloadPDF(
                                    it.orderId,
                                    it.order.randomOrderId,
                                    index
                                  );
                                }}
                              >
                                {buttonLoadingStates[index] ? (
                                  <CircularProgress
                                    size={20}
                                    sx={{ color: "white" }}
                                  />
                                ) : (
                                  <span>
                                    {it.orderId && it.order.randomOrderId}
                                  </span>
                                )}
                              </button>
                            </span> }
                          </td>
                          <td>
                            {moment.utc(it.createdAt).tz('America/Los_Angeles').format('MM-DD-YY hh:mm a')}
                          </td>

                          <td>
                            <p
                              className={`${
                                it.type == "Credit" ? "green" : "red"
                              }`}
                            >
                              {it.points}
                            </p>
                          </td>
                          {/* <td>
                            <button
                              onClick={() => {
                                downloadPDF(
                                  it.orderId,
                                  it.order.randomOrderId,
                                  index
                                );
                              }}
                              className="btn btn-outline-success"
                            >
                              {buttonLoadingStates[index] ? (
                                <>
                                  <span style={{ width: "200px" }}>
                                    <CircularProgress
                                      size={20}
                                      sx={{ color: "white" }}
                                      className="hello"
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="badge badge-soft-success">
                                    View Details
                                  </span>
                                </>
                              )}
                            </button>
                          </td> */}
                          {/* <td>
                            <button
                              onClick={() => {
                                downloadPDF(
                                  it.orderId,
                                  it.order.randomOrderId,
                                  index
                                );
                              }}
                              className="btn btn-success"
                            >
                              {buttonLoadingStates[index] ? (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                <span>View Details</span>
                              )}
                            </button>
                          </td> */}
                        </tr>
                      ))}
                  </table>
                </div>
              </Box>
            </Modal>
          </Container>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default MyRewards;
