import React, { useEffect, useState } from "react";
import "../assets/css/subscribe.css";
import {
  createNewsLetter,
  getNewsLetter,
  getNewsLetterByEmail,
} from "../functions/PolicyAndInquiry/NewsLetterSubs";
import { Button } from "@mui/material";
import {
  Input,
  Label,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getUsers } from "../functions/Auth/Users";
import { getSetupByName, sendEmail } from "../functions/Notifications/EmailNotifications";

const initialState = {
  email: "",
  IsActive: true,
};

const Subscribe = () => {
  const [join, setJoin] = useState(false);
  const [isjoin, setisJoin] = useState(false);

  const [values, setValues] = useState(initialState);
  const { email, IsActive } = values;
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  let user = localStorage.getItem("NicksCoUser");
  useEffect(() => {
    if(user){
    getUsers(user).then((res) => {
      getNewsLetterByEmail(res.data.email).then((res) => {
        if (res.data.isOk) {
          setisJoin(true);
        } else {
          setisJoin(false);
        }
        console.log("ww", res);
      });
    });
  }
  }, []);

  const handleJoinNow = (e) => {
    e.preventDefault();
    let errors = validate(email);
    console.log("ee", errors);
    setFormErrors(errors);
    setIsSubmit(true);
    if (Object.keys(errors).length === 0) {
      createNewsLetter(values)
        .then((res1) => {
          console.log("res", res1);
          setIsSubmit(false);
          setFormErrors({});
          setJoin(!join);
          setisJoin(true);
          setValues(initialState);
          getSetupByName("Welcome Newsletter Subcription")
            .then((res) => {
              console.log("res in email", res);
              const response = res.data;
              sendEmail(
                response.EmailFrom,
                response.EmailPassword,
                [],
                response.emailSubject,
                response.EmailSignature,
                response.OutgoingServer,
                response.OutgoingPort,
                res1.data.email
              )
                .then((res1) => {
                  console.log("res1", res1);
                  console.log("email sent successfully");
                })
                .catch((err) => {
                  console.log("error in email send", err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [errEM, setErrEM] = useState(false);

  const validate = (email) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (values.email === "") {
      errors.email = "Email is required!";
      setErrEM(true);
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
      setErrEM(true);
    }
    if (values.email !== "") {
      setErrEM(false);
    }

    return errors;
  };

  const validClassEM =
    errEM && isSubmit ? "form-control is-invalid" : "form-control";

  return (
    <div
      className={`subs-div ${join ? "dark" : "light"}`}
      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
    >
      {isjoin ? (
        <p className="mt-2">
          You're subscribed! Stay tuned for the latest updates.
        </p>
      ) : (
        // <div className="subs-input">
        //   <input
        //     // classNsame="subs-input"
        //     id="subscribe-input"
        //     type="text"
        //     placeholder="Enter Your Email"
        //     name="email"
        //     value={email}
        //     onChange={(e) => setValues({ ...values, email: e.target.value })}
        //     style={{
        //       backgroundColor: "#030303",
        //       color: "#fff",
        //       border: "1px solid #030303",
        //       width: "100%",
        //       outline: "none",
        //       fontWeight: "bold",
        //       fontSize: "20px",
        //     }}
        //   />
        //   {isSubmit && (
        //     <p className="text-danger" style={{ textAlign: "left" }}>
        //       {formErrors.email}
        //     </p>
        //   )}
        // </div>
        <p className="mt-2">
          <span>Sign up for our newsletter</span> to receive special offers,
          event invitations, and more.
        </p>
      )}
      <div className="subs-inner mt-2">
        {isjoin ? (
          <h5>SUBSCRIBED</h5>
        ) : (
          <h5 onClick={() => setJoin(!join)} style={{ cursor: "pointer" }}>
            JOIN NOW
          </h5>
        )}
      </div>

      <Modal
        isOpen={join}
        toggle={() => {
          setJoin(!join);
          setValues(initialState);
          // setIsEdit(false);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setJoin(!join);
          }}
        >
          Subscribe Now
        </ModalHeader>
        <ModalBody>
          <Row>
            <div className="">
              <div className="form_item">
                <input
                  type="email"
                  className={validClassEM}
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                />
                {isSubmit && <p className="text-danger">{formErrors.email}</p>}
              </div>

              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={handleJoinNow}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    border: "2px solid black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                      border: "2px solid white",
                    },
                    textAlign: "center",
                  }}
                  variant="outlined"
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Subscribe;
