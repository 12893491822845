import React, { useEffect, useState } from "react";
import BannerImg from "./../assets/img/subscription-6cover.jpg";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { listBannerImages } from "../functions/CMS/banner";

const SlideSection = () => {
  const [bannerData, setBannnerData] = useState([]);
  const loadBannerData = () => {
    listBannerImages()
      .then((res) => {
        console.log("res in banner", res.data);
        setBannnerData(res.data);
      })
      .catch((err) => {
        console.log("error in list banner");
      });
  };
  useEffect(() => {
    loadBannerData();
  }, []);
  return (
    <React.Fragment>
      <Carousel>
        {/* <Carousel.Item interval={5000}> */}
        {/* <div className="slider-divv">
            <video
              playsInline
              autoPlay
              loop
              muted
              preload="none"
              className="bannerImg"
              style={{ marginTop: 0 }}
            >
              <source
                src="https://ceremonycoffee.com/wp-content/uploads/ceremony-coffee-roasters-about-hero_1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div
            className="descriptionDiv"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            <h6 className="descriptionTitle">WELCOME TO NICK'S ROASTING CO.</h6>
            <h4 className="descriptionSubTitle">Good to the Last Sip</h4>
            <p className="description">
              At Nick's Roasting Co., we pick and brew the best beans to make
              sure the coffee you enjoy will be a unique and satisfying
              experience for both body and mind.
            </p>
            <Link to="#">GET STARTED</Link>
          </div> */}
        {/* {bannerData.map((b) => {
            return (
              <React.Fragment key={b._id}>
                {b.Title === "Banner 1" && (
                  <>
                    <div className="slider-divv">
                      {b.Type === "video" ? (
                        <>
                          {console.log("b.bannerImage", b.bannerImage)}
                          <video
                            playsInline
                            autoPlay
                            loop
                            muted
                            preload="none"
                            className="bannerImg"
                            style={{ marginTop: 0 }}
                          >
                            <source
                              src={`${process.env.REACT_APP_API_URL_COFFEE}/${b.bannerImage}`}
                              // src={b.bannerImage}
                              type="video/mp4"
                            />
                          </video>
                        </>
                      ) : (
                        <>
                          <img
                            src={`${process.env.REACT_APP_API_URL_COFFEE}/${b.bannerImage}`}
                            alt=""
                            className="bannerImg"
                            style={{ marginTop: 0 }}
                          />
                        </>
                      )}
                    </div>
                    <div
                      className="descriptionDiv"
                      style={{
                        fontFamily: "Arial, Helvetica, sans-serif",
                        color: "white",
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: b.Description,
                        }}
                      ></p>
                      
                    </div>
                  </>
                )}
              </React.Fragment>
            );
          })} */}
        {bannerData.map((b) => {
          console.log("bb", b.IsActive, b.Type); // Moved the console.log outside of the conditional rendering block
          if (b.IsActive === true) {
            return (
              <Carousel.Item key={b._id} interval={5000}>
                <div className="slider-divv">
                  {b.Type === "video" ? (
                    <video
                      playsInline
                      autoPlay
                      loop
                      muted
                      preload="none"
                      className="bannerImg"
                      style={{ marginTop: 0 }}
                    >
                      <source
                        src={`${process.env.REACT_APP_API_URL_COFFEE}/${b.bannerImage}`}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_API_URL_COFFEE}/${b.bannerImage}`}
                      // src={b.BannerImage}
                      alt=""
                      className="bannerImg"
                      style={{ marginTop: 0 }}
                    />
                  )}
                </div>
                <div
                  className="descriptionDiv"
                  style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    color: "white",
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: b.Description,
                    }}
                  ></p>
                </div>
              </Carousel.Item>
            );
          } else {
            return null;
          }
        })}
        {/* </Carousel.Item> */}
        {/* <Carousel.Item> 
      <div>
       
       <video
         playsInline
         autoPlay
         loop
         muted
         preload="none" 
         className="bannerImg"
         style={{ marginTop: 0 }}
       >
         <source
           src="https://ceremonycoffee.com/wp-content/uploads/ceremony-coffee-roasters-locations-hero-1_1_1.mp4"
           type="video/mp4"
         />
       </video>
     </div>
     <div className="descriptionDiv"  style={{fontFamily: "Arial, Helvetica, sans-serif"}}>
       <h6 className="descriptionTitle">
      
         Elevate Your Coffee Routine
       </h6>
       <h4 className="descriptionSubTitle">Subscribe and Save 10%</h4>
       <p className="description">
         No commitments, just repeat coffee shipments at your convenience.
       </p>
       <Link to="#">GET STARTED</Link>
     </div>
      </Carousel.Item> */}
      </Carousel>
    </React.Fragment>
  );
};

export default SlideSection;
