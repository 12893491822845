import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import Footer from "../Component/Footer";

// import AboutImg from "./../assets/img/11062b_2974cb752e974ea192d619ea0d64120d~mv2.webp";
// import AboutImg2 from "./../assets/img//11062b_ca88a6f079324d7c98963f33c1f2d48f~mv2.082f93412eeea96d38cb (1).webp";
// import AboutImg3 from "./../assets/img/11062b_54b0313b65a44b479c9f065e126f7c06~mv2.webp";
import { FaCoffee } from "react-icons/fa";
import BradeCrum from "../Component/BradeCrum";
import MobileHeader from "../Component/MobileHeader";
import { listContent } from "../functions/CMS/cmsContent";
import { Puff } from "react-loader-spinner";

const Shipping = () => {
  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
      <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum />
          <section className="about_section sec_ptb_120">
            <div className="container">
              <div className="row align-items-center justify-content-lg-between">
                {/* <div className="col-lg-6 order-last">
                  <div
                    className="about_image2 wow fadeInUp pd-bottom-50"
                    data-wow-delay=".1s"
                  >
                    <img
                      src={AboutImg}
                      alt="image_not_found"
                      className="disp-none"
                    />
                    <br className="disp-none" />
                    <br className="disp-none" />
                    <img src={AboutImg2} alt="image_not_found" />
                    <br className="disp-none" />
                    <br className="disp-none" />
                    <img src={AboutImg3} alt="image_not_found" />
                  </div>
                </div> */}
                <div className="">
                  <div className="about_content">
                    <div className="text-uppercase">
                      {cmsContent.map((c) => {
                        return (
                          <React.Fragment key={c._id}>
                            {c.IsActive &&
                              c.ContentFor === "shipping Heading" && (
                                <>
                                  <span>
                                    {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                                    <p
                                      className="small_title wow fadeInUp"
                                      data-wow-delay=".1s"
                                      dangerouslySetInnerHTML={{
                                        __html: c.ContentUpload,
                                      }}
                                    ></p>
                                  </span>
                                </>
                              )}
                          </React.Fragment>
                        );
                      })}
                      {/* <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                    <FaCoffee className="fas fa-coffee" /> Shipping & Returns
                  </h2>
                  <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
                    nicks roasting co Shipping & Returns Policies
                  </h3> */}
                    </div>

                    {/* <p className="wow fadeInUp" data-wow-delay=".3s"> */}
                    {/* At nicks roasting co, we strive to deliver fresh and delicious
                  coffee to your doorstep. We offer free shipping on all our
                  coffee subscription boxes. Our coffee is carefully packaged to
                  ensure it arrives at your doorstep fresh and ready to brew. If
                  you have any questions about our shipping policy, please do
                  not hesitate to contact us. */}
                    <ul className="about_info ul_li_block">
                      <li className="wow fadeInUp" data-wow-delay=".4s">
                        <p className="mb-0">
                          {cmsContent.map((c) => {
                            return (
                              <React.Fragment key={c._id}>
                                {c.IsActive &&
                                  c.ContentFor === "shipping content" && (
                                    <>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: c.ContentUpload,
                                        }}
                                      ></p>
                                    </>
                                  )}
                              </React.Fragment>
                            );
                          })}
                        </p>
                      </li>
                    </ul>
                    {/* </p> */}
                    {/* <ul className="about_info ul_li_block">
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    <h4 className="text-uppercase">SHIPPING POLICY</h4>
                    <p className="mb-0">
                      We offer free standard shipping on all our coffee
                      subscription boxes. Our coffee is roasted fresh and
                      packaged with care to ensure it arrives at your doorstep
                      at its peak flavor. We currently only ship within the
                      United States and do not offer international shipping at
                      this time.If you have any questions about our shipping
                      policy, please do not hesitate to contact us. <br />
                      <br />
                      Shipping Policy This document sets out the shipping policy
                      that applies to customers that make a purchase at
                      www.rchenningcoffeecompany.com. If you have any questions,
                      please contact our customer service team on 1800 297 4001
                      or Info@nicksroastingco.com <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Shipping Options & Delivery Costs
                      </span>{" "}
                      <br />
                      We offer the following shipping options - you will be
                      asked to select a shipping method at checkout. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Standard Shipping
                      </span>{" "}
                      <br />
                      5 Days Shipping <br />
                      3 Days Shipping <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Order Processing Time
                      </span>{" "}
                      <br />
                      All orders placed before 2 PM Monday to Friday are
                      processed and dispatched the same day, all orders placed
                      after will be dispatched the next day. All orders placed
                      during the weekend or on a public holiday will be sent
                      from our warehouse on Monday or on the next business day.{" "}
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Delivery Address & P.O. Boxes
                      </span>{" "}
                      <br />
                      Please note that we are unable to modify the delivery
                      address once you have placed your order. We are sorry but
                      we do not ship to P.O. boxes. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        International Orders
                      </span>{" "}
                      <br />
                      Your package may be subject to import duties and taxes.
                      You, as the customer, are responsible for paying those
                      fees. We recommend that you check with your local customs
                      office before placing an order on our website as these
                      fees can sometimes be significant and we are unable to
                      calculate these for you. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Tracking Your Order
                      </span>{" "}
                      <br />
                      Once your order has been dispatched, we will send you a
                      confirmation email with tracking information. You will be
                      able to track your package directly on the carrier’s
                      website. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Returns, Refunds, and Exchanges
                      </span>{" "}
                      <br />
                      We want you to be completely happy with your purchase -
                      please read our return & refund policy for detailed
                      information about our processes. <br />
                    </p>
                  </li>
                </ul> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div>
                  <div className="about_content">
                    <ul className="about_info ul_li_block">
                      <li className="wow fadeInUp" data-wow-delay=".4s">
                        <p className="mb-0">
                          {cmsContent.map((c) => {
                            return (
                              <React.Fragment key={c._id}>
                                {c.IsActive &&
                                  c.ContentFor === "Return policy content" && (
                                    <>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: c.ContentUpload,
                                        }}
                                      ></p>
                                    </>
                                  )}
                              </React.Fragment>
                            );
                          })}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="row">
            <div>
              <div className="about_content">
                <ul className="about_info ul_li_block">
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    <h4 className="text-uppercase mt-4">
                      Return & Exchange Policy
                    </h4>
                    <p className="mb-0">
                      We offer free standard shipping on all our coffee
                      subscription boxes. Our coffee is roasted fresh and
                      packaged with care to ensure it arrives at your doorstep
                      at its peak flavor. We currently only ship within the
                      United States and do not offer international shipping at
                      this time.If you have any questions about our shipping
                      policy, please do not hesitate to contact us. <br />
                      <br />
                      Shipping Policy This document sets out the shipping policy
                      that applies to customers that make a purchase at
                      www.rchenningcoffeecompany.com. If you have any questions,
                      please contact our customer service team on 1800 297 4001
                      or Info@nicksroastingco.com <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Shipping Options & Delivery Costs
                      </span>{" "}
                      <br />
                      We offer the following shipping options - you will be
                      asked to select a shipping method at checkout. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Standard Shipping
                      </span>{" "}
                      <br />
                      5 Days Shipping <br />
                      3 Days Shipping <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Order Processing Time
                      </span>{" "}
                      <br />
                      All orders placed before 2 PM Monday to Friday are
                      processed and dispatched the same day, all orders placed
                      after will be dispatched the next day. All orders placed
                      during the weekend or on a public holiday will be sent
                      from our warehouse on Monday or on the next business day.{" "}
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Delivery Address & P.O. Boxes
                      </span>{" "}
                      <br />
                      Please note that we are unable to modify the delivery
                      address once you have placed your order. We are sorry but
                      we do not ship to P.O. boxes. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        International Orders
                      </span>{" "}
                      <br />
                      Your package may be subject to import duties and taxes.
                      You, as the customer, are responsible for paying those
                      fees. We recommend that you check with your local customs
                      office before placing an order on our website as these
                      fees can sometimes be significant and we are unable to
                      calculate these for you. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Tracking Your Order
                      </span>{" "}
                      <br />
                      Once your order has been dispatched, we will send you a
                      confirmation email with tracking information. You will be
                      able to track your package directly on the carrier’s
                      website. <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Returns, Refunds, and Exchanges
                      </span>{" "}
                      <br />
                      We want you to be completely happy with your purchase -
                      please read our return & refund policy for detailed
                      information about our processes. <br />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
            </div>
          </section>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default Shipping;
