import React, { useState, useEffect } from "react";
import "../assets/css/footer.css";
import logo from "../assets/img/logo_white_1x.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Button } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { listActiveCategories } from "../functions/Category/category";
import { CiMail, CiPhone } from "react-icons/ci";
import Subscribe from "./Subscribe";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";
import { listLocationDetails } from "../functions/Location/companyLocation";
import { listContent } from "../functions/CMS/cmsContent";
import { listActiveMenu } from "../functions/Menu/MenuForm";

const Footer = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companylocation, setCompanyLocation] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [company, setCompany] = useState("");
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    loadCategoryData();
    loadCompanyData();
    loadCompanyLocation();
    loadCmsContent();
    loadMenuData();
  }, []);

  const loadCategoryData = () => {
    listActiveCategories().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setCategoryData(invertedData);
    });
  };
  const loadCompanyData = () => {
    listCompanynDetails().then((res) => {
      console.log("res company", res.data);
      setCompanyData(res.data);
    });
  };

  const loadMenuData = () => {
    listActiveMenu().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setMenuData(invertedData);
    });
  };

  const loadCmsContent = () => {
    listContent().then((res) => {
      console.log("res cms", res.data);
      setContentData(res.data);
    });
  };

  const loadCompanyLocation = () => {
    listLocationDetails().then((res) => {
      console.log("res company location", res.data);
      setCompanyLocation(res.data);
      setCompany(res.data[0].Address);
    });
  };

  const navig = useNavigate();
  return (
    <React.Fragment>
      <Subscribe />
      <div className="footer-main-div">
        <Row style={{ padding: "100px 0px" }} className="footer-row">
          <Col lg={3} md={6}>
            {companyData.map((cd, index) => {
              return (
                <img
                key={index}
                  // src={cd.Favicon}
                  src={`${process.env.REACT_APP_API_URL_COFFEE}/${cd.Favicon}`}
                  alt=""
                  className="logo-img"
                  style={{ margin: "auto" }}
                />
              );
            })}
            {/* <img
              src={logo}
              alt=""
              className="logo-img"
              style={{ margin: "auto" }}
            /> */}
            <p>
              {contentData.map((c) => {
                return (
                  <React.Fragment key={c._id}>
                    {c.IsActive && c.ContentFor === "Footer slogan Content" && (
                      <>
                        {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
              {/* From Bean to Brew, Excellence <br /> in Every Cup. */}
            </p>

            <p>
              <span hidden style={{ marginRight: "12px" }}>
                <FacebookIcon />
              </span>
              <span hidden style={{ marginRight: "12px" }}>
                <XIcon />
              </span>
              <span hidden style={{ marginRight: "12px" }}>
                <InstagramIcon />
              </span>
              <span hidden style={{ marginRight: "12px" }}>
                <YouTubeIcon style={{ fontSize: "35px" }} />
              </span>
            </p>
          </Col>
          <Col lg={3} md={6}>
            <p>
              <b>Corporate office</b>
            </p>

            {companyData.map((c) => {
              return (
                c.IsActive && (
                  <React.Fragment key={c._id}>
                    <p style={{ width: "230px" }}>{c.Address}</p>
                  </React.Fragment>
                )
              );
            })}

            {/* <p>
              <b>VISIT US</b>
            </p>
        
            <p
              style={{ width: "230px" }}
              // key={c._id}
            >
              {company}
            </p> */}

            <p className="mt-2">
              <a
                href="mailto:info@nicksroastingco.com"
                style={{ color: "black !important" }}
                className="text-dark"
              >
                <b>
                  {/* info@nicksroastingco.com */}
                  {companyData.map((c) => {
                    return (
                      c.IsActive && (
                        <React.Fragment key={c._id}>
                          <>
                            <CiMail />
                            {c.EmailID_Office}
                          </>
                        </React.Fragment>
                      )
                    );
                  })}
                </b>
              </a>
            </p>
            <p>
              <a
                // href="tel:+19162947749"
                style={{ color: "black important" }}
                className="text-dark"
              >
                <b>
                  {" "}
                  {/* <CiPhone /> */}
                  {companyData.map((c, index) => {
                    return (
                      c.IsActive && (
                        <React.Fragment key={index} >
                          <CiPhone />

                          {c.ContactNo_Office}
                        </React.Fragment>
                      )
                    );
                  })}
                  {/* 916 294 774ContactNo_Office9{" "} */}
                </b>
              </a>
            </p>

            {/* <h6>
              {" "}
              {companylocation.map((c) => {
                return (
                  c.IsActive && (
                    <React.Fragment>
                      <h6 style={{ width: "300px" }} key={c._id}>
                        {c.StoreDate}
                      </h6>
                    </React.Fragment>
                  )
                );
              })}
            </h6> */}
            {/* <h6>Monday - Friday: 7:00 am - 6:00 pm</h6>
            <h6>Saturday - Sunday: 7:00 am - 5:00 pm</h6> */}
          </Col>

          <Col lg={2} md={6} className="col footerCol">
            {/* <p>
              <b>CATEGORY</b>
            </p>
            {categoryData.map((cd) =>
              cd.IsActive ? (
                <Link
                  key={cd._id}
                
                  to={`/producListing/${cd._id}`}
                  className="headerLink text-dark"
                >
                  {cd.categoryName.toUpperCase()}
                </Link>
              ) : null
            )} */}
            
            {menuData.filter((c) => c.MenuName !== "More").map((cd, index) => {
              return (
                <Link
                  key={index} // don't forget to add a unique key
                  // style={{
                  //   color: backgroundColor === "none" ? "#000" : "#000",
                  //   fontSize: "16px",
                  // }}
                  className="headerLink text-dark"
                  to={cd.MenuURL}
                >
                  {cd.MenuName.toUpperCase()}
                </Link>
              );
            })}
          </Col>
          <Col lg={2} md={6} className="col footerCol">
           
            <p onClick={() => navig("/aboutUS")}>ABOUT US</p>
            <p onClick={() => navig("/shipping")}>SHIPPING</p>
            <p onClick={() => navig("/store-policy")}>STORE POLICY</p>
            <p onClick={() => navig("/faq")}>FAQ</p>
            <p onClick={() => navig("/findStore")}>FIND STORE</p>
          </Col>
          <Col lg={2} md={6}>
            

            <p onClick={() => navig("/loyalty")}>LOYALTY</p>
            <p onClick={() => navig("/referFriend")}>REFER FRIENDS</p>
            <p onClick={() => navig("/franchise")}>FRANCHISE</p>
            <p onClick={() => navig("/contactUs")}>Contact Us</p>
            
          </Col>
        </Row>
        <div
          className="footer_bottom text-center"
          style={{ borderTop: "2px solid black" }}
        >
          <Row>
            <Col lg={6} md={6} sm={6}>
              <p
                className="copyright_text mb-0 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span
                  className="footerBottomTitle"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                >
                  Copyright &copy; 2024 By
                </span>
                <Link
                  className="btn_text footerBottomTitle"
                  to="#"
                >
                  <span>Nick's Roasting Co </span>
                </Link>
              </p>
            </Col>
            {/* <Col lg={6} md={6} sm={6}>
              <p
                className="copyright_text mb-0 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span
                  className="footerBottomTitle"
                  style={{ marginRight: "10px" }}
                >
                  Developed &amp; Designed By:
                </span>
                <Link
                  className="btn_text footerBottomTitle"
                  to="https://www.barodaweb.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Barodaweb | The E-Catalogue Design</span>
                </Link>
              </p>
            </Col> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
