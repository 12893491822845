import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import ProductImg from "../assets/img/productImg.png";
import { Card, Col, Container, Row, FormControl } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import {
  listActiveCategories,
  getCategoryMaster,
} from "../functions/Category/category";

import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";

import { Puff } from "react-loader-spinner";
import { CategoryProductListNew } from "../functions/ProductDetails/Productlist";
const Category = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [CID, setCID] = useState("");
  const [catgeName, setCategName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProductData, setFilteredProductData] = useState([]);

  const loadProductData = () => {
    CategoryProductListNew({ CID: CID }).then((res) => {
      console.log("product Data", res.data.data);
      const resp = res.data.data;
      setProductData(resp);
      getCategoryMaster(CID).then((res) => {
        console.log("categg", res.data);
        const d = res.data.categoryName;
        console.log("d", d);
        setCategName(d);
      });
    });
  };

  useEffect(() => {
    loadProductData();
  }, [CID]);

  const loadCategoryData = () => {
    listActiveCategories().then((res) => {
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setCategoryData(invertedData);
    });
  };

  useEffect(() => {
    loadCategoryData();
    const cid = "65b8cbd1aa85ec2cb2bf9f5e";

    CategoryProductListNew({ CID: cid }).then((res) => {
      console.log("product Data", res.data.data);
      const resp = res.data.data;
      setProductData(resp);
      getCategoryMaster(cid).then((res) => {
        console.log("categg", res.data);
        const d = res.data.categoryName;
        console.log("d", d);
        setCategName(d);
      });
    });
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = productData.filter((product) =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProductData(filteredData);
    } else {
      setFilteredProductData(productData);
    }
  }, [searchTerm, productData]);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Hader />
          <MobileHeader />

          <BradeCrum />
          <Container className="mainDivHowItWork">
            <Row>
              <Col lg={4}>
                <div
                  className="sb_widget sb_category wow fadeInUp"
                  data-wow-delay=".1s"
                >
                  <h3 className="sb_widget_title text-uppercase">Categories</h3>
                  <ul className="ul_li_block">
                    {categoryData.map((cd) => {
                      if (cd.MenuType === "663dcd775945ab5d6c64c057") {
                        return (
                          <li key={cd._id}>
                            <Link
                              // to={`/productListing/${cd._id}`}
                              onClick={() => setCID(cd._id)}
                            >
                              <span>
                                <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                                {cd.categoryName}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                      return null; // Return null for items that don't meet the condition
                    })}
                  </ul>
                </div>
              </Col>
              <Col>
                <Row>
                  <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                    <FaCoffee className="fas fa-coffee" /> {catgeName}
                  </h2>
                  <div className="d-flex justify-content-end" >
                    <FormControl
                      type="text"
                      style={{ width: "33%" }}
                      placeholder="Search.."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-3"
                    />
                  </div>

                  {filteredProductData.map((data) => {
                    return (
                      <Col lg={4} md={6} key={data._id}>
                        <Link
                          to="#"
                        >
                          <Card className="beansCard">
                            <div className="text-center">
                              <img
                                className="productImgListing"
                                // src={ProductImg}
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}
                                style={{ width: "250px", height: "250px" }}
                                alt="img"
                              />
                            </div>
                            <Link to="/location" className="productListingTitle">
                              {data.productName}
                              <div className="text-center orderBtn">
                                Order Now
                              </div>
                            </Link>

                          </Card>
                        </Link>
                      </Col>
                    );
                  })}
                  {/* <Col lg={4} md={6}>
                    <Link
                      to="#"
                    >
                      <Card className="beansCard">
                        <div className="text-center">
                          <img
                            className="productImgListing"
                            src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}

                            style={{ width: "250px", height: "250px" }}
                            alt="img"
                          />
                        </div>
                        <Link className="productListingTitle" to="#">
                          {data.productName}
                        </Link>
                        <div className="text-center orderBtn">Order Now</div>
                      </Card>
                    </Link>
                  </Col>  */}
                  {/* <Col lg={4} md={6}>
                    <Link
                      to="#"
                    >
                      <Card className="beansCard">
                        <div className="text-center">
                          <img
                            className="productImgListing"
                            src={ProductImg}
                            // src={productListingImg}
                            style={{ width: "250px", height: "250px" }}
                            alt="img"
                          />
                        </div>
                        <Link className="productListingTitle" to="#">
                          Title
                        </Link>
                        <div className="text-center orderBtn">Order Now</div>
                    
                      </Card>
                    </Link>
                  </Col>  */}
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Category;
