// import React, { useState, useEffect } from "react";
// import Hader from "../Component/Hader";
// import BradeCrum from "../Component/BradeCrum";
// import HowitWork from "../Component/HowItWokr";
// import Footer from "../Component/Footer";
// import Tab from "react-bootstrap/Tab";
// import ProductImg from "../assets/img/productImg.png";
// import Tabs from "react-bootstrap/Tabs";
// import { Card, Col, Container, Row } from "react-bootstrap";
// import { Link, useParams } from "react-router-dom";
// import { Puff } from "react-loader-spinner";
// import { listSubscriptionProducts } from "../functions/ProductDetails/ProductDetails";
// import { getCategoryFromMenu } from "../functions/Category/category";
// import MobileHeader from "../Component/MobileHeader";

// const Beans = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [subsType, setSubsType] = useState([]);

//   useEffect(() => {
//     const delay = 1000;
//     setTimeout(() => {
//       setIsLoading(false);
//     }, delay);
//   }, []);

//   const [subscriptionProduct, setSubscriptionProduct] = useState([]);
//   const loadSubscriptionProduct = () => {
//     listSubscriptionProducts().then((res) => {
//       console.log("res in subs data", res.data);
//       setSubscriptionProduct(res.data);
//     });
//   };

//   const loadCategoryData = () => {
//     const menuid = "663dcd955945ab5d6c64c05d";
//     getCategoryFromMenu(menuid).then((res) => {
//       console.log("res in category data", res.data);
//       setSubsType(res.data);
//     });
//   };

//   useEffect(() => {
//     loadSubscriptionProduct();
//     loadCategoryData();
//   }, []);

//   return (
//     <React.Fragment>
//       {isLoading ? (
//         // Loader component while loading
//         <div className="loader-container">
//           <Puff
//             color="#c7a17a"
//             height={50}
//             width={50}
//             timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
//           />
//         </div>
//       ) : (
//         <div>
//           <Hader Color="#000" />
//           <MobileHeader />

//           <BradeCrum />
//           <HowitWork />
//           <Container className="bansContainer">
//             <Tabs
//               // defaultActiveKey="home"
//               defaultActiveKey={subsType?.filter((sub) => sub.IsActive)[0]?._id}
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3"
//             >
//               {subsType?.map((sub) => {
//                 return (
//                   sub.IsActive && (
//                     <Tab
//                       //   onClick={() => handleLocations(state._id)}
//                       eventKey={sub._id}
//                       title={sub.categoryName.toUpperCase()}
//                       key={sub._id}
//                     >
//                       <Row>
//                         {subscriptionProduct.length > 0 ? (
//                           subscriptionProduct.map((data) => {
//                             if (data.categories.includes(sub._id)) {
//                               return (
//                                 <Col lg={3} key={data._id}>
//                                   <Link to={`/productDetails/${data._id}`}>
//                                     <Card className="beansCard">
//                                       <div className="text-center">
//                                         <img
//                                           className="productImgListing"
//                                           // src={ProductImg} // Assuming ProductImg is the variable holding the image source
//                                           src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}
//                                           style={{
//                                             width: "250px",
//                                             height: "300px",
//                                           }}
//                                           alt="img"
//                                         />
//                                       </div>
//                                       <Link
//                                         className="productListingTitle"
//                                         to="#"
//                                       >
//                                         {data.productName}
//                                       </Link>
//                                       {/* <div className="text-center orderBtn">Order Now</div> */}
//                                     </Card>
//                                   </Link>
//                                 </Col>
//                               );
//                             }
//                             return null; // Return null for items that don't meet the condition
//                           })
//                         ) : (
//                           <p>There are no items to display</p>
//                         )}
//                       </Row>
//                     </Tab>
//                   )
//                 );
//               })}

//               {/* <Tab eventKey="beans" title="BEANS ">
//                 <Row>
//                   <Col lg={3} md={6}>
//                     <Link
//                       to="#"
//                       // to={`/productDetails/${data._id}`}
//                     >
//                       <Card className="beansCard">
//                         <div className="text-center">
//                           <img
//                             className="productImgListing"
//                             src={ProductImg}
//                             // src={productListingImg}
//                             style={{ width: "250px", height: "300px" }}
//                             alt="img"
//                           />
//                         </div>
//                         <Link className="productListingTitle" to="#">
//                           Title
//                         </Link>
//                         <div className="text-center orderBtn">Order Now</div>

//                       </Card>
//                     </Link>
//                   </Col>
//                   <Col lg={3} md={6}>
//                     <Link
//                       to="#"
//                     >
//                       <Card className="beansCard">
//                         <div className="text-center">
//                           <img
//                             className="productImgListing"
//                             src={ProductImg}
//                             // src={productListingImg}
//                             style={{ width: "250px", height: "300px" }}
//                             alt="img"
//                           />
//                         </div>
//                         <Link className="productListingTitle" to="#">
//                           Title
//                         </Link>
//                         <div className="text-center orderBtn">Order Now</div>

//                       </Card>
//                     </Link>
//                   </Col>
//                 </Row>
//               </Tab>
//               <Tab eventKey="leaves" title="LEAVES">
//                 <Row>
//                   <Col lg={3} md={6}>
//                     <Link
//                       to="#"
//                       // to={`/productDetails/${data._id}`}
//                     >
//                       <Card className="beansCard">
//                         <div className="text-center">
//                           <img
//                             className="productImgListing"
//                             src={ProductImg}
//                             // src={productListingImg}
//                             style={{ width: "250px", height: "300px" }}
//                             alt="img"
//                           />
//                         </div>
//                         <Link className="productListingTitle" to="#">
//                           Title
//                         </Link>
//                         <div className="text-center orderBtn">Order Now</div>

//                       </Card>
//                     </Link>
//                   </Col>
//                   <Col lg={3} md={6}>
//                     <Link
//                       to="#"
//                       // to={`/productDetails/${data._id}`}
//                     >
//                       <Card className="beansCard">
//                         <div className="text-center">
//                           <img
//                             className="productImgListing"
//                             src={ProductImg}
//                             // src={productListingImg}
//                             style={{ width: "250px", height: "300px" }}
//                             alt="img"
//                           />
//                         </div>
//                         <Link className="productListingTitle" to="#">
//                           Title
//                         </Link>
//                         <div className="text-center orderBtn">Order Now</div>

//                       </Card>
//                     </Link>
//                   </Col>
//                   <Col lg={3} md={6}>
//                     <Link
//                       to="#"
//                       // to={`/productDetails/${data._id}`}
//                     >
//                       <Card className="beansCard">
//                         <div className="text-center">
//                           <img
//                             className="productImgListing"
//                             src={ProductImg}
//                             // src={productListingImg}
//                             style={{ width: "250px", height: "300px" }}
//                             alt="img"
//                           />
//                         </div>
//                         <Link className="productListingTitle" to="#">
//                           Title
//                         </Link>
//                         <div className="text-center orderBtn">Order Now</div>

//                       </Card>
//                     </Link>
//                   </Col>
//                 </Row>
//               </Tab> */}
//             </Tabs>
//           </Container>
//           <Footer />
//         </div>
//       )}
//     </React.Fragment>
//   );
// };

// export default Beans;
import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import ProductImg from "../assets/img/productImg.png";
import { Card, Col, Container, Row, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import { getCategoryMaster } from "../functions/Category/category";
import HowitWork from "../Component/HowItWokr";
import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";

import { Puff } from "react-loader-spinner";
import { SubscriptionProductList } from "../functions/ProductDetails/Productlist";
import { getCategoryFromMenu } from "../functions/Category/category";

const Beans = () => {
  const [productData, setProductData] = useState([]);
  const [CID, setCID] = useState("");
  const [catgeName, setCategName] = useState("");

  const [subsType, setSubsType] = useState([]);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const loadCategoryData = () => {
    const menuid = "663dcd955945ab5d6c64c05d";
    getCategoryFromMenu(menuid).then((res) => {
      console.log("res in category data", res.data);
      setSubsType(res.data);
    });
  };

  useEffect(() => {
    // loadSubscriptionProduct();
    loadCategoryData();
  }, []);

  const loadProductData = () => {
    SubscriptionProductList({ CID: CID }).then((res) => {
      console.log("subs Data", res.data.data);
      const resp = res.data.data;
      setProductData(resp);
      getCategoryMaster(CID).then((res) => {
        console.log("categg", res.data);
        const d = res.data.categoryName;
        console.log("d", d);
        setCategName(d);
      });
    });
  };

  useEffect(() => {
    loadProductData();
  }, [CID]);

  useEffect(() => {
    loadCategoryData();
    const cid = "662a3f03268c5a81029d62d6";

    SubscriptionProductList({ CID: cid }).then((res) => {
      console.log("product Data", res.data.data);
      const resp = res.data.data;
      setProductData(resp);
      getCategoryMaster(cid).then((res) => {
        console.log("categg", res.data);
        const d = res.data.categoryName;
        console.log("d", d);
        setCategName(d);
      });
    });
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = productData.filter((product) =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProductData(filteredData);
    } else {
      setFilteredProductData(productData);
    }
  }, [searchTerm, productData]);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Hader />
          <MobileHeader />
          <BradeCrum />
          <HowitWork />
          <Container className="mainDivHowItWork">
            <Row>
              <Col lg={4}>
                <div
                  className="sb_widget sb_category wow fadeInUp"
                  data-wow-delay=".1s"
                >
                  {/* <h3 className="sb_widget_title text-uppercase">
                    SUBSCRIPTION
                  </h3> */}
                  <ul className="ul_li_block">
                    {subsType.map((cd) => {
                      {
                        /* if (cd.MenuType === "663dcd775945ab5d6c64c057") { */
                      }
                      return (
                        <li key={cd._id}>
                          <Link
                            // to={`/productDetails/${cd._id}`}
                            onClick={() => setCID(cd._id)}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              {cd.categoryName}
                            </span>
                          </Link>
                        </li>
                      );
                      {
                        /* } */
                      }
                      return null; // Return null for items that don't meet the condition
                    })}
                  </ul>
                </div>
              </Col>
              <Col>
                <Row>
                  <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                    <FaCoffee className="fas fa-coffee" /> {catgeName}
                  </h2>
                  <div className="d-flex justify-content-end">
                    <FormControl
                      type="text"
                      style={{ width: "33%" }}
                      placeholder="Search.."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-3"
                    />
                  </div>
                  {filteredProductData.map((data) => {
                    return (
                      <Col lg={4} md={6}>
                        <Link
                          // to="#"
                          to={`/productDetails/${data._id}`}
                        >
                          <Card className="beansCard">
                            <div className="text-center">
                              <img
                                className="productImgListing"
                                // src={ProductImg}
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}
                                style={{ width: "240px", height: "420px" }}
                                alt="img"
                              />
                            </div>
                            <div className="productListingTitle">
                              {data.productName}
                            </div>
                            <div className="productListingTitle">
                              ${data.basePrice}
                            </div>
                            <div className="text-center orderBtn mt-2">
                              Order Now
                            </div>
                          </Card>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Beans;
