import React, { useEffect, useState } from "react";
import "../assets/css/feature.css";
import img_01 from "../assets/img/Couple_Drinking_Coffee.webp";
import { FaCoffee } from "react-icons/fa";
import img_02 from "../assets/img/img1.jpg";
import img_03 from "../assets/img/img2.jpg";
import img_04 from "../assets/img/img3.jpg";
import img_05 from "../assets/img/img4.jpg";
import img_06 from "../assets/img/img5.jpg";
import img_07 from "../assets/img/img6.jpg";
import { listContent } from "../functions/CMS/cmsContent";

import img_09 from "../assets/img/img_09.png";
import { Col, Container, Row } from "react-bootstrap";

const Features = () => {
  const [contentData, setContentData] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setContentData(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };

  useEffect(() => {
    loadContentData();
  }, []);

  return (
    <div
      className="pd-tp-50"
      style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        padding: "0 5% 100px 5%",
      }}
    >
      {/* <h2 className='feat-header'>SATISFACTION GUARANTEED</h2>
        <div className='feat-flex'>
            <div className='feat-left'>
                <img src={img_01} alt="" />
            </div>
            <div className='feat-right'>
                <h2>Outstanding Quality</h2>
                <p>Harmonious balance of flavor aroma, and texture. Well- roasted beans, showcasing distinct notes without bitterness, and rich satisfying taste. The freshness of the beans, the brewing method and attention to detail in the entire process contribute to the exceptional coffee experience.</p>
                <img src={img_09} alt="" className='sack-img'/>
            </div>
        </div> */}
      <Row>
      {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery section Heading" && (
                    <>
                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: c.ContentUpload,
                        }}
                      ></p>
                    </>
                  )}
              </React.Fragment>
            );
          })}
        {/* <Col xl={3} lg={6} md={6} className="col-content">
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery section Heading" && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: c.ContentUpload,
                        }}
                      ></p>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Section 5 Homepage Content" && (
                    <>
                    5 content
                      <p
                        dangerouslySetInnerHTML={{
                          __html: c.ContentUpload,
                        }}
                      ></p>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          
        </Col> */}
        <Col xl={5} lg={12} md={12} style={{ textAlign: "center" }}>
          {/* <img src={img_01} alt="" /> */}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 1" && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: c.ContentUpload,
                        }}
                      ></p>
                    </>
                  )}
              </React.Fragment>
            );
          })}
        </Col>
        <Col xl={7} lg={12} md={12} className="col-img">
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 2" && (
                    <>
                      <div className="feature-div">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </div>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 3" && (
                    <>
                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                      <div className="feature-div">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </div>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 4" && (
                    <>
                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                      <div className="feature-div">
                        <p
                          // style={{ border: "1px solid #000" }}
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </div>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 5" && (
                    <>
                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                      <div className="feature-div">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </div>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 6" && (
                    <>
                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                      <div className="feature-div">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </div>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {contentData.map((c) => {
            return (
              <React.Fragment key={c._id}>
                {c.IsActive &&
                  c.ContentFor === "Our gallery Homepage image 7" && (
                    <>
                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                      <div className="feature-div">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </div>
                    </>
                  )}
              </React.Fragment>
            );
          })}
          {/* <img src={img_02} alt="" className="sack-img" /> */}
          {/* <img src={img_03} alt="" className="sack-img" /> */}
          {/* <img src={img_04} alt="" className="sack-img" />
          <img src={img_05} alt="" className="sack-img" />
          <img src={img_06} alt="" className="sack-img" />
          <img src={img_07} alt="" className="sack-img" /> */}
        </Col>
      </Row>
    </div>
  );
};

export default Features;
