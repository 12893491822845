import axios from "axios";

export const getAllShippingAddressofUser = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/allShippingAddress/${userId}`
  );
};

export const createUserShippingAddress = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/userShippingAddress`,
    values
  );
};

export const createGuestUserShippingAddress = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/userShippingAddress`,
    values
  );
};

export const updateDefaultAddress = async (userId, addressId) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/updateDefaultShipAdd/${userId}/${addressId}`
  );
};

export const updateBillingValueSA = async (_id) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/updateBillingValue/userShippingAddress/${_id}`
  );
};

export const updatebillingIdSA = async (_id, billid) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/updateBillingId/userShippingAddress/${_id}/${billid}`
  );
};

export const removeUserShippingAddress = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove/userShippingAddress/${_id}`
  );
};

export const updateUserShippingAddress = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/userShippingAddress/${_id}`,
    values
  );
};

export const getUserShippingAddress = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/userShippingAddress/${_id}`
  );
};

export const getDefaultShippingAddressByUser = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/getDefaultShippingAddress/${userId}`
  );
};
