import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/notification.css";
import Hader from "../../Component/Hader";
import Footer from "../../Component/Footer";
// import { FaCoffee } from "react-icons/fa";
// import BannerTwo from "../Component/BannerTwo";
// import MobileHader from "../Component/MobileHader";
import ProfileSideBar from "../../Component/profileSideBar";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Button, IconButton, Snackbar } from "@mui/material";
import { MyContext } from "../../MyContext";
import BannerTwo from "../../Component/BannerTwo";

import Subscriptioncard from "../../Component/Subscriptioncard";
import Cart from "../../Component/Cart";
import { getSubscriptionProductofUser } from "../../functions/Subscription/SubscriptionMaster";
import { getProductVariantsInfoInCart } from "../../functions/ProductDetails/ProductDetails";
import BradeCrum from "../../Component/BradeCrum";
import MobileHeader from "../../Component/MobileHeader";
const MySubscription = () => {
  const [subscriptionProducts, setSubscriptionProducts] = useState([]);
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const { prodVariantsData, setProdVariantsData } = useContext(MyContext);
  const userId = localStorage.getItem("NicksCoUser");

  const loadSubscriptionData = async () => {
    getSubscriptionProductofUser(userId).then((res) => {
      // console.log("res in subscription product", res.data);
      setSubscriptionProducts(res.data);
      const allOrderDetails = [];
      res.data.forEach((re) => {
        // console.log("ree", re.orderDetails);
        allOrderDetails.push(...re.orderDetails);
        const order = re.orderDetails;
        const promises = order.map(async (item) => {
          // Declare the callback function as async
          // console.log("variantid", item.productVariantsId);
          if (item.productVariantsId !== null) {
            const res1 = await getProductVariantsInfoInCart(
              item.productVariantsId
            );
            // console.log("product variants info", res1.data[0]);
            return res1.data[0]; // Return the object directly
          }
        });

        // Wait for all promises to resolve
        Promise.all(promises.filter(Boolean)).then((variantDataArray) => {
          setProdVariantsData(variantDataArray);
        });
      });

      setOrderDetailsData(allOrderDetails);
    });
  };

  useEffect(() => {
    loadSubscriptionData();
  }, []);
  return (
    <React.Fragment>
      <Cart />
      {/* <BradeCrum /> */}
      <BannerTwo Title="My Subscription" subTitle="My Subscription" />
  <Hader Color="#000" />
      <MobileHeader />
      {/* <BannerTwo Title="My Subscriptions" subTitle="My Subscriptions" /> */}
      <Container className="sec_ptb_120 ">
        <Row>
          <Col lg="3">
            <ProfileSideBar />
          </Col>
          <Col
            lg="9"
            style={{
              //   border: "1px solid #c7a17a",
              marginTop: "20px",
              borderRadius: "5px",
              paddingTop: "20px",
            }}
          >
            {/* {orderDetailsData?.map((subPro) => (
              <>
                <Subscriptioncard />
                <br />
              </>
            ))} */}
            {subscriptionProducts.length > 0 ? (
              <Row>
                {orderDetailsData.map((orderDetail) => {
                  const subscriptionProduct = subscriptionProducts.find(
                    (subsProduct) =>
                      subsProduct.orderDeatilId[0] === orderDetail._id
                  );

                  if (!subscriptionProduct) return null; // Skip if no matching subscription product found

                  return (
                    <>
                      <Col lg={4}>
                        <Subscriptioncard
                          prodVariantsData={prodVariantsData}
                          orderDetail={orderDetail}
                          subscriptionProduct={subscriptionProduct}
                        />
                      </Col>
                      <br />
                    </>
                  );
                })}
              </Row>
            ) : (
              <h5 className="text-center mt-3">
                You haven't subscribed any product yet. <hr />
              </h5>
            )}
            {/* <Subscriptioncard />
            <br />
            <Subscriptioncard />
            <br />
            <Subscriptioncard />
            <br />
            <Subscriptioncard />
            <br /> */}
          </Col>
        </Row>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default MySubscription;
