import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import "./../assets/css/faq.css";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import { Container } from "react-bootstrap";
import Footer from "../Component/Footer";
import { FaCoffee } from "react-icons/fa";
import Faqdropdown from "../Component/FaqDropDown";
import MobileHeader from "../Component/MobileHeader";
import { listFAQ } from "../functions/PolicyAndInquiry/FAQ";
import { listContent } from "../functions/CMS/cmsContent";

const Faq = () => {
  const [faqContent, setFaqContent] = useState([]);
  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);

  const loadFAQData = () => {
    listFAQ()
      .then((res) => {
        console.log("res in faq", res.data);
        setFaqContent(res.data);
      })
      .catch((err) => {
        console.log("error in list banner");
      });
  };
  useEffect(() => {
    loadFAQData();
  }, []);

  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum Title="Faq" />
          <Container className="faqContainer">
            <section className="about_section sec_ptb_120">
              <div className="container">
                <div className="ro">
                  <div className="faq-inner-div">
                    <div className="about_content">
                      <div className="section_title text-uppercase">
                        {/* <h2
                          className="small_title wow fadeInUp"
                          data-wow-delay=".1s"
                        >
                          <FaCoffee className="fas fa-coffee" /> FAQ
                        </h2> */}
                        {/* <h3
                          className="big_title wow fadeInUp"
                          data-wow-delay=".2s"
                        >
                          Frequently asked questions
                        </h3> */}

                        {cmsContent.map((c) => {
                          return (
                            <React.Fragment key={c._id}>
                              {c.IsActive && c.ContentFor === "faq heading" && (
                                <>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: c.ContentUpload,
                                    }}
                                  ></p>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                      {/* <p className="wow fadeInUp" data-wow-delay=".3s">
                        Find answers to some of the most commonly asked
                        questions about our coffee subscription box service. If
                        you have any other questions, please feel free to
                        contact us and we'll be happy to help!
                      </p> */}
                      {/* <ul className="about_info ul_li_block">
                      <li className="wow fadeInUp" data-wow-delay=".4s"> */}
                        {cmsContent.map((c) => {
                          return (
                            <React.Fragment key={c._id}>
                              {c.IsActive && c.ContentFor === "faq content" && (
                                <>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: c.ContentUpload,
                                    }}
                                  ></p>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      {/* </li>
                      </ul> */}
                    </div>
                  </div>
                  {/* {faqContent.map((faq) => {
                    faq.IsActive && (
                    return (
                      <>
                        <Faqdropdown ques={faq.question} ans={faq.answer} />
                        <br />
                      </>
                    );
                    );
                  })} */}
                  {faqContent.map((faq) => {
                    return (
                      faq.IsActive && (
                        <>
                          <Faqdropdown ques={faq.question} ans={faq.answer} />
                          <br />
                        </>
                      )
                    );
                  })}
                  {/* <Faqdropdown/><br />
            <Faqdropdown/><br />
            <Faqdropdown/><br />
            <Faqdropdown/><br /> */}
                </div>
              </div>
            </section>
          </Container>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Faq;
