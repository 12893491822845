import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Card, CardBody, CardHeader } from "reactstrap";

import mapicon from "../assets/img/Locations/mapicon.jpg";

const MapLocation = ({ locations }) => {
  const [mapCenter, setMapCenter] = useState([
    38.659016550770914, -121.00148696060141,
  ]);

  const [viewport, setViewport] = useState({
    center: [38.659016550770914, -121.00148696060141],
    zoom: 8,
  });

  useEffect(() => {
    if (locations.length > 0) {
      // Calculate the average coordinates
      const totalLat = locations.reduce(
        (acc, location) => acc + location.latitude,
        0
      );
      const totalLong = locations.reduce(
        (acc, location) => acc + location.longitude,
        0
      );
      const avgLat = totalLat / locations.length;
      const avgLong = totalLong / locations.length;

      console.log("center", avgLat, avgLong);

      // Set the map center to the average coordinates
      setMapCenter([avgLat, avgLong]);
    }
  }, [locations]);

  const markerIcon = L.icon({
    iconUrl: mapicon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  });

  console.log("ddd", markerIcon);
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0"> Locations </h4>
        </CardHeader>
        <CardBody>
          <div
            // id="gmaps-markers"
            // className="gmaps"
            style={{ position: "relative" }}
          >
            <MapContainer
              // center={[19.07609, 72.877426]}
              center={mapCenter}
              zoom={4}
              style={{
                height: "100%",
                "min-height": "400px",
                width: "100%",
                "min-width": "300px",
                zIndex:"1"
              }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {locations.map(
                (location, index) =>
                  location.IsActive && (
                    <Marker
                      key={index}
                      position={[location.latitude, location.longitude]}
                      icon={markerIcon}
                    >
                      <Popup>
                        {" "}
                        <h4>{location.Location}</h4>
                        <p>{location.Address}</p>{" "}
                      </Popup>
                    </Marker>
                  )
              )}
            </MapContainer>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MapLocation;
