import React, { useState, useContext } from "react";
import { MyContext } from "../MyContext";

const Counter = () => {
  // Using useState hook to maintain state
  const { count, setCount } = useContext(MyContext);

  // Function to increment the counter
  const incrementCounter = () => {
    setCount(count + 1);
  };

  // Function to decrement the counter
  const decrementCounter = () => {
    // setCount(count - 1);
    if (count > 1) {
      setCount(count - 1);
    }
  };

  return (
    // <div>
    <div
      className="quantity-box d-flex align-items-center"
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: " 5px",
        width: "fit-content",
      }}
    >
      <button className="quantity-button" onClick={decrementCounter}>
        -
      </button>
      <div
        className="mx-2"
        style={{
          display: "inline-block",
          margin: "0 5px",
        }}
      >
        {count}
      </div>
      {/* <span>{count}</span> */}
      <button className="quantity-button" onClick={incrementCounter}>
        +
      </button>
    </div>
    // </div>
  );
};

export default Counter;
