import axios from "axios";

export const listProductsDetails = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/product/product-details`
  );
};

export const listAllProductsData = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list-all/product/product-details`
  );
};

export const getProductVariantsInfo = async (productId, productVariants) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/productVariantsInfo`,
    {
      productId,
      productVariants,
    }
  );
};

export const getProductVariantsInfoInCart = async (productVariantsId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/productVariantsInfoInCart/${productVariantsId}`
  );
};

export const getAvailablityOfProduct = async (productId, categValues) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/available-products/product-details/${productId}`,
    categValues
  );
};

export const getRelatedProducts = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/related-products/${productId}`
  );
};

export const getLatestProducts = async () => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/latest-products`
  );
};

export const listSubscriptionProducts = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/subscription-product/product-details`
  );
};
