import React, { useState } from "react";

import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

const Faqdropdown = ({ ques, ans }) => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };
  return (
    <div className="dropdowns">
      <div className="dropdown-qst" onClick={handleToggle}>
        <span>{ques}</span>
        <span>
          <MdArrowDownward
            display={toggle ? "none" : "block"}
            style={{
              backgroundColor: "#eceae3",
              padding: "5px",
              fontSize: "30px",
            }}
          />
          <MdArrowUpward
            display={toggle ? "block" : "none"}
            style={{
              color: "white",
              backgroundColor: "#c7a17a",
              padding: "5px",
              fontSize: "30px",
            }}
          />
        </span>
      </div>
      <div
        style={{ display: `${toggle ? "block" : "none"}` }}
        className="dropdown-ans"
      >
        {ans}
        {/* Text... will... coming... soon... Text... will... coming... soon... Text... will... coming... soon... Text... will... coming... soon... Text... will... coming... soon... Text... will... coming... soon... Text... will... coming... soon... Text... will... coming... soon... */}
      </div>
    </div>
  );
};

export default Faqdropdown;
