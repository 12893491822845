import React, { Component } from "react";
import Slider from "react-slick";
import franchise_1 from "../assets/img/franchise_1.jpg";
import franchise_2 from "../assets/img/franchise_s1.jpg";
import franchise_3 from "../assets/img/franchise_s2.jpg";
import franchise_4 from "../assets/img/franchise_s3.jpg";

function VerticalSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 5000
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
            <img src={franchise_2} alt=""/>
        </div>
        <div>
            <img src={franchise_3} alt=""/>
        </div>
        <div>
            <img src={franchise_4} alt=""/>
        </div>
      </Slider>
    </div>
  );
}

export default VerticalSlider;
