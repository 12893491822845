import React, { useEffect, useState } from "react";
import Hader from "./../Component/Hader";
import BraderCrum from "./../Component/BradeCrum";
import About from "../Component/About";
import Footer from "./../Component/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";
import { listContent } from "../functions/CMS/cmsContent";
import { Puff } from "react-loader-spinner";

const AboutUs = () => {
  useEffect(() => {
    loadContentData();
  }, []);

  const [contentData, setContentData] = useState([]);
  const loadContentData = () => {
    listContent().then((res) => {
      console.log("content data", res.data);
      setContentData(res.data);
    });
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <Hader Color="#000" />
          <MobileHeader />
          <BraderCrum Title="About Us" />
          <Container>
            <section className="about_section sec_ptb_120">
              <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                  {/* <div className="col-lg-6 col-md-12 order-last">
                    <div
                      className="about_image2 wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="about-right">
                        <img
                          src="/static/media/coffee_table_image.99293f23f4733da6ff4b.webp"
                          alt
                          className="about-img"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="">
                    <div className="about_content">
                      <div className="section_title text-uppercase">
                        {/* <h2
                      className="small_title wow fadeInUp"
                      data-wow-delay=".1s"
                    > */}
                        {/* <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 640 512"
                        className="fas fa-coffee"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z" />
                      </svg>{" "} */}
                        {contentData.map((c) => {
                          return (
                            <React.Fragment key={c._id}>
                              {c.IsActive &&
                                c.ContentFor === "about us heading" && (
                                  <>
                                    {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: c.ContentUpload,
                                      }}
                                    ></p>
                                  </>
                                )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <p className="wow fadeInUp" data-wow-delay=".3s">
                       
                        {contentData.map((c) => {
                          return (
                            <React.Fragment key={c._id}>
                              {c.IsActive &&
                                c.ContentFor === "about us content" && (
                                  <>
                                    {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: c.ContentUpload,
                                      }}
                                    ></p>
                                  </>
                                )}
                            </React.Fragment>  
                          );
                        })}
                      </p>
                      <ul className="about_info ul_li_block">
                        <li className="wow fadeInUp" data-wow-delay=".5s">
                          {contentData.map((c) => {
                            return (
                              <React.Fragment key={c._id}>
                                {c.IsActive &&
                                  c.ContentFor === "about us content 2" && (
                                    <>
                                      {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: c.ContentUpload,
                                        }}
                                      ></p>
                                    </>
                                  )}
                              </React.Fragment>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Container>

          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default AboutUs;
