import axios from "axios";

export const addCreditCard = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/add-credit-card`,
    values
  );
};

export const CreateCreditCardOnCheckout = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/credit-card`,
    values
  );
};

export const deleteCreditCard = async (id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/delete-credit-card/${id}`
  );
};

export const updateCreditCard = async (id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update-credit-card/${id}`,
    values
  );
};
export const updateDefaultCard = async (id, isDefault) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update-default-card/${id}/${isDefault}`
  );
};

export const getCreditCardByuserId = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get-credit-card/${userId}`
  );
};

export const getSingleCreditCard = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get-single-credit-card/${id}`
  );
};
