// import * as React from "react";
import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../MyContext";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function Filterselect({
  filterId,
  filterOptionid,
  filterName,
  filterOptions,
}) {
  const {
    setCategProductData,
    sortOption,
    variants,
    setVariants,
    expanded,
    setExpanded,
  } = useContext(MyContext);

  // const [expanded, setExpanded] = React.useState(false);

  const params = useParams();
  const CID = params.cid;

  const handleChange = (panel) => (event, isExpanded) => {
    console.log("pp", panel, isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  const handleCheckboxChange = (e, optionId) => {
    const checked = e.target.checked;

    // if (checked) {
    //   console.log("variants add", optionId);
    //   setVariants((prevVariants) => [...prevVariants, optionId]);
    //   console.log("variants", variants);
    // } else {
    //   setVariants((prevVariants) =>
    //     prevVariants.filter((id) => id !== optionId)
    //   );
    //   console.log("variants uncheck", variants);
    // }
    if (checked) {
      console.log("variants add", optionId);
      setVariants((prevVariants) => [...prevVariants, optionId]);
      console.log("variants", variants);
    } else {
      setVariants((prevVariants) =>
        prevVariants.filter((id) => id !== optionId)
      );
    }
  };

  return (
    <div>
      <Accordion
        expanded={expanded === filterId}
        onChange={handleChange(filterId)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>{filterName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {filterOptions &&
            filterOptions.map((it, index) => (
              <>
                <FormControlLabel
                  value={filterOptionid[index]}
                  key={index}
                  control={
                    <Checkbox
                      //   checked={variants.includes(filterOptionid[index])}
                      // onChange={handleCheckboxChange}
                      onChange={(e) =>
                        handleCheckboxChange(e, filterOptionid[index])
                      }
                    />
                  }
                  label={it}
                  labelPlacement="end"
                />
                <br />
              </>
            ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
