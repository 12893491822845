import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import "../assets/css/loyality.css";
import { FaUser } from "react-icons/fa";
import { GiTwoCoins } from "react-icons/gi";
import { MdOutlineRedeem } from "react-icons/md";

import { Card, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import MobileHeader from "../Component/MobileHeader";
import { Puff } from "react-loader-spinner";
import { getLoyaltyPointMaster } from "../functions/Loyalty/LoyaltyPointMaster";
import { listContent } from "../functions/CMS/cmsContent";

const LoyalityPoint = () => {
  const UserId = localStorage.getItem("NicksCoUser");
  const [isLoading, setIsLoading] = useState(true);
  const [point, setPoints] = useState(0);

  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);

  useEffect(() => {
    getLoyaltyPointMaster("660aeeada81862d11e0ec5be").then((res) => {
      setPoints(res.data.points);
    });
  }, []);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum Title="Loyalty " />
          <Container className="loyalityContainer">
            <Row>
              <div className="section_title text-uppercase">
                <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 640 512"
                    className="fas fa-coffee"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z" />
                  </svg>{" "}
                  loyalty
                </h2>
              </div>
              <Col lg={4}>
                <div className="loyalityCard">
                  <h6 className="loyalitytitle">
                    <FaUser className="userIcon" />
                  </h6>
                  <h4 className="loyalitytitle">Sign Up</h4>
                  {/* <p className="loyalitytitle">
                    Sign up as a member to start enjoying the loyalty program
                  </p> */}

                  {cmsContent.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive && c.ContentFor === "loyalty signup" && (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: c.ContentUpload,
                              }}
                            ></p>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Col>
              <Col lg={4}>
                <div className="loyalityCard">
                  <h6 className="loyalitytitle">
                    <GiTwoCoins className="userIcon" />
                  </h6>
                  <h4 className="loyalitytitle">Earn Points</h4>
                  {cmsContent.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive && c.ContentFor === "loyalty earn points" && (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: c.ContentUpload,
                              }}
                            ></p>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {/* <p className="loyalitytitle">
                    Purchase a product and Get 1 Loyalty Points for every $1
                    spent
                  </p>
                  <h6 className="loyalitytitle">Sign up to the site</h6>
                  <p className="loyalitytitle">Get {point} Loyalty Points</p> */}
                </div>
              </Col>
              <Col lg={4}>
                <div className="loyalityCard">
                  <h6 className="loyalitytitle">
                    <MdOutlineRedeem className="userIcon" />
                  </h6>
                  <h4 className="loyalitytitle">Redeem Rewards</h4>
                  {cmsContent.map((c) => {
                    return (
                      <React.Fragment key={c._id}>
                        {c.IsActive && c.ContentFor === "loyalty redeem rewards" && (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: c.ContentUpload,
                              }}
                            ></p>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {/* <p className="loyalitytitle">
                    Flexible reward 50 Loyalty Points = $5 discount
                  </p> */}
                </div>
              </Col>
              <div className="viewBtnDiv">
                {UserId && <Link to="/my-rewards">View Points</Link>}
              </div>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default LoyalityPoint;
