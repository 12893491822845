import React, { useEffect, useContext, useState } from "react";
// import bradCrumBg from "./../assets/img/bradCrumBaner.webp";
// import bradCrumBg from "./../assets/img/sliderTwo.jpg";
import bradCrumBg from "./../assets/img/banner3.jpg";
// import bradCrumBg from "./../assets/img/nicksbanner.jpg";

import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { getCategoryMaster } from "../functions/Category/category";
import { useParams } from "react-router-dom";
import { MyContext } from "../MyContext";
import { listContent } from "../functions/CMS/cmsContent";

const BradeCrum = ({ Title }) => {
  // console.log("title", Title);

  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);

  return (
    <React.Fragment>
      <div
        className="bradCrumDiv product-group-card"
        style={{
          backgroundImage: `url(${bradCrumBg})`,
          backgroundSize: "cover",
        }}
      >
        <Container>
          <div className="bradmCrumTitleSection">
            <div
              className="bradCrumTitle  text-uppercase"
              style={{ color: "#fff" }}
            >
              {/* <p style={{ fontSize: "30px" }}>Home/{Title}</p> */}
              {cmsContent.map((c) => {
                return (
                  <React.Fragment key={c._id}>
                    {c.IsActive && c.ContentFor === "banner slogan" && (
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: c.ContentUpload,
                          }}
                        ></p>
                      </>
                    )}
                  </React.Fragment>
                );
              })}

              {/* <span
                style={{ color: "#fff", fontWeight: "bold", fontSize: "25px" }}
              >
                From Bean to Brew,
              </span>{" "}
              <br />
              Excellence in Every Cup. */}
            </div>

            <div>
              {/* <Link className="bradCrunLink" to="/">
                Home
              </Link>
              <FaChevronRight /> */}
              {/* <span>{Title}</span> */}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BradeCrum;
