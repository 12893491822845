import React, { useState, useEffect } from "react";
import { Card, Label, Input, Row } from "reactstrap";
import "./../assets/css/login.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Hader from "../Component/Hader";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { OTPResend, createUsers } from "../functions/Auth/Users";
import { createNewsLetter } from "../functions/PolicyAndInquiry/NewsLetterSubs";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "react-bootstrap";
import MobileHeader from "../Component/MobileHeader";

const initialState = {
  firstName: "",
  lastName: "",
  contactNo: "",
  email: "",
  userImage: "",
  password: "",
  IsPublic: false,
  followers: [],
  following: [],
  cart: [],
  defaultShippingAddress: 0,
  defaultBillingAddress: 0,
  shippingAddress: [],
  billingAddress: [],
  IsActive: true,
};

const OTPVerify = () => {
  const formData = new FormData();
  const [time, setTime] = useState(localStorage.getItem("remTime"));
  const [values, setValues] = useState(initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    firstName,
    lastName,
    contactNo,
    email,
    userImage,
    password,
    IsPublic,
    followers,
    following,
    cart,
    defaultShippingAddress,
    defaultBillingAddress,
    referredBy,
    points,
    IsActive,
  } = location.state.formDataObject;
  // } = values;
  const { otp } = values;

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [errLN, setErrLN] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (values.otp === "") {
      errors.otp = "OTP is required!";
      setErrLN(true);
    }
    if (values.lastName !== "") {
      setErrLN(false);
    }
    return errors;
  };

  const validClassOTP =
    errLN && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  const startTimer = () => {
    setTime(180); // Reset the countdown to 2 minutes
    localStorage.setItem("remTime", 180);
  };

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          localStorage.setItem("remTime", 0);
          clearInterval(timer);
          return 0;
        } else {
          localStorage.setItem("remTime", Math.max(time - 1, 0));
          return Math.max(time - 1, 0);
        }
      });
    }, 1000);
  }, []);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleClick = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);
    console.log(erros);

    if (Object.keys(erros).length === 0) {
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("contactNo", contactNo);
      formData.append("email", email);
      formData.append("userImage", userImage); // Assuming userImage is a file
      formData.append("password", password);
      formData.append("IsPublic", IsPublic);
      formData.append("followers", JSON.stringify(followers)); // Convert array to string
      formData.append("following", JSON.stringify(following)); // Convert array to string
      formData.append(
        "cart",
        localStorage.getItem("guestCartData")
          ? localStorage.getItem("guestCartData")
          : JSON.stringify(cart)
      ); // Convert array to string
      formData.append("defaultShippingAddress", defaultShippingAddress);
      formData.append("defaultBillingAddress", defaultBillingAddress);
      formData.append("referredBy", referredBy);
      formData.append("points", points);
      formData.append("IsActive", IsActive);
      formData.append("otp", otp);

      createUsers(formData)
        .then((res) => {
          // console.log("res in signup", res.data);
          if (res.data.isVerified) {
            createNewsLetter({ email: email, IsActive: true }).then((res) => {
              console.log(res);
            });
            if (res.data.guestCartData) {
              localStorage.removeItem("guestCartData");
            }
            toast.success("Account created successfully!");
            setValues(initialState);
            setIsSubmit(false);
            setFormErrors({});
            localStorage.setItem("NicksCoUser", res.data.data._id);
            if (res.data.guestCartData) {
              navigate("/shopping-cart");
            } else {
              navigate("/");
            }

            // window.location.replace("/");
          } else {
            setFormErrors({ otp: "Invalid OTP!" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleResendOTP = (e) => {
    e.preventDefault();
    setFormErrors({});

    const {
      firstName,
      lastName,
      contactNo,
      email,
      userImage,
      password,
      IsPublic,
      followers,
      following,
      cart,
      defaultShippingAddress,
      defaultBillingAddress,
      referredBy,
      points,
      IsActive,
    } = location.state.formDataObject;
    // } = values;
    const formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("contactNo", contactNo);
    formData.append("email", email);
    formData.append("userImage", userImage); // Assuming userImage is a file
    formData.append("password", password);
    formData.append("IsPublic", IsPublic);
    formData.append("followers", JSON.stringify(followers)); // Convert array to string
    formData.append("following", JSON.stringify(following)); // Convert array to string
    formData.append("cart", JSON.stringify(cart)); // Convert array to string
    formData.append("defaultShippingAddress", defaultShippingAddress);
    formData.append("defaultBillingAddress", defaultBillingAddress);
    formData.append("referredBy", referredBy);
    formData.append("points", points);
    formData.append("IsActive", IsActive);
    OTPResend(formData)
      .then((res) => {
        console.log("res", res.data);
        toast.success("OTP Sent Successfully !");
        startTimer();

        if (res.data.isVerified) {
          setValues(initialState);
          setIsSubmit(false);
          setFormErrors({});
        } else {
          setFormErrors({ otp: "Invalid OTP!" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div>
    <Hader Color="#000" />
        <MobileHeader />
      </div>
      <ToastContainer />

      <Container>
        <div
          className="loginDiv"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <div className="form">
            <Card className="loginCard">
              <span className="h4 mb-3 text-center">
                Check your Email for a OTP
              </span>
              <form>
                <div className="mb-3 mt-3">
                  <input
                    id="otp"
                    type="number"
                    className={validClassOTP}
                    placeholder="Enter sent OTP"
                    required
                    name="otp"
                    value={otp}
                    style={{ marginBottom: 0 }}
                    onChange={handleChange}
                  />

                  {isSubmit && (
                    <p className="text-danger" style={{ textAlign: "left" }}>
                      {formErrors.otp}
                    </p>
                  )}
                </div>

                <p>
                  OTP Expired?{" "}
                  <span>
                    <Link
                      className="text-dark"
                      style={{ fontWeight: "bold" }}
                      to="#"
                      onClick={handleResendOTP}
                    >
                      Resend new OTP
                    </Link>
                  </span>
                  <p>
                    OTP Expires in: {`${Math.floor(time / 60)}`.padStart(2, 0)}:
                    {`${time % 60}`.padStart(2, 0)}
                    {/* {time === 0 && <p>OTP expired</p>}  */}
                  </p>
                </p>
                {/* Click on submit button to submit the form */}
                <button type="submit" onClick={handleClick}>
                  Sign Up
                </button>
              </form>
            </Card>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default OTPVerify;
