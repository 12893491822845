import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container } from "react-bootstrap";
import { getRelatedProducts } from "../functions/ProductDetails/ProductDetails";
import { Link } from "react-router-dom";
import "../assets/css/relatedProducts.css";
const MultipleItemCarousel = ({ productId, Title }) => {
  const [relatedProductData, setRelatedProductsData] = useState([]);

  const loadRelatedProductsData = () => {
    getRelatedProducts(productId).then((res) => {
      console.log("related products", res.data);
      setRelatedProductsData(res.data);
    });
  };

  useEffect(() => {
    loadRelatedProductsData();
  }, []);

  const loadProductData = (bid) => {
    window.location.replace(`/productDetails/${bid}`);
    // window.location.reload();
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1025, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <Container>
        <h4 className="reletedTitle">{Title}</h4>
        <Carousel responsive={responsive}>
          {relatedProductData?.map((data) => (
            <Link
              // to={`/productDetails/${data._id}`}
              className="element-item chocolate greantea"
              data-category="chocolate"
              key={data._id}
              onClick={() => loadProductData(data._id)}
              // onClick={() => window.location.reload()}
            >
              <div>
                <div
                  className="shop_card wow fadeInUp reledCard"
                  data-wow-delay=".1s"
                >
                  {/* Replace the <a> tag with <Link> */}
                  <div className="item_image" style={{ width: "350px" }}>
                    <img
                      src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}
                      alt="image_not_found"
                      style={{
                        height: "350px",
                        width: "270px",
                        paddingLeft: "50px",
                      }}
                      // height="250px"
                      // width="300px"
                    />
                  </div>
                  <div className="item_content">
                    <h5 className="item_title_related text-uppercase">
                      {data.productName}
                    </h5>
                    {/* You can add price if needed */}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Carousel>
      </Container>
    </React.Fragment>
  );
};

export default MultipleItemCarousel;
