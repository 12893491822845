import React, { useEffect, useState } from "react";
import Hader from "../../Component/Hader";
import { FaCoffee, FaRegUserCircle, FaAddressBook } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Footer from "../../Component/Footer";
import BannerTwo from "../../Component/BannerTwo";
import {
  addCreditCard,
  updateCreditCard,
  getCreditCardByuserId,
  deleteCreditCard,
  getSingleCreditCard,
  updateDefaultCard,
} from "../../functions/Auth/CreditCard";
import { getUsers } from "../../functions/Auth/Users";
import { updateDefaultAddress } from "../../functions/Auth/UserShippings";
import { Link } from "react-router-dom";
import ProfileSideBar from "../../Component/profileSideBar";
import Cart from "../../Component/Cart";
import { Puff } from "react-loader-spinner";
import "../../assets/css/profile.css";
import MobileHeader from "../../Component/MobileHeader";
import { listCountry } from "../../functions/Location/Location";
import moment from "moment";

const initialState = {
  userId: localStorage.getItem("NicksCoUser"),
//  firstName: "",
//  lastName: "",
  cardNumber: "",
  cvv: "",
  expiryDate: "",
  // expiryMonth: "",
  // expiryYear: "",
  isDefault: false,
  cardHolderName: "",
//  city: "",
//  address: "",
//  email: "",
 // zipCode: "",
//  country: "",
  isActive: true,
};

const MyWallet = () => {
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal_list, setmodal_list] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cardArray, setCardArray] = useState([]);
  const [id, setId] = useState("");
  const [values, setValues] = useState(initialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisplayError, setIsDIsplayError] = useState(false);
  const [_id, set_Id] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const [countries, setCountries] = useState([]);
  const [remove_id, setRemove_id] = useState("");

  const {
    userId,
    //firstName,
   // lastName,
    cardNumber,
    cvv,
    expiryDate,
    // expiryMonth,
    // expiryYear,
    isDefault,
    cardHolderName,
    address,
  //  email,
  //  city,
  //  zipCode,
  //  country,
    isActive,
  } = values;

  const tog_list = () => {
    setmodal_list(!modal_list);
    setValues(initialState);
    setDisplaycvv("");
    setIsEdit(false);
  };

  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = (_id) => {
    setmodal_delete(!modal_delete);
    setRemove_id(_id);
  };

  useEffect(() => {
    loadCardDetails();
  }, []);

  const loadCardDetails = () => {
    getCreditCardByuserId(userId)
      .then((res) => {
        const response = res.data;
        console.log("res in card", res.data);
        setCardData(response);
      })
      .catch((err) => {
        console.log("error in load card details", err);
      });
  };

  useEffect(() => {
    loadCountries();
  }, []);

  const loadCountries = () => {
    listCountry().then((res) => {
      setCountries(res.data);
    });
  };

  const [expirationdate, setExpirationdate] = useState("");
  const [shower, setShower] = useState(false);

  const handleExpChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    if (value.length === 1) {
      // Only accept 0 or 1 as the first character
      if (value !== '0' && value !== '1') {
        return;
      }
    }

    if (value.length === 2) {
      // Only accept 0-9 as the second character
      if (!/[0-9]/.test(value[1])) {
        return;
      }
      // Ensure the month is valid (01-12)
      if (parseInt(value, 10) > 12) {
        return;
      }
      if (parseInt(value, 10) === 0) {
        return;
      }
    }

    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }

    if (value.length === 5) {
      // Ensure the year part is a valid two-digit number
      const month = parseInt(value.slice(0, 2), 10);
      const year = parseInt(value.slice(3), 10);
      const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
      const currentMonth = new Date().getMonth() + 1; // Get current month (0-based index)

      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        setShower(true);
      } else {
        setShower(false);

        const [montho, yearo] = value.split('/');
        const fullYear = `20${yearo}`;

        const formattedDate = `${fullYear}-${montho}`;

        setValues({
          ...values,
          [e.target.name]: formattedDate,
        });
        
      }
    }

    setExpirationdate(value);


  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const [displaycvv, setDisplaycvv] = useState("");
  const handleChangecvv = (e) => {
    const inputValue = e.target.value;
    const maskedCVV = inputValue.replace(/\d/g, "*"); // Replace each digit with *
    setDisplaycvv(maskedCVV);
    setValues({ ...values, cvv: inputValue });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);
    setIsDIsplayError(true);

    if (Object.keys(erros).length === 0) {
      updateCreditCard(_id, values)
        .then((res) => {
          setmodal_list(!modal_list);
          setValues(initialState);
          setIsDIsplayError(false);
          setIsEdit(false);
          loadCardDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleTog_edit = (_id) => {
    setmodal_list(!modal_list);
    setIsSubmit(false);
    setIsDIsplayError(true);
    setIsEdit(true);
    set_Id(_id);
    getSingleCreditCard(_id)
      .then((res) => {
        console.log(res.data);
        const response = res.data;
        const c = response.cvv;
        const maskedCVV = c.replace(/\d/g, "*"); // Replace each digit with *
        console.log("mm", maskedCVV);
        setDisplaycvv(maskedCVV);
        const date = new Date(response.expiryDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        setValues({
          ...values,
          userId: response.userId,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          expiryDate: `${year}-${month}`,
          city: response.city,
          country: response.country,
          address: response.address,
          zipCode: response.zipCode,
          cardHolderName: response.cardHolderName,
          cardNumber: response.cardNumber,
          cvv: response.cvv,
          isActive: response.isActive,
        });
        console.log("e", response.expiryDate);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    console.log("erros", erros);
    setFormErrors(erros);
    setIsSubmit(true);
    setIsDIsplayError(true);

    if (Object.keys(erros).length === 0) {
      addCreditCard(values)
        .then((res) => {
          console.log("res", res.data.data);
          setmodal_list(!modal_list);
          setValues(initialState);
          setIsDIsplayError(false);
          setIsSubmit(false);
          loadCardDetails();
          setIsEdit(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteCreditCard(remove_id)
      .then((res) => {
        setmodal_delete(!modal_delete);
        loadCardDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [checkDefault, setCheckDefault] = useState([]);
  const handlecheck = (e, cardId) => {
    setValues({ ...values, isDefault: e.target.checked });
    // if (e.target.checked) {
    const def = e.target.checked;
    updateDefaultCard(cardId, def)
      .then((res) => {
        console.log(res.data);
        setCheckDefault([cardId]);
        loadCardDetails();
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const mask_card_number = (cardNumber) => {
    cardNumber = cardNumber.toString();

    var initialDigits = cardNumber.slice(0, -4);
    var lastFourDigits = cardNumber.slice(-4);

    // Mask the initial digits except the last four
    var maskedInitialDigits = "*".repeat(initialDigits.length);

    // Concatenate the masked initial digits with the last four digits
    var maskedCardNumber = maskedInitialDigits + lastFourDigits;

    return maskedCardNumber;
  };
  // const handleUpdate = () => {
  //   setIsEdit(false);
  //   let newArray = cardArray.map((it, index) => {
  //     if (id == index) {
  //       return values;
  //     } else {
  //       return it;
  //     }
  //   });
  //   setCardArray(newArray);
  //   setmodal_list(!modal_list);
  // };

  const [errFN, setErrFN] = useState(false);
  const [errCardN, setErrCardN] = useState(false);
  const [errCardName, setErrCardName] = useState(false);
  const [errCVV, setErrCVV] = useState(false);
  const [errLN, setErrLN] = useState(false);
  const [errCN, setErrCN] = useState(false);
  const [errCID, setErrCID] = useState(false);
  const [errCI, setErrCI] = useState(false);
  const [errAL1, setErrAL1] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errExp, setErrExp] = useState(false);
  const [errZP, setErrZP] = useState(false);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const cvvregex = /^[0-9]{3, 4}$/;
    const visaregex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const mastercardregex =
      /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/;
    const cardreqgex = /^(?:d[ -]*?){13,16}$/;

    //if (values.email === "") {
    //  errors.email = "Email is required!";
    //  setErrEmail(true);
   // } else if (!regex.test(values.email)) {
   //   errors.email = "Invalid Email address!";
   //   setErrEmail(true);
    //} else {
    //  setErrEmail(false);
    //}

    if (values.cardNumber === "") {
      errors.cardNumber = "Card Number is required!";
      setErrCardN(true);
    }
    if (values.cardNumber !== "") {
      setErrCardN(false);
    }

    if (values.cardHolderName === "") {
      errors.cardHolderName = "Card Holder Name is required!";
      setErrCardName(true);
    }
    if (values.cardHolderName !== "") {
      setErrCardName(false);
    }

    if (values.cvv === "") {
      errors.cvv = "cvv is required!";
      setErrCVV(true);
    }
    // else if (!cvvregex.test(values.cvv)) {
    //   errors.cvv = "Invalid cvv!";
    //   setErrCVV(true);
    // }
    if (values.cvv !== "") {
      setErrCVV(false);
    }

    //if (values.firstName === "") {
     // errors.firstName = "First name is required!";
     // setErrFN(true);
   // }
   // if (values.firstName !== "") {
   //   setErrFN(false);
   // }
    if (values.expiryDate === "") {
      errors.expiryDate = "Expiry Date is required!";
      setErrExp(true);
    }
    if (values.expiryDate !== "") {
      setErrExp(false);
    }

   // if (values.lastName === "") {
    //  errors.lastName = "Last name is required!";
    //  setErrLN(true);
   // }
   // if (values.lastName !== "") {
  //    setErrLN(false);
  //  }

   // if (values.city === "") {
   //   errors.city = "Select City required!";
   //   setErrCI(true);
   // }
   // if (values.city !== "") {
   //   setErrCI(false);
   // }

   // if (values.country == "") {
    //  errors.country = "Select country name!";
   //   setErrCID(true);
   // }
   // if (values.country !== "") {
   //   setErrCID(false);
  //  }
   // if (values.email == "") {
   //   errors.email = "Email is Required!";
   //   setErrAL1(true);
   // }
   // if (values.email !== "") {
   //   setErrAL1(false);
   // }

   // if (values.address == "") {
   //   errors.address = "Address is Required!";
   //   setErrAL1(true);
   // }
   // if (values.address !== "") {
   //   setErrAL1(false);
   // }

   // if (values.zipCode == "") {
    //  errors.zipCode = "zipCode is Required!";
   //   setErrZP(true);
   // }
   // if (values.zipCode !== "") {
   //   setErrZP(false);
   // }

    return errors;
  };

  const validClassCountryName =
    errCN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassCityName =
    errCI && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassCardNumber =
    errCardN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassCardName =
    errCardName && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassCVV =
    errCVV && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassAdd =
    errAL1 && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassExpDate =
    errAL1 && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassFN =
    errFN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassLN =
    errLN && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassEmail =
    errEmail && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassZip =
    errZP && isDisplayError ? "form-control is-invalid" : "form-control";

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
          <Cart />
          <div>
            <Hader Color="#000" />
            <MobileHeader />
          </div>
          <BannerTwo Title="My Wallet" subTitle="My Wallet" />
          <Container className="sec_ptb_120 ">
            <Row>
              <Col lg="3">
                <ProfileSideBar />
              </Col>
              <Col lg="9">
                {cardData.length > 0 ? (
                  cardData.map((card, index) => (
                    <Card
                      className="myProfileCard"
                      style={{ borderRadius: "0" }}
                    >
                      <p className="addressTitle">
                        {card.firstName} {card.lastName}
                      </p>
                      <p style={{ whiteSpace: "wrap" }}>
                        <b>Card Number:</b> {mask_card_number(card.cardNumber)}
                        <br />
                        <b>Expiry Date:</b>{" "}
                        {moment(new Date(card.expiryDate)).format("MM/YY")}
                        <br />
                        {card.city}
                        {/* <br />
                        {card.country} */}
                      </p>

                      {/* <p className="addressTitle">{address.contactNo}</p> */}
                      <Row className="editDiv">
                        <Col lg={2}>
                          <Link
                            className="editlink"
                            to="#"
                            onClick={() => {
                              handleTog_edit(card._id);
                            }}
                          >
                            Edit
                          </Link>
                        </Col>
                        <Col lg={2}>
                          {" "}
                          <Link
                            className="editlink"
                            to="#"
                            // onClick={console.log("")}
                            onClick={() => tog_delete(card._id)}
                          >
                            Remove
                          </Link>
                        </Col>
                        <Col className="defaultCol" lg={8}>
                          {/* <input type="checkBox" />{" "}
                  <Link className="editlink" to="#">
                    {" "}
                    Default Addresses
                  </Link> */}
                          <div className="form-check mb-2">
                            <input
                              key={card._id}
                              type="checkbox"
                              name="isDefault"
                              value={isDefault}
                              onChange={(e) => handlecheck(e, card._id)}
                              // checked={defaultAdd}
                              checked={checkDefault.includes(card._id)}
                            />
                            <label
                              // className="form-check-label"
                              htmlFor="activeCheckBox"
                              className="editlink"
                            >
                              Default Card
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </Card>
                  ))
                ) : (
                  <h5 className="text-center mt-3">
                    You Haven’t Saved Any Cards Yet. <hr />
                  </h5>
                )}

                <Link
                  className="btn btn-lg  p-3 m-3 "
                  style={{
                    border: "1px solid black",
                    fontSize: "15px",
                    borderRadius: "0px",
                  }}
                  // to="/add-new-address"
                  onClick={tog_list}
                >
                  {" "}
                  <CiCreditCard1 className="m-1" />
                  Add New Card
                </Link>

                {cardArray.length == 0 && (
                  <h5
                    style={{
                      color: "grey",
                      textAlign: "center",
                      marginTop: "150px",
                    }}
                  >
                    Securely save your payment details when you place an order
                    at checkout.
                  </h5>
                )}
              </Col>
            </Row>
            <Modal
              isOpen={modal_list}
              toggle={() => {
                tog_list();
              }}
              className="modal-lg"
              centered
            >
              <ModalHeader
                className="bg-light p-3"
                toggle={() => {
                  setmodal_list(false);
                }}
              >
                {isEdit ? "Edit Card" : "Add Card"}
              </ModalHeader>
              <ModalBody style={{ backgroundColor: "white" }}>
                <Card>
                  <CardHeader>Card Details</CardHeader>
                  <Row style={{ margin: "20px" }}>
                    <Col lg={12}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "15px" }}>
                          Card Number<span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            width: "100%",
                            // padding: "5px",
                            border: "1px solid black",
                            marginBottom: " 10px",
                            height: "50px",
                            borderRadius: "0px",
                          }}
                          type="text"
                          // style={{ height: "50px" }}
                          className={validClassCardNumber}
                          placeholder="Enter Card Number"
                          name="cardNumber"
                          value={cardNumber}
                          onChange={(e) => handleChange(e)}
                        />
                        {isDisplayError && (
                          <p className="text-danger">{formErrors.cardNumber}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          Expiration date<span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid black",
                            marginBottom: " 10px",
                            height: "50px",
                            borderRadius: "0px",
                            borderRadius: "0px",
                          }}
                          type="text"

                          onChange={(e) => handleExpChange(e)}
                          className={validClassExpDate}
                          placeholder="MM/YY"
                          maxLength="5"
                          name="expiryDate"
                          // value={`${expiryMonth}/${expiryYear}`}
                          value={expirationdate}
                        />
                        {shower && <p className="text-danger">Invalid expiryDate</p>}
                        {isDisplayError && (
                          <p className="text-danger">{formErrors.expiryDate}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          Security code (CVV)
                          <span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid black",
                            marginBottom: " 10px",
                            height: "50px",
                            borderRadius: "0px",
                          }}
                          type="text"
                          inputMode="numeric" // Allow only numeric input on mobile devices
                          // style={{ height: "50px" }}
                          className={validClassCVV}
                          placeholder="XXX"
                          onChange={(e) => handleChangecvv(e)}
                          name="cvv"
                          value={displaycvv}
                        />
                        {isDisplayError && (
                          <p className="text-danger">{formErrors.cvv}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          Cardholder name<span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid black",
                            marginBottom: " 10px",
                            height: "50px",
                            borderRadius: "0px",
                          }}
                          type="text"
                          // style={{ height: "50px" }}
                          className={validClassCardName}
                          placeholder=""
                          onChange={(e) => handleChange(e)}
                          name="cardHolderName"
                          value={cardHolderName}
                        />
                        {isDisplayError && (
                          <p className="text-danger">
                            {formErrors.cardHolderName}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Row style={{ marginTop: "20px", margin: "10px" }}>
                  
                 
                  

                  {/* <Col lg={6}>
                    <Label style={{ marginTop: "5px" }}>
                      country<span className="text-danger">*</span>{" "}
                    </Label>
                    <div className="mb-3">
                      <Input
                        style={{
                          width: "100%",
                          padding: "5px",
                          border: "1px solid #c7a17a",
                          marginBottom: " 10px",
                        }}
                        type="text"
                        placeholder=""
                        className={validClassCountryName}
                        required
                        onChange={(e) => handleChange(e)}
                        name="country"
                        value={country}
                      />

                      {isDisplayError && (
                        <p className="text-danger">{formErrors.country}</p>
                      )}
                    </div>
                  </Col> */}
                  
                </Row>
              </ModalBody>
              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-dark"
                    id="add-btn"
                    onClick={isEdit ? handleUpdate : handleSubmit}
                  >
                    {isEdit ? "Update" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={tog_list}
                  >
                    Cancel
                  </button>
                </div>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={modal_delete}
              toggle={() => {
                tog_delete();
              }}
              centered
            >
              <ModalHeader
                className="bg-light p-3"
                toggle={() => {
                  setmodal_delete(false);
                }}
              >
                Remove Card Details
              </ModalHeader>
              <form>
                <ModalBody>
                  <div className="mt-2 text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/gsqxdxog.json"
                      trigger="loop"
                      colors="primary:#f7b84b,secondary:#f06548"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Are you sure ?</h4>
                      <p className="text-muted mx-4 mb-0">
                        You want to Remove this Card Details
                      </p>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#f06548" }}
                      className="btn btn-danger"
                      id="add-btn"
                      onClick={handleDelete}
                    >
                      Remove
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => setmodal_delete(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </ModalFooter>
              </form>
            </Modal>
          </Container>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default MyWallet;
