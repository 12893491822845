import React, { useState, useEffect, useContext } from "react";
import "./../assets/css/productListing.css";
import BradeCrum from "../Component/BradeCrum";
import Hader from "../Component/Hader";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaCoffee } from "react-icons/fa";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import { Puff } from "react-loader-spinner";

import Filterdrawer from "../Component/Filterdrawer";
import Cart from "../Component/Cart";

import { listSubscriptionProducts } from "../functions/ProductDetails/ProductDetails";
import MobileHeader from "../Component/MobileHeader";

const Subscription = () => {
  const [subscriptionProduct, setSubscriptionProduct] = useState([]);
  const loadSubscriptionProduct = () => {
    listSubscriptionProducts().then((res) => {
      console.log("res in subs data", res.data);
      setSubscriptionProduct(res.data);
    });
  };
  useEffect(() => {
    loadSubscriptionProduct();
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          {/* <Filterdrawer open={drawerOpen} toggleDrawer={toggleDrawer} /> */}
          <Cart />
      <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum
          //  Title="From Bean to Brew, Excellence in Every Cup."
          />

          <Container className="productListinContainer">
            <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
              <FaCoffee className="fas fa-coffee" /> SUBSCRIPTION
            </h2>
            <section className="shop_section sec_ptb_120 bg_default_gray">
              <div>
                <Row>
                  {subscriptionProduct.length > 0 ? (
                    subscriptionProduct.map((data) => (
                      <Col lg={3} key={data._id}>
                        <Link
                          // to="#"
                          to={`/productDetails/${data._id}`}
                        >
                          <Card className="productListingCard">
                            <div className="text-center">
                              <img
                                className="productImgListing"
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}
                                // src={productListingImg}
                                style={{ width: "200px", height: "200px" }}
                                alt="img"
                              />
                            </div>
                            <Link
                              className="productListingTitle"
                              // to="#"
                              to={`/productDetails/${data._id}`}
                            >
                              {data.productName}
                            </Link>
                            <div
                              className="text-center"
                              style={{ fontWeight: "bold" }}
                            >
                              ${data.basePrice}
                            </div>

                            {/* <Link className="shopNowBtn" to="#">
                              Shop Now
                            </Link> */}
                          </Card>
                        </Link>
                      </Col>
                    ))
                  ) : (
                    <h5 className="text-center text-dark">
                      {" "}
                      There is no items to display.{" "}
                    </h5>
                  )}
                </Row>
                {/* </div> */}
              </div>
            </section>
          </Container>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Subscription;
