import axios from "axios";

export const updatePointsConversation = async (points) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/pointConversation`,
    {points}
  );
};

export const getPointsConversation= async () => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/pointConversation`
    );
  };
