import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaAngleDoubleRight, FaCoffee } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import Blog1 from "./../assets/img/11062b_2974cb752e974ea192d619ea0d64120d~mv2.webp";
import Blog2 from "./../assets/img/11062b_54b0313b65a44b479c9f065e126f7c06~mv2.webp";
import Blog3 from "./../assets/img/11062b_ca88a6f079324d7c98963f33c1f2d48f~mv2.082f93412eeea96d38cb.webp";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./../assets/css/blogsection.css";
import { listActiveBlogs, listBlogCategory } from "../functions/CMS/Blog";

import moment from "moment";
import { FaAnglesRight } from "react-icons/fa6";

const BlogSection = () => {
  const [blogData, setBlogData] = useState([]);
  const [blogCategory, setBlogCategory] = useState("");

  const fetchBlogCategory = () => {
    listBlogCategory().then((res) => setBlogCategory(res.data));
  };

  useEffect(() => {
    fetchBlogCategory();
  }, []);

  const loadBlogData = () => {
    listActiveBlogs().then((res) => {
      console.log("Product Data", res.data);
      const limitedData = res.data.slice(0, 3);
      setBlogData(limitedData);
    });
  };

  useEffect(() => {
    loadBlogData();
  }, []);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  // const blogData = [
  //   {
  //     img: Blog1,
  //     date: "22-01-23",
  //     title: "title one",
  //     description:
  //       "text will be comming soon.. text will be coming soon.. text will be comming soon ..",
  //   },
  //   {
  //     img: Blog2,
  //     date: "23-01-23",
  //     title: "title one",
  //     description:
  //       "text will be comming soon.. text will be coming soon.. text will be comming soon ..",
  //   },
  //   {
  //     img: Blog3,
  //     date: "24-01-23",
  //     title: "title one",
  //     description:
  //       "text will be comming soon.. text will be coming soon.. text will be comming soon ..",
  //   },
  // ];

  return (
    <div>
      <section className="blog_section sec_ptb_120 ">
        <Container
          className="container"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <div className="section_title text-uppercase text-center">
            <h1 className="small_title wow fadeInUp" data-wow-delay=".1s">
              <FaCoffee className="fas fa-coffee" /> Blog
            </h1>
            {/* <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
              Latest Blog
            </h3> */}
          </div>
          <div lg="3">
            <div className=" wow fadeInUp" data-wow-delay=".1s">
              <ul className="ul_li_block categoryUl">
                {blogCategory.length > 0 &&
                  blogCategory.map((cd, index) => (
                    <React.Fragment key={cd._id}>
                      <li>
                        <Link
                          key={cd._id} // Add key prop here
                          to="/blog"
                          // to={{
                          //   pathname: '/blog',
                          //   state: cd._id
                          // }}
                        >
                          <span className="h5">
                            {/* <FaAnglesRight className="fal fa-angle-double-right" />{" "} */}
                            {cd.blogCategory}
                          </span>
                        </Link>
                      </li>
                      {index !== blogCategory.length - 1 && <span> | </span> }
                    </React.Fragment>
                  ))}

                {/* <li>
                          <Link
                            to="#"
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              Coffee Trending
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              Coffee History
                            </span>
                          </Link>
                        </li> */}
              </ul>
            </div>
          </div>
          <div className=" justify-content-center">
            <div lg="9">
              <Row>
                {blogData?.map((blogData) => (
                  <Col lg={4}>
                    <div className="blogGridDiv">
                      <div
                        className="blog_grid wow fadeInUp"
                        data-wow-delay=".1s"
                      >
                        <a
                          className="item_image"
                          href={`/blog-details/${blogData._id}`}
                        >
                          <img
                            // src={blogData.img}
                            src={`${process.env.REACT_APP_API_URL_COFFEE}/${blogData.blogImage}`}
                            alt="image_not_found"
                          />
                        </a>
                        <div className="item_content">
                          <p className="dateSection">
                            {" "}
                            <MdDateRange />
                            {/* {moment(new Date(blogData.createdAt)).format("DD-MM-YY")} */}
                            {moment
                              .utc(blogData.createdAt)
                              .tz("America/Los_Angeles")
                              .format("MM-DD-YY hh:mm a")}
                          </p>
                          <h3 className="item_title text-uppercase">
                            <a href="#">{blogData.title}</a>
                          </h3>
                          {/* <p>{blogData.blogThumnailDesc}</p> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: truncateString(
                                blogData.blogThumnailDesc,
                                90
                              ),
                            }}
                          ></p>
                          {/* <p
                      dangerouslySetInnerHTML={{
                        __html: truncateString(blogData.blogDesc, 120),
                      }}
                    ></p> */}
                          <a
                            className="btn_text text-uppercase"
                            href={`/blog-details/${blogData._id}`}
                          >
                            <span>Read More</span>
                            <FaAngleDoubleRight
                              style={{ marginLeft: "8px" }}
                              className="far fa-angle-double-right"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <div
              className="abtn_wrap text-lg-end text-md-end wow fadeInUp overBlogSection"
              data-wow-delay=".3s"
            >
              <Link className="btn btn_border border_black" to="/blog">
                SEE ALL BLOG
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default BlogSection;
