import axios from "axios";

export const listDeliveryOption = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/deliveryOptions`
  );
};

export const getDeliveryOption = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/deliveryOptions/${_id}`
  );
};
