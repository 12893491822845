import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import ReturnImg from "./../assets/img/whatWeDoBG.jpg";
import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";
import { listContent } from "../functions/CMS/cmsContent";
import { Puff } from "react-loader-spinner";

const ADAcompliance = () => {
    const [cmsContent, setCmsContent] = useState([]);

    const loadContentData = () => {
        listContent()
            .then((res) => {
                console.log("res in content", res.data);
                setCmsContent(res.data);
            })
            .catch((err) => {
                console.log("error in list content");
            });
    };
    useEffect(() => {
        loadContentData();
    }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const delay = 1000;
        setTimeout(() => {
            setIsLoading(false);
        }, delay);
    }, []);

    return (
        <React.Fragment>
            {isLoading ? (
                // Loader component while loading
                <div className="loader-container">
                    <Puff
                        color="#c7a17a"
                        height={50}
                        width={50}
                        timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
                    />
                </div>
            ) : (
                <>
                    <Hader Color="#000" />
                    <MobileHeader />
                    <BradeCrum />
                    <section
                        className="about_section"
                        aria-labelledby="about-heading"
                        role="region"
                        style={{
                            padding: '60px 20px',
                            backgroundColor: '#f9f9f9',
                            maxWidth: '1200px',
                            margin: '0 auto',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <h2
                            id="about-heading"
                            tabIndex="0"
                            style={{
                                fontSize: '2.5rem',
                                color: '#333',
                                marginBottom: '20px',
                                textAlign: 'center',
                                textDecoration: 'underline'
                            }}
                        >
                            Accessibility
                        </h2>
                        <div
                            className="about_body"
                            tabIndex="0"
                            style={{
                                fontSize: '1.1rem',
                                color: '#666',
                                lineHeight: '1.8',
                                textAlign: 'justify'
                            }}
                        >
                            <p style={{ marginBottom: '20px' }}>
                                Welcome to our website. We are committed to providing an accessible experience for all users. Our team works diligently to ensure that our content is inclusive and accessible to everyone, regardless of their abilities. We follow the best practices and guidelines to make our website user-friendly and accessible.
                            </p>
                            <p style={{ marginBottom: '20px' }}>
                                If you have any questions or need assistance, please do not hesitate to contact us at -{" "}
                                <a
                                    href="mailto:info@nicksroastingco.com"
                                    style={{
                                        color: '#007bff',
                                        textDecoration: 'none',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    info@nicksroastingco.com
                                </a>.
                            </p>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </React.Fragment>
    );
};

export default ADAcompliance;