import React, { useEffect, useState } from "react";
import Hader from "./../Component/Hader";
import BraderCrum from "./../Component/BradeCrum";
import About from "../Component/About";
import Footer from "./../Component/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";
import franchise_1 from "../assets/img/franchise_1.jpg";
import franchise_2 from "../assets/img/coffee_cup.jpg";
import franchise_3 from "../assets/img/coffee_beans.jpg";
import VerticalSlider from "../Component/VerticalSlider";
import { createFranchise } from "../functions/Franchise/franchise";
import { toast, ToastContainer } from "react-toastify";
import { CiPhone } from "react-icons/ci";
import franchise_s3 from "../assets/img/franchise_s2.jpg";
import { listContent } from "../functions/CMS/cmsContent";
import { Puff } from "react-loader-spinner";

const initialState = {
  contactName: "",
  contactNo: "",
  email: "",
  city: "",
  state: "",
  country: "",
  address: "",
  zipCode: "",
  franchiseCity: "",
  franchiseState: "",
  franchiseCountry: "",
  networth: "",
  liquidity: "",
  remarks: "",
  message: "",
  IsActive: true,
};

const Franchise = () => {
  const [values, setValues] = useState(initialState);
  const {
    contactName,
    contactNo,
    email,
    city,
    state,
    country,
    address,
    zipCode,
    franchiseCity,
    franchiseState,
    franchiseCountry,
    networth,
    liquidity,
    remarks,
    message,
    IsActive,
  } = values;
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadContentData();
  }, []);

  const [contentData, setContentData] = useState([]);
  const loadContentData = () => {
    listContent().then((res) => {
      console.log("content data", res.data);
      setContentData(res.data);
    });
  };
  const [isSumit, setIsSumit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errFN, setErrFN] = useState(false);
  const [errEM, setErrEM] = useState(false);
  const [errCN, setErrCN] = useState(false);
  // const [errMSG, setErrMSG] = useState(false);

  const validate = (values) => {
    const errors = {};
    const phone = /^\d{7,}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.contactName === "") {
      errors.contactName = "Contact Name is required!";
      setErrFN(true);
    }

    if (values.contactName !== "") {
      setErrFN(false);
    }

    if (values.email === "") {
      errors.email = "Email is required!";
      setErrEM(true);
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
      setErrEM(true);
    }
    if (values.email !== "") {
      setErrEM(false);
    }

    if (values.contactNo === "") {
      errors.contactNo = "Contact Number is required!";
      setErrCN(true);
    } else if (!phone.test(values.contactNo)) {
      errors.contactNo = "This is not a valid Contact Number";
      setErrCN(true);
    }

    if (values.contactNo !== "") {
      setErrCN(false);
    }

    // if (values.Message == "") {
    //   errors.Message = "Message is required!";
    //   setErrMSG(true);
    // }
    // if (values.Message !== "") {
    //   setErrMSG(false);
    // }

    return errors;
  };

  const validClassName =
    errFN && isSumit ? "form-control is-invalid" : "form-control";
  const validClassEmail =
    errEM && isSumit ? "form-control is-invalid" : "form-control";
  const validClassContact =
    errCN && isSumit ? "form-control is-invalid" : "form-control";

  // const validClassMessage =
  //   errMSG && isSumit ? "form-control is-invalid" : "form-control";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSumit(true);
    console.log("vv", values);
    let erros = validate(values);
    setFormErrors(erros);
    if (Object.keys(erros).length === 0) {
      createFranchise(values).then((res) => {
        console.log("res in createInquiry", res.data);
        // if (res.data.isOk) {
        toast.success("Message sent successfully");
        // setValues(initialState);
        setValues(initialState);
        // }
      });
    }
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <Hader Color="#000" />
          <MobileHeader />
          <BraderCrum Title="Franchise" />
          <Container>
            <section className="about_section sec_ptb_120">
              <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                {/* <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 640 512"
              className="fas fa-coffee"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z" />
            </svg>{" "} */}
                {/* OWN YOUR OWN FRANCHISE */}
                {contentData.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive && c.ContentFor === "franchise heading" && (
                        <>
                          {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </h2>
              <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                  {/* <div className="col-lg-6 col-md-12 order-last">
                    <img
                      src={franchise_2}
                      alt
                      style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    />
                  </div> */}
                  <div className="">
                    {/* <div className="about_content">
                  <div className="section_title text-uppercase">
                    <h3 className="wow fadeInUp" data-wow-delay=".2s">
                      THE MARKET PLACE
                    </h3>
                  </div>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    We know it's important, when evaluating a business
                    opportunity, to verify the market demand for your product or
                    service. The 'fast casual' category of the industry has
                    grown - in fact, it represents the majority of the
                    industry's growth, driven by consumer demand. Customers are
                    looking for better quality food choices, without sacrificing
                    convenience.
                  </p>
                  <div
                    className="section_title text-uppercase"
                    style={{ marginTop: "50px" }}
                  >
                    <h3 className="wow fadeInUp" data-wow-delay=".2s">
                      WORLD OF NICK'S ROASTING SUCCESS SYSTEM
                    </h3>
                  </div>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    We believe there are two key elements to your success: a
                    system that completely supports each stage of your
                    development, launch and building community visibility, and a
                    true commitment to warm hospitality for your guests.
                  </p>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    We offer detailed training, team resources, onsite visits
                    from our specialists, and monthly follow up to see how we
                    can best help you grow and prosper.
                  </p>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    We'll share more details on our system as we talk, but the
                    most important take away we want to leave you with is: you
                    can trust us to help you build your business. Everything we
                    do is to help increase the return on your investment. After
                    all, we only succeed if you do!
                  </p>
                </div> */}
                    <div className="about_content">
                      {contentData.map((c) => {
                        return (
                          <React.Fragment key={c._id}>
                            {c.IsActive &&
                              c.ContentFor === "franchise content" && (
                                <>
                                  {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: c.ContentUpload,
                                    }}
                                  ></p>
                                </>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container" style={{ marginTop: "50px" }}>
                <div className="row align-items-center justify-content-lg-between">
                  {/* <div className="col-lg-6 col-md-12 order-last">
                    <img src={franchise_s3} alt="" />
                  </div> */}
                  <div className="">
                    <div className="about_content">
                      {/* <div className="section_title text-uppercase">
                    <h3 className="wow fadeInUp" data-wow-delay=".2s">
                      NEXT STEPS
                    </h3>
                  </div>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    We look forward to learning more about you to see how we can
                    help you get started on your path to success with World of
                    Nick's Roasting.
                  </p>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    Please review and complete the forms below, then plan to
                    connect with us to learn more. In the meantime click through
                    our site to learn more about us.
                  </p>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{ textAlign: "justify" }}
                  >
                    Call or email us asap while there's still an opening in your
                    area.
                  </p> */}
                      {contentData.map((c) => {
                        return (
                          <React.Fragment key={c._id}>
                            {c.IsActive &&
                              c.ContentFor === "franchise steps" && (
                                <>
                                  {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: c.ContentUpload,
                                    }}
                                  ></p>
                                </>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container" style={{ marginTop: "100px" }}>
                {/* <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
              GET MORE INFORMATION NOW!
            </h3>
            <p>
              Call now to get more information. Or request more information with
              the form below:
            </p>
            <p>
              <CiPhone /> 916 294 7749
            </p>
            <p>
              <CiPhone /> 1800 297 4001
            </p> */}
                {contentData.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive && c.ContentFor === "franchise info" && (
                        <>
                          {/* <FaCoffee className="fas fa-coffee" />{" "} */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}

                <div className="row" style={{ marginTop: "50px" }}>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b mb-0 mt-3">
                      <input
                        type="text"
                        className={validClassName}
                        name="contactName"
                        placeholder="Your name:"
                        onChange={handleChange}
                        value={contactName}
                      />
                    </div>
                    {isSumit && (
                      <p className="text-danger">{formErrors.contactName}</p>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        placeholder="Address:"
                        onChange={(e) => handleChange(e)}
                        value={address}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form_item form-item-b">
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="City:"
                        onChange={(e) => handleChange(e)}
                        value={city}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form_item form-item-b">
                      <input
                        type="text"
                        // className={validClassName}
                        className="form-control"
                        name="state"
                        placeholder="State:"
                        onChange={(e) => handleChange(e)}
                        value={state}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form_item form-item-b">
                      <input
                        type="number"
                        // className={validClassName}
                        className="form-control"
                        name="zipCode"
                        placeholder="Zip:"
                        onChange={(e) => handleChange(e)}
                        value={zipCode}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b mb-0 mt-3">
                      <input
                        type="email"
                        className={validClassEmail}
                        name="email"
                        placeholder="Your Mail:"
                        onChange={(e) => handleChange(e)}
                        value={email}
                      />
                    </div>
                    {isSumit && (
                      <p className="text-danger">{formErrors.email}</p>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b mb-0 mt-3">
                      <input
                        type="number"
                        name="contactNo"
                        className={validClassContact}
                        placeholder="Contact Number:"
                        onChange={(e) => handleChange(e)}
                        value={contactNo}
                      />
                    </div>
                    {isSumit && (
                      <p className="text-danger">{formErrors.contactNo}</p>
                    )}
                  </div>
                </div>

                <p style={{ marginTop: "40px" }}>
                  What Geographic Area are you interested in opening a World of
                  Nick's Roasting Store?
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_item form-item-b">
                      <input
                        type="text"
                        className="form-control"
                        // className={validClassName}
                        name="franchiseCountry"
                        placeholder="Country:"
                        onChange={(e) => handleChange(e)}
                        value={franchiseCountry}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b">
                      <input
                        type="text"
                        // className={validClassName}
                        className="form-control"
                        name="franchiseState"
                        placeholder="State:"
                        onChange={(e) => handleChange(e)}
                        value={franchiseState}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form_item form-item-b">
                      <textarea
                        // className={validClassName}
                        className="form-control"
                        name="franchiseCity"
                        placeholder="City / Cities:"
                        onChange={(e) => handleChange(e)}
                        value={franchiseCity}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                </div>

                <p>Financial Info</p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_item form-item-b">
                      <input
                        type="number"
                        // className={validClassName}
                        className="form-control"
                        name="networth"
                        placeholder="Net Worth:"
                        onChange={(e) => handleChange(e)}
                        value={networth}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b">
                      <input
                        type="number"
                        // className={validClassName}
                        className="form-control"
                        name="liquidity"
                        placeholder="Liquidity For This Investment:"
                        onChange={(e) => handleChange(e)}
                        value={liquidity}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item form-item-b">
                      <input
                        type="text"
                        // className={validClassName}
                        className="form-control"
                        name="remarks"
                        placeholder="Additional Info:"
                        onChange={(e) => handleChange(e)}
                        value={remarks}
                      />
                      {/* {isSumit && (
                    <p className="text-danger">{formErrors.ContactName}</p>
                  )} */}
                    </div>
                  </div>
                </div>

                <div className="form_item form-item-b">
                  <textarea
                    type="textarea"
                    name="message"
                    // className={validClassMessage}
                    className="form-control"
                    placeholder="Your Message:"
                    defaultValue={""}
                    rows={4}
                    onChange={(e) => handleChange(e)}
                    value={message}
                  />
                  {/* {isSumit && <p className="text-danger">{formErrors.Message}</p>} */}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn_primary text-uppercase"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </section>
          </Container>

          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default Franchise;
