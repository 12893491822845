import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import ReferFrind from "./../assets/img/referral-win-icon.jpg";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { getUsers } from "../functions/Auth/Users";
import { FaCopy } from "react-icons/fa";
import { getLoyaltyPointMaster } from "../functions/Loyalty/LoyaltyPointMaster";
import { Puff } from "react-loader-spinner";
import { listContent } from "../functions/CMS/cmsContent";
import MobileHeader from "../Component/MobileHeader";

const ReferFriends = () => {
  const [copied, setCopied] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [point, setPoints] = useState(0);

  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);

  useEffect(() => {
    getUsers(localStorage.getItem("NicksCoUser")).then((res) => {
      setRefCode(res.data.refCode);
    });
  }, []);

  useEffect(() => {
    getLoyaltyPointMaster("660aeeada81862d11e0ec5be").then((res) => {
      setPoints(res.data.points);
    });
  }, []);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <Hader Color="#000" />
          <MobileHeader />

          <BradeCrum />
          <Container className="sec_ptb_120">
            <div className="section_title text-uppercase">
              <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 640 512"
                  className="fas fa-coffee"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z" />
                </svg>{" "}
                Refer a Friend
              </h2>
            </div>

            <div className="referFrindImgDiv">
              <img className="referFrindImg" src={ReferFrind} alt="img" />
            </div>
            <h2 className="referTitle">
              Refer your friends & get {point} points!
            </h2>
            {/* <p className="referTitle">
              Share your referral code with your friends and earn points when
              they sign up.
            </p> */}
            {cmsContent.map((c) => {
              return (
                <React.Fragment key={c._id}>
                  {c.IsActive && c.ContentFor === "refer a friend" && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: c.ContentUpload,
                        }}
                      ></p>
                    </>
                  )}
                </React.Fragment>
              );
            })}

            <div
              className="abtn_wrap text-lg-end text-md-end wow fadeInUp overBlogSection"
              data-wow-delay=".3s"
            >
              <Link
                className="btn"
                style={{ border: "1px solid black" }}
                to="#"
              >
                <span>{refCode}</span>
                <CopyToClipboard text={refCode} onCopy={handleCopy}>
                  <button
                    className="btn btn-ouline-light "
                    style={{ marginLeft: "5px" }}
                  >
                    <FaCopy />
                  </button>
                </CopyToClipboard>
              </Link>
            </div>
          </Container>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default ReferFriends;
