// import * as React from 'react';
import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../MyContext";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Filterselect from "./Filterselect";
import { listActiveParameter } from "../functions/ProductDetails/parameterMaster";
import { listActiveParameterValue } from "../functions/ProductDetails/parameterValue";
import { CategoryProductListData } from "../functions/ProductDetails/Productlist";

const Filterdrawer = ({ open, toggleDrawer }) => {
  const {
    setCategProductData,
    sortOption,
    variants,
    setVariants,
    setDrawerOpen,
  } = useContext(MyContext);

  const params = useParams();
  const CID = params.cid;

  const [parameterMaster, setParameterMaster] = useState([]);
  const [parameterValue, setParameterValue] = useState([]);

  const loadParameterMaster = () => {
    listActiveParameter().then((res) => {
      setParameterMaster(res.data);
    });
  };

  const loadParameterValue = () => {
    listActiveParameterValue().then((res) => {
      setParameterValue(res.data);
    });
  };

  const handleFilter = () => {
    CategoryProductListData(sortOption, CID, variants).then((res) => {
      setCategProductData(res.data.data);
    });
    setDrawerOpen(false);
  };

  const handleFilterReset = () => {
    setVariants([]);
    const checkboxes = document.querySelectorAll(
      'input.PrivateSwitchBase-input.css-1m9pwf3[type="checkbox"]'
    );
    console.log("checkboxes", checkboxes);
    checkboxes.forEach((checkbox) => {
      console.log("chec", checkbox.checked);
      checkbox.checked = false;
      console.log("chec new", checkbox.checked);
    });

    let varia = [];

    CategoryProductListData(sortOption, CID, varia).then((res) => {
      console.log("product in filter", res.data.data);
      setCategProductData(res.data.data);
    });
    setDrawerOpen(false);
  };

  useEffect(() => {
    loadParameterMaster();
    loadParameterValue();
  }, []);

  const mapParameterValueToMaster = (value, master) => {
    return value.map((val) => {
      const param = master.find((param) => param._id === val.parameterId);
      return {
        ...val,
        parameterId: param ? param._id : "",
        parameterName: param ? param.parameterName : "",
      };
    });
  };

  // Combine parameterMaster and parameterValue
  const combinedData = mapParameterValueToMaster(
    parameterValue,
    parameterMaster
  );

  // Group filter options by filter name
  const combinedFilterSec = combinedData.reduce((acc, curr) => {
    const existingFilter = acc.find(
      (item) => item.filterId === curr.parameterId
    );

    if (existingFilter) {
      existingFilter.filterOptionid.push(curr._id);
      existingFilter.filterOptions.push(curr.parameterValue);
    } else {
      acc.push({
        filterId: curr.parameterId,
        filterName: curr.parameterName,
        filterOptionid: [curr._id],
        filterOptions: [curr.parameterValue],
      });
    }
    return acc;
  }, []);
  const filterSec = combinedFilterSec.map((item) => ({
    filterId: item.filterId,
    filterName: item.filterName,
    filterOptionid: Array.from(new Set(item.filterOptionid)), // Remove duplicates

    filterOptions: Array.from(new Set(item.filterOptions)), // Remove duplicates
  }));

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 320 }}
      role="presentation"
      padding="10px"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <br />
      <h2 style={{ textAlign: "center" }}>Filters</h2>
      <br />
      <Divider />
      <br />
      {filterSec &&
        filterSec.map((it) => (
          <>
            <Filterselect
              filterOptionid={it.filterOptionid}
              filterId={it.filterId}
              filterName={it.filterName}
              filterOptions={it.filterOptions}
            />
            <br />
          </>
        ))}

      <Divider />
      <br />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          sx={{
            color: "black",
            backgroundColor: "white",
            borderColor: "black",
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              borderColor: "black",
            },
          }}
          onClick={handleFilterReset}
        >
          RESET
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            "&:hover": { backgroundColor: "black", color: "white" },
          }}
          onClick={handleFilter}
        >
          APPLY
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={toggleDrawer("left", false)}>
        {list("left")}
      </Drawer>
    </div>
  );
};

export default Filterdrawer;
