import React, { useContext, useEffect, useState } from "react";
import "../assets/css/mobileheader.css";
import logo from "../assets/img/logo_white_1x.png";
import { FaBars } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaHome, FaCoffee } from "react-icons/fa";
import { FaCircleDollarToSlot, FaBagShopping } from "react-icons/fa6";
import { FaSearch, FaShoppingCart, FaUser } from "react-icons/fa";
import { PiCoffeeFill } from "react-icons/pi";
import { BiCoffeeTogo } from "react-icons/bi";
import { TbPaperBag } from "react-icons/tb";
import { MdMore } from "react-icons/md";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MyContext } from "../MyContext";
import Cart from "./Cart";
import { getUserCartByUserId } from "../functions/Auth/UserCart";
import { getProductVariantsInfoInCart } from "../functions/ProductDetails/ProductDetails";
import { listActiveCategories } from "../functions/Category/category";
import { listActiveMenu } from "../functions/Menu/MenuForm";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";

const MobileHeader = () => {
  const navigate = useNavigate();
  const {
    isCanvasOpen,
    setIsCanvasOpen,
    setcartDetails,
    setSubTotal,
    setCartCount,
    cartCount,
    setProdVariantsData,
  } = useContext(MyContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userId = localStorage.getItem("NicksCoUser");
  const [menuData, setMenuData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const toggleCanvas = async () => {
    try {
      if (userId) {
        const res = await getUserCartByUserId(userId);
        const resp = res.data.data;
        const invertedData = resp.reverse();
        setcartDetails(invertedData);
        setCartCount(resp.length);
        setSubTotal(res.data.subTotal);

        const promises = resp.map((item) => {
          console.log("variantid", item.productVariantsId);
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                console.log("product variants info", res1.data[0]);
                return res1.data[0];
              }
            );
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
        console.log("is", isCanvasOpen);
        setIsCanvasOpen(!isCanvasOpen);
      }
    } catch (error) {
      console.error("Error loading user cart:", error);
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "white",
      backgroundColor: "#c7a17a",
    },
  }));

  const handleLogout = () => {
    localStorage.removeItem("NicksCoUser");
    window.location.replace("/");
  };

  useEffect(() => {
    loadCategoryData();
    loadCartData();
    loadMenuData();
    loadCompanyData();
  }, []);

  const loadMenuData = () => {
    listActiveMenu().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setMenuData(invertedData);
    });
  };

  const loadCompanyData = () => {
    listCompanynDetails().then((res) => {
      console.log("res company data", res.data);
      setCompanyData(res.data);
    });
  };

  const loadCategoryData = () => {
    listActiveCategories().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setCategoryData(invertedData);
    });
  };

  const loadCartData = () => {
    if(userId){
    getUserCartByUserId(userId).then((res) => {
      console.log("cart data", res.data);
      const resp = res.data.data;
      setCartCount(resp.length);
    });
  };
}
  const location = useLocation();

  const currentRoute = location.pathname;
  const [categoryData, setCategoryData] = useState([]);

  const handleChangeMenu = () => {
    handleClose();
  };
  return (
    <>
      <Cart />
      <div className="navbar-div">
        {/* <div className="nav-logo-div">
          <img
            // src={logo}

            alt=""
            onClick={() => navig("/")}
          />
        </div>
 */}
        <div className="nav-logo-div">
          {companyData.map((cd, index) => {
            return (
              <img
                // src={cd.Favicon}
                key={index}
                src={`${process.env.REACT_APP_API_URL_COFFEE}/${cd.Favicon}`}
                alt=""
                onClick={() => navigate("/")}
              />
            );
          })}
        </div>

        <div className="nav-icon-div">
          <IconButton
            aria-label="cart"
            onClick={toggleCanvas}
            style={{ color: "black" }}
          >
            <StyledBadge badgeContent={cartCount}>
              {/* {userId ? <ShoppingCartIcon /> : null} */}
              <FaShoppingCart
                className="fal fa-shopping-bag"
                // onClick={toggleCanvas}
                onClick={() => navigate("/shopping-cart")}
                style={{
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </StyledBadge>
          </IconButton>
          <div className="dropdown-container">
            <IoPersonCircleOutline style={{ fontSize: "25px" }} />
            {localStorage.getItem("NicksCoUser") ? (
              <ul
                className="submenu dropdown-menu"
                aria-labelledby="experience_submenu"
              >
                <li>
                  <a className="hoverLink" href="/my-address">
                    My Addresses
                  </a>
                </li>
                <li>
                  <a className="hoverLink" href="/my-wallet">
                    My Wallet
                  </a>
                </li>
                <li>
                  <a className="hoverLink" href="/my-subscription">
                    My Subscriptions
                  </a>
                </li>
                <li>
                  <a className="hoverLink" href="/my-orders">
                    My Orders
                  </a>
                </li>
                <li>
                  <a className="hoverLink" href="/my-rewards">
                    My Rewards
                  </a>
                </li>
                <li>
                  <a className="hoverLink" href="/my-account">
                    My Account
                  </a>
                </li>

                <li>
                  <a className="hoverLink" onClick={handleLogout} href="#">
                    Logout
                  </a>
                </li>
              </ul>
            ) : (
              <ul
                className="submenu dropdown-menu"
                aria-labelledby="experience_submenu"
              >
                <li>
                  <a className="hoverLink" href="/login">
                    Login
                  </a>
                </li>
                <li>
                  <a className="hoverLink" href="/register">
                    Signup
                  </a>
                </li>
              </ul>
            )}
          </div>

          <FaBars style={{ fontSize: "25px" }} onClick={handleShow} />
        </div>
      </div>
      <Offcanvas
        className="mobileHaderSection"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="main_menu_list ">
            {/* <li>
              <Link
                className="nav-link"
                to="/"
                id="home_submenu"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaHome /> Home
              </Link>
            </li> */}

            {/* {categoryData.map((cd) =>
              cd.IsActive ? (
                <li className="dropdown">
                  <Link
                    key={cd._id}
                    className="nav-link"
                    id="about_submenu"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    to={`/producListing/${cd._id}`}
                    onClick={handleChangeMenu}
                  >
                    <FaCoffee /> {cd.categoryName.toUpperCase()}
                  </Link>
                </li>
              ) : null
            )} */}

            {menuData.map((cd, index) => {
              if (cd._id === "6643314299a193bb437a2ae7") {
                return (
                  <li className="dropdown">
                    <Link
                      key={index}
                      className="nav-link"
                      to="#"
                      id="experience_submenu"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MdMore /> {cd.MenuName.toUpperCase()}
                    </Link>
                    <ul
                      className="submenu dropdown-menu"
                      aria-labelledby="experience_submenu"
                    >
                      {categoryData
                        .filter(
                          (categ) =>
                            categ.IsActive &&
                            categ.IsSubMenu &&
                            categ.MenuType === cd._id
                        )
                        .map((categ, index) => {
                          return (
                            <React.Fragment key={index}>
                              <li>
                                <a
                                  className="hoverLink"
                                  href={categ.subMenuURL}
                                >
                                  {categ.categoryName.toUpperCase()}
                                </a>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      {/* <li>
                  <Link to="/aboutUS">ABOUT US</Link>
                </li>
                <li>
                  <Link to="/loyalty">LOYALTY</Link>
                </li>
                <li>
                  <Link to="/referFriend">REFER FRIENDS</Link>
                </li>
                <li>
                  <Link to="/blog">BLOG</Link>
                </li>

                <li>
                  <Link to="/shipping">SHIPPING </Link>
                </li>
                <li>
                  <Link to="/store-policy">STORE POLICY</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
               

                <li>
                  <Link to="/contactUs">CONTACT US</Link>
                </li>
                <li>
                  <Link to="/franchise">FRANCHISE</Link>
                </li> */}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li className="dropdown">
                    <Link
                      key={index}
                      className="nav-link"
                      id="about_submenu"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      to={cd.MenuURL}
                      onClick={handleChangeMenu}
                    >
                      <FaCoffee /> {cd.MenuName.toUpperCase()}
                    </Link>
                  </li>
                );
              }
            })}

            {/* <li className="dropdown">
              <Link
                className="nav-link"
                to="/subscription"
                id="about_submenu"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaCircleDollarToSlot /> ROASTED BEANS
              </Link>
            </li> */}
            {/* <li className="dropdown">
                  <Link
                    className="nav-link"
                    to=""
                    id="about_submenu"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                  <FaCoffee  />  COFFEE

                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    className="nav-link"
                    to="#"
                    id="about_submenu"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                   <PiCoffeeFill /> TEA

                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    className="nav-link"
                    to="/subscription"
                    id="about_submenu"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                 <BiCoffeeTogo />  DRINK
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    className="nav-link"
                    to="/subscription"
                    id="about_submenu"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
               <FaBagShopping  />  SHOP
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    className="nav-link"
                    to="/subscription"
                    id="about_submenu"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
              <TbPaperBag /> FLIGHT
                  </Link>
                </li> */}
            {/* <li className="dropdown">
              <Link
                className="nav-link"
                to="#"
                id="experience_submenu"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MdMore /> MORE
              </Link>
              <ul
                className="submenu dropdown-menu"
                aria-labelledby="experience_submenu"
              >
                <li>
                  <Link to="/aboutUS">ABOUT US</Link>
                </li>
                <li>
                  <Link to="/loyalty">LOYALTY</Link>
                </li>
                <li>
                  <Link to="/referFriend">REFER FRIENDS</Link>
                </li>
                <li>
                  <Link to="/blog">BLOG</Link>
                </li>

                <li>
                  <Link to="/shipping">SHIPPING </Link>
                </li>
                <li>
                  <Link to="/store-policy">STORE POLICY</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
               

                <li>
                  <Link to="/contactUs">CONTACT US</Link>
                </li>
                <li>
                  <Link to="/franchise">FRANCHISE</Link>
                </li>
              
              </ul>
            </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MobileHeader;
