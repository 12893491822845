import React from "react";
import { IoIosHome } from "react-icons/io";
import Icon1 from "./../assets/img/icon_01.png";
import { Link } from "react-router-dom";

const BannerTwo = ({ Title, subTitle }) => {
  return (
    <div>
      <section className="breadcrumb_section text-uppercase bannerBackground">
        <div className="container">
          <h1
            className="page_title text-white wow fadeInUp"
            data-wow-delay=".1s"
          >
            {Title}
          </h1>
          <ul
            className="breadcrumb_nav ul_li wow fadeInUp"
            data-wow-delay=".2s"
          >
            <li>
              <Link to="/">
                <IoIosHome className="fas fa-home" /> Home
              </Link>  / {subTitle}
            </li> 
            {/* <li>{subTitle}</li>  */}
          </ul>
        </div>
        <div className="breadcrumb_icon_wrap">
          <div className="container">
            <div className="breadcrumb_icon wow fadeInUp" data-wow-delay=".3s">
              <img src={Icon1} alt="icon_not_found" />
              <span className="bg_shape" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BannerTwo;
