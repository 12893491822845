import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { FaChevronLeft } from "react-icons/fa";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../assets/css/Cart.css";
import ProductImg from "./../assets/img/productListingImg.png";
import { FaShoppingCart } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, json } from "react-router-dom";
import { Card } from "react-bootstrap";
import Counter from "./Counter";
import { MyContext } from "../MyContext";
import { FaArrowLeft } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

import {
  getUserCartByUserId,
  RemoveFromCart,
  increaseQuantityOne,
  decreaseQuantityOne,
  increaseQuantityGuestCartData,
  decreaseQuantityGuestCartData,
  RemoveFromGuestCart,
} from "../functions/Auth/UserCart";

const Cart = () => {
  const {
    isCanvasOpen,
    setIsCanvasOpen,
    cartDetails,
    setcartDetails,
    setSubTotal,
    subTotal,
    setCartCount,
    cartCount,
    prodVariantsData,
    setProdVariantsData,
  } = useContext(MyContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  };
  const navigate = useNavigate();

  const handleViewCart = () => {
    setIsCanvasOpen(!isCanvasOpen);
    navigate("/shopping-cart");
  };

  const userId = localStorage.getItem("NicksCoUser");

  const handleRemoveItem = (id, productVariant, subsId) => {
    if (userId) {
      RemoveFromCart(userId, id, productVariant, subsId).then((res) => {
        console.log("remove item", res.data);
        // setcartDetails(res.data);
        getUserCartByUserId(userId).then((res1) => {
          console.log("cart data", res1.data);
          const resp = res1.data.data;
          const invertedData = resp.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);
          setSubTotal(res1.data.subTotal);
          setCartCount(resp.length);
        });
      });
    } else {
      RemoveFromGuestCart(
        id,
        productVariant,
        subsId,
        localStorage.getItem("guestCartData")
          ? JSON.parse(localStorage.getItem("guestCartData"))
          : []
      ).then((res) => {
        console.log("remove item", res.data);
        if (res.data.isOk) {
          const dataString = JSON.stringify(res.data.guestCartData);
          const guestCartData = localStorage.setItem(
            "guestCartData",
            dataString
          );
          const cartArray = res.data.guestCartData;
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
          });
          setSubTotal(subAmt);
        }
      });
    }
  };

  const handleAddItem = (productid, subsId, productvariantid) => {
    console.log("add item", productid, productvariantid, subsId);

    if (userId) {
      increaseQuantityOne(userId, productid, productvariantid, subsId).then(
        (res) => {
          console.log("updated cart", res.data);
          // loadUserCart();
          getUserCartByUserId(userId).then((res1) => {
            console.log("cart data", res1.data);
            const resp = res1.data.data;
            const invertedData = resp.reverse(); // Reverse the order of the array
            setcartDetails(invertedData);
            setSubTotal(res1.data.subTotal);
            setCartCount(resp.length);
          });
        }
      );
    } else {
      console.log("outs");
      increaseQuantityGuestCartData(
        productid,
        productvariantid,
        subsId,
        localStorage.getItem("guestCartData")
          ? JSON.parse(localStorage.getItem("guestCartData"))
          : []
      ).then((res) => {
        console.log("updated cart", res.data);
        if (res.data.isOk) {
          const dataString = JSON.stringify(res.data.guestCartData);
          const guestCartData = localStorage.setItem(
            "guestCartData",
            dataString
          );
          const cartArray = res.data.guestCartData;
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
          });
          setSubTotal(subAmt);
        }
      });
    }
  };

  const handleSubtractItem = (productid, productvariantid, subsId) => {
    console.log("subtract item", productid, productvariantid, subsId);

    if (userId) {
      decreaseQuantityOne(userId, productid, productvariantid, subsId).then(
        (res) => {
          console.log("updated cart", res.data);
          // loadUserCart();
          getUserCartByUserId(userId).then((res1) => {
            console.log("cart data", res1.data);
            const resp = res1.data.data;
            const invertedData = resp.reverse(); // Reverse the order of the array
            setcartDetails(invertedData);
            setSubTotal(res1.data.subTotal);
            setCartCount(resp.length);
          });
        }
      );
    } else {
      console.log("outs dec");
      decreaseQuantityGuestCartData(
        productid,
        productvariantid,
        subsId,
        localStorage.getItem("guestCartData")
          ? JSON.parse(localStorage.getItem("guestCartData"))
          : []
      ).then((res) => {
        console.log("updated cart", res.data);
        if (res.data.isOk) {
          const dataString = JSON.stringify(res.data.guestCartData);
          const guestCartData = localStorage.setItem(
            "guestCartData",
            dataString
          );
          const cartArray = res.data.guestCartData;
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
          });
          setSubTotal(subAmt);
        }
      });
    }
  };

  const [productData, setProductData] = useState([]);
  useEffect(() => {
    console.log("cart 2222", cartDetails);
  }, []);

  return (
    <>
      {/* <FaShoppingCart onClick={handleShow} className="searchIcon" />

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link style={{ color: "#000" }} to="#">
              {" "}
              <FaChevronLeft />
              Continue shopping
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="cartTitleDiv">
            You have <span style={{ fontWeight: "700" }}>1</span> items in your
            cart
          </div>
          <div className="scrolable">
            <Card>
              <div className="cartDiv">
                <div>
                  <img src={ProductImg} alt="img" />
                </div>
                <div>
                  <Link className="productListingTitle" to="#">
                    Title
                  </Link>
                </div>
                <div>
                  <Counter />
                </div>
                <div>$9.99</div>
                <div>
                  <RiDeleteBinLine />
                </div>
              </div>
            </Card>
          </div>
          <div className="totalDiv">
            <div>Sub Total</div>
            <div>$19.98</div>
          </div>
          <Link className="shopNowBtn" to="#">
            View Cart
          </Link>
        </Offcanvas.Body>
      </Offcanvas> */}
      <div>
        <Offcanvas
          show={isCanvasOpen}
          backdrop={true}
          fade={true}
          onHide={toggleCanvas}
          style={{
            "@media (min-width: 770px)": {
              width: "30%",
            },
            "@media (max-width: 770px)": {
              width: "50%",
            },
            "@media (max-width: 425px)": {
              width: "90%",
            },
          }}
        >
          <offcanvasbody>
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col">
                <div className="card">
                  <div className="card-body p-4">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="mb-3">
                          <a
                            href="#"
                            className="text-body"
                            onClick={toggleCanvas}
                          >
                            <FaArrowLeft className=" me-2" />
                            Continue shopping
                          </a>
                        </h5>
                        <hr />

                        {cartDetails.length > 0 ? (
                          <>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <div>
                                <p className="mb-0">
                                  You have <strong>{cartDetails.length}</strong>{" "}
                                  items in your cart
                                </p>
                              </div>
                            </div>

                            <div className="scrolable">
                              {cartDetails?.map((cart) => (
                                <div className="card mb-2" key={cart._id}>
                                  <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex flex-row align-items-center">
                                        <div>
                                          <img
                                            // src={cart.productImage}
                                            src={`${process.env.REACT_APP_API_URL_COFFEE}/${cart.productImage}`}
                                            className="rounded-3 cart-image"
                                            alt="Shopping item"
                                            height="150px"
                                            max-width="300px"
                                          />
                                        </div>
                                        <div className="ms-3">
                                          <h6>{cart.productName}</h6>
                                          {cart.subsId && (
                                            <p>
                                              <b>
                                                <i>Subscribed:</i>
                                              </b>{" "}
                                              <i> {cart.subsName} </i>
                                            </p>
                                          )}
                                          {prodVariantsData.length > 0 &&
                                            prodVariantsData.find(
                                              (variant) =>
                                                variant._id ===
                                                cart.productVariantsId
                                            ) && (
                                              <p>
                                                {prodVariantsData
                                                  .find(
                                                    (variant) =>
                                                      variant._id ===
                                                      cart.productVariantsId
                                                  )
                                                  .parameters.map(
                                                    (param, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <b>
                                                          <i>
                                                            {
                                                              param.parameterName
                                                            }
                                                          </i>
                                                        </b>
                                                        :{" "}
                                                        <i>
                                                          {param.parameterValue}
                                                        </i>
                                                        <br />
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </p>
                                            )}
                                          <div className="d-flex flex-row align-items-center">
                                            <div
                                              className="quantity-box d-flex align-items-center"
                                              style={{
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                padding: " 5px",
                                              }}
                                            >
                                              <button
                                                className="quantity-button"
                                                onClick={() => {
                                                  handleSubtractItem(
                                                    cart.productId,
                                                    cart.productVariantsId,
                                                    cart.subsId
                                                  );
                                                }}
                                              >
                                                –
                                              </button>
                                              <div
                                                className="mx-2"
                                                style={{
                                                  display: "inline-block",
                                                  margin: "0 5px",
                                                }}
                                              >
                                                {cart.quantity}
                                              </div>
                                              <button
                                                className="quantity-button"
                                                onClick={() => {
                                                  handleAddItem(
                                                    cart.productId,
                                                    cart.subsId,
                                                    cart.productVariantsId
                                                  );
                                                }}
                                              >
                                                +
                                              </button>
                                            </div>
                                            <div className="mx-2">
                                              <h6
                                                className="mb-0"
                                                style={{ margin: "0 5px" }}
                                              >
                                                $
                                                {cart?.amount
                                                  ? cart.amount.toFixed(2)
                                                  : "0.00"}
                                              </h6>
                                            </div>
                                            <a
                                              onClick={() => {
                                                handleRemoveItem(
                                                  cart.productId,
                                                  cart.productVariantsId,
                                                  cart.subsId
                                                );
                                              }}
                                              href="#!"
                                              style={{ color: "#cecece" }}
                                            >
                                              <FaTrashAlt />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div>
                              <hr className="my-4" />

                              <div className="d-flex justify-content-between">
                                <p className="mb-2">Subtotal</p>
                                <p className="mb-2">${subTotal.toFixed(2)}</p>
                              </div>
                              {/* <div className="d-flex justify-content-between">
                              <p className="mb-2">Shipping</p>
                              <p className="mb-2">$20.00</p>
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                              <p className="mb-2">Total(Incl. taxes)</p>
                              <p className="mb-2">$4818.00</p>
                            </div> */}
                              <button
                                type="button"
                                className="btn btn_primary btn-outline cartsidebar-btn"
                                onClick={handleViewCart}
                              >
                                <div className="d-flex justify-content-between">
                                  {/* <Link
                                    className="ankers-1"
                                    // to="/shoping-cart"
                                    // onClick={handleViewCart}
                                  > */}
                                  <span
                                    className="ms-2"
                                    // style={{ marginTop: "20px" }}
                                  >
                                    View Cart <FaLongArrowAltRight />
                                  </span>
                                  {/* </Link> */}
                                </div>
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="container">
                            <div className="row justify-content-center">
                              <div className="col-12 col-md-6 text-center">
                                <div className="alert alert-info" role="alert">
                                  Your cart is empty
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </offcanvasbody>
        </Offcanvas>
      </div>
    </>
  );
};

export default Cart;
