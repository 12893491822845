import React from "react";
import "../assets/css/mysubscription.css";
import { Button, IconButton, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { FaClosedCaptioning, FaEye } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";

import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { cancelOrderSubscription } from "../functions/Orders/Order";

const Subscriptioncard = ({
  prodVariantsData,
  orderDetail,
  subscriptionProduct,
}) => {
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(false);
  const [subscancel, setSubsCancel] = React.useState(false);
  const [subscancelId, setSubsCancelId] = React.useState();

  const theme = useTheme();

  const toggleCancelSubs = (id) => {
    setSubsCancel(true);
    setSubsCancelId(id);
  };

  const cancelSubscription = () => {
    cancelOrderSubscription(subscancelId)
      .then((res) => {
        // console.log("res in data", res);
        setSubsCancel(false);
        setSubsCancelId();
        setOpen(false);
        document.getElementsByClassName("cancelSubsBtn").disabled = true;
        window.location.reload();
      })
      .catch((err) => {
        console.log("err in subscription", err);
      });
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FormatDates = (formatdate) => {
    const ftdate = new Date(formatdate);
    // console.log("ff", ftdate);
    const fttime = new Date(ftdate.getTime() - 5.5 * 60 * 60 * 1000);
    return moment(new Date(fttime)).format("MM-DD-YY");
  };

  const matchingSubsDetail = subscriptionProduct?.subsDetails.find(
    (subsProduct) => subsProduct._id === orderDetail.subsId
  );

  const dialogbox = () => {
    return (
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
        // style={{width: "100%"}}
        // onTouchCancel={handleClose}
        // onMouseEnter={handleClose}
      >
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={count}
          autoHideDuration={6000}
          onClose={() => setCount(false)}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        />
        <div className="closeDiv bg-light" style={{ padding: "2%" }}>
          <IoMdClose onClick={handleClose} />
        </div>
        <div className="dia-outer-div">
          <div className="dia-img-div">
            <img
              src={`${process.env.REACT_APP_API_URL_COFFEE}/${orderDetail?.productDetails?.productImage}`}
              // src={img_01}
              width="200px"
              height="250px"
              alt="image"
            />
          </div>
          <div className="dia-p-div">
            <p style={{ fontSize: "20px" }}>
              {/* <b>Mexican</b> */}
              <b>{orderDetail?.productDetails?.productName}</b>
            </p>

            <p>{matchingSubsDetail.title}</p>

            <p>Quantity : {orderDetail?.quantity}</p>
            <p>
              <b>$ {orderDetail?.amount.toFixed(2)}</b>
            </p>
            <p>
              {subscriptionProduct?.endDate ? (
                <React.Fragment>
                  <b> Cancellation Date:</b>
                  {moment
                    .utc(subscriptionProduct.endDate)
                    .tz("America/Los_Angeles")
                    .format("MM-DD-YY hh:mm a")}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <b> Renewal Date:</b>
                  {subscriptionProduct?.params &&  
                  subscriptionProduct.params.length > 0 &&
                  subscriptionProduct.params[
                    subscriptionProduct.params.length - 1
                  ].nextOrderDate !== null
                    ? FormatDates(
                        subscriptionProduct.params[
                          subscriptionProduct.params.length - 1
                        ].nextOrderDate
                      )
                    : null}
                </React.Fragment>
              )}

              {/* {FormatDates(orderDetail?.subscriptionStartDate)} */}
            </p>
            {/* <p>Grind Type : Fine</p>
            <p>Size : </p>
            <p>Drink : Hot</p>
            <p>Energy Type : Redbull</p> */}
            {prodVariantsData.length > 0 &&
              prodVariantsData?.find(
                (variant) => variant._id === orderDetail.productVariantsId
              ) && (
                <p>
                  {prodVariantsData
                    .find(
                      (variant) => variant._id === orderDetail.productVariantsId
                    )
                    .parameters.map((param, index) => (
                      <React.Fragment key={index}>
                        <b>
                          <i>{param.parameterName}</i>
                        </b>
                        : <i>{param.parameterValue}</i>
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              )}
          </div>
        </div>
        {subscriptionProduct?.endDate ? (
          ""
        ) : (
          <DialogActions
            sx={{ display: "flex", justifyContent: "end", margin: "2%" }}
          >
            {subscancel ? (
              <div>
                <p className="text-muted mx-4 m-2">
                  Are you Sure You want to end this subscription ?
                </p>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-dark"
                    id="add-btn"
                    onClick={cancelSubscription}
                  >
                    End Subscription
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={(e) => {
                      e.preventDefault();
                      setSubsCancel(false);
                      setSubsCancelId();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <Button
                  onClick={() => toggleCancelSubs(orderDetail._id)}
                  autoFocus
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "gray", color: "black" },
                  }}
                  className="cancelSubsBtn"
                >
                  Cancel Subscription
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  sx={{
                    backgroundColor: "#e8e8e8",
                    color: "black",
                    "&:hover": { backgroundColor: "white", color: "black" },
                  }}
                >
                  Auto Pay
                </Button>{" "}
              </React.Fragment>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const cardStyle = {
    width: "20rem",
    height: "14rem",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  };

  const hoverStyle = {
    transform: "translateY(-10px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  return (
    <React.Fragment>
      <div
        className="subs-outer-div mt-2"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={isHovered ? { ...cardStyle, ...hoverStyle } : cardStyle}
      >
        {dialogbox()}
        {/* <div className='subs-inner-div'>
            <div className='subs-img-div'>
                <img src={img_01} alt="" />
            </div>
            <div className='subs-p-div'>
                <div>
                <h4>PRODUCT NAME</h4>
                <p>Date : 11/11/1111</p>
                </div>
                <Button variant='contained' sx={{backgroundColor:"#c7a17a",'&:hover':{backgroundColor:"black"}}} onClick={handleClickOpen}>View</Button>
            </div>
        </div> */}
        <div className="subs-inner-div" key={orderDetail._id}>
          {/* <div className="subs-img-div">
          <img
            width="250px"
            height="320px"
            src={`${process.env.REACT_APP_API_URL_COFFEE}/${orderDetail?.productDetails?.productImage}`}
            alt=""
          />
        </div> */}
          <div className="">
            <div>
              <h4>{orderDetail?.productDetails?.productName}</h4>
              <p style={{ fontSize: "14px" }}>
                Date : {FormatDates(subscriptionProduct?.startDate)}
              </p>
              {matchingSubsDetail ? (
                <p>
                  <b>Subscribed:</b> {matchingSubsDetail.title}
                </p>
              ) : null}
              {subscriptionProduct?.endDate ? (
                <h6 className="text-danger">
                  Cancelled On:{" "}
                  {moment
                    .utc(subscriptionProduct?.endDate)
                    .tz("America/Los_Angeles")
                    .format("MM-DD-YY hh:mm a")}{" "}
                </h6>
              ) : null}
            </div>
            <div className="maindivViewBtn">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  fontSize: "12px",
                  padding: "7px",
                  "&:hover": { backgroundColor: "white", color: "black" },
                  gap: "10px",
                }}
                onClick={handleClickOpen}
              >
                View <FaEye />
              </Button>

              <Dropdown>
                {subscriptionProduct?.params[
                  subscriptionProduct?.params.length - 1
                ].nextOrderDate != null ? (
                  <>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "12px",
                        padding: "7px",
                        border: "1px solid black ",
                        "&:hover": { backgroundColor: "black" },
                        gap: "10px",
                      }}
                      id="dropdown-basic"
                    >
                      Renewal Date:{" "}
                      {FormatDates(
                        subscriptionProduct?.params[
                          subscriptionProduct?.params.length - 1
                        ].nextOrderDate
                      )}
                    </Dropdown.Toggle>
                  </>
                ) : null}

                {subscriptionProduct?.params.length > 0 ? (
                  <Dropdown.Menu>
                    {subscriptionProduct?.params.map((param, index) => (
                      <Dropdown.Item key={index} href="#/action-1">
                        <MdDateRange />
                        {param.nextOrderDate != null
                          ? FormatDates(param.nextOrderDate)
                          : null}
                        {/* {FormatDates(param.nextOrderDate)} */}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                ) : null}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscriptioncard;
