import axios from "axios";

export const listActiveParameterValue = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/listActive/parameterValue`
  );
};

export const getParameterValue = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/parameterValue/${_id}`
  );
};
