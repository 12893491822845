import React, { useState, useEffect } from "react";
import { FaRegUser, FaRegUserCircle } from "react-icons/fa";
import { Card, CardBody, Col, Input, Label } from "reactstrap";

import UserImg from "./../assets/img/userImg.png";
import { Link, useLocation } from "react-router-dom";
import { getUsers, updateUsers } from "../functions/Auth/Users";
import { Badge, Avatar } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { AiFillCamera } from "react-icons/ai";
const ProfileSideBar = () => {
  const location = useLocation();
  const user = localStorage.getItem("NicksCoUser");
  const [userData, setUserData] = useState([]);
  const [ImageUpdate, setImageUpdate] = useState(false);
  const loadUser = () => {
    if(user){
    getUsers(user).then((res) => {
      const response = res.data;
      console.log("user image", response.userImage);
      setUserImage(response.userImage);
      if (response.userImage === "" || response.userImage === null) {
        console.log("dd");
        setImageUpdate(true);
      }

      setUserData([res.data]);
    });
  }
  };

  const [photoAdd, setPhotoAdd] = useState();
  const [checkImagePhoto, setCheckImagePhoto] = useState(false);
  const [userImage, setUserImage] = useState("");

  const PhotoUpload = (e) => {
    if (e.target.files.length > 0) {
      let imageurl = URL.createObjectURL(e.target.files[0]);

      setPhotoAdd(imageurl);
      setUserImage(e.target.files[0]);
      setCheckImagePhoto(true);
      if (e.target.files[0]) {
        const formData = new FormData();
        formData.append("myFile", e.target.files[0]);
        updateUsers(user, formData)
          .then((res) => {
            console.log("image", res);
            setImageUpdate(false);
            toast.success("Image Updated successfully");
          })
          .catch((err) => {
            console.log("error in update user", err);
          });
      }
    }
  };

  useEffect(() => {
    loadUser();
  }, []);
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div>
      <ToastContainer />
      <Card className="myProfileCard">
        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
          <Avatar
            // src={UserImg}
            src={
              checkImagePhoto
                ? photoAdd
                : ImageUpdate
                ? UserImg
                : `${process.env.REACT_APP_API_URL_COFFEE}/${userImage}`
            }
            size={100}
            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
            alt="user-profile"
            // onError={handleImageError}
          />

          <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
            <Input
              id={"profile-img-file-input"}
              type="file"
              accept=".jpg, .png, .jpeg"
              className="profile-img-file-input visually-hidden" // remove id from here
              name="userImage"
              onChange={PhotoUpload}
            />
            <Label
              htmlFor={"profile-img-file-input"}
              className="profile-photo-edit avatar-sm"
            >
              <span className="avatar-title rounded-circle bg-light text-body">
                {/* <i
                  className="ri-camera-fill"
                  style={{
                    display: "block",
                    position: "absolute",
                    top: "80px",
                    left: "80px",
                  }}
                ></i> */}
                <AiFillCamera
                  style={{
                    display: "block",
                    position: "absolute",
                    top: "80px",
                    left: "75px",
                    fontSize: "30px",
                    "&:hover": { cursor: "pointer" },
                    padding: "4px",
                    backgroundColor: "#ccc",
                    borderRadius: "13px",
                    color: "#fff",
                  }}
                />
              </span>
            </Label>
          </div>
        </div>
        {/* <img className="usetIcon" src={UserImg} alt="icon" /> */}
        {userData.map((user) => (
          <>
            <h6>
              {user.firstName} {user.lastName}
            </h6>
            {/* <div className="followersDiv">
              <div>Followers {user.followers.length}</div>
              <div>Following {user.following.length}</div>
            </div> */}
          </>
        ))}
      </Card>
      <Card className="myProfileCard ">
        <div className="cardDivProfile">
          <Link
            className={
              isActive("/my-address")
                ? "activeLink profileCardLink"
                : "profileCardLink"
            }
            to="/my-address"
          >
            My Addresses
          </Link>
        </div>

        <div className="cardDivProfile">
          <Link
            className={
              isActive("/my-wallet")
                ? "activeLink profileCardLink"
                : "profileCardLink"
            }
            to="/my-wallet"
          >
            My Wallet
          </Link>
        </div>
        <div className="cardDivProfile">
          <Link
            className={
              isActive("/my-subscription")
                ? "activeLink profileCardLink"
                : "profileCardLink"
            }
            to="/my-subscription"
          >
            My Subscriptions
          </Link>
        </div>
        <div className="cardDivProfile">
          <Link
            className={
              isActive("/my-orders")
                ? "activeLink profileCardLink"
                : "profileCardLink"
            }
            to="/my-orders"
          >
            My Orders
          </Link>
        </div>
        <div className="cardDivProfile">
          <Link
            className={
              isActive("/my-orders")
                ? "activeLink profileCardLink"
                : "profileCardLink"
            }
            to="/my-rewards"
          >
            My Rewards
          </Link>
        </div>
        <div className="cardDivProfile">
          <Link
            className={
              isActive("/my-account")
                ? "activeLink profileCardLink"
                : "profileCardLink"
            }
            to="/my-account"
          >
            My Account
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default ProfileSideBar;
