// MyContext.js
import React, { createContext, useState, useEffect } from "react";
import { getProductVariantsInfo, getProductVariantsInfoInCart } from "./functions/ProductDetails/ProductDetails";

const MyContext = createContext({
  data: {},
  setData: () => {},
  cartDetails: {},
  setcartDetails: () => {},

  coffeeTrendingCheck: {},
  setCoffeeTrendingCheck: () => {},

  coffeeHistoryCheck: {},
  setCoffeeHistoryCheck: () => {},

  drawerOpen: false,
  setDrawerOpen: () => {},
  expanded: false,
  setExpanded: () => {},
  userIdData: {},
  setUserIdData: () => {},
  totalProductAmount: {},
  setTotalProductAmount: () => {},
  pageName: {},
  setpageName: () => {},
  prodVariantsData: {},
  setProdVariantsData: () => {},
  shippingCharge: {},
  setShippingCharge: () => {},
  cartCount: {},
  setCartCount: () => {},
  sortOption: {},
  setSortOption: () => {},
  variants: [],
  setVariants: () => [],
  subTotal: {},
  setSubTotal: () => {},
  count: {},
  setCount: () => {},
  CategProductData: [],
  setCategProductData: () => [],
  searchResults: [],
  setSearchResults: () => [],
  isCanvasOpen: false,
  setIsCanvasOpen: () => {},
});

const MyContextProvider = ({ children }) => {
  const [data, setData] = useState({
    isQuickViewModalOpen: false,
    quickViewModalData: {},
  });

  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  // PRODUCT LIST PAGE
  const [CategProductData, setCategProductData] = useState([]);
  const [pageName, setPageName] = useState(1);
  const [userIdData, setUserIdData] = useState(
    localStorage.getItem("NicksCoUser")
  );

  const [sortOption, setSortOption] = useState(1);
  const [expanded, setExpanded] = useState(false);

  const [variants, setVariants] = useState([]);
  const [count, setCount] = useState(1);
  const [cartDetails, setcartDetails] = useState([]);
  const [prodVariantsData, setProdVariantsData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [coffeeTrendingCheck, setCoffeeTrendingCheck] = useState(false);

  const [coffeeHistoryCheck, setCoffeeHistoryCheck] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("NicksCoUser")) {
      console.log("user exist");
    } else {
      const guestCartData = localStorage.getItem("guestCartData");
      if (guestCartData) {
        const cartArray = JSON.parse(guestCartData);
        setCartCount(cartArray.length);
        console.log("Cart count:", cartArray.length);

        const promises = cartArray.map((item) => {
          console.log("variantid", item.productVariantsId);
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                console.log("product variants info", res1.data[0]);
                return res1.data[0]; // Return the object directly
              }
            );
        });
  
        const variantDataArray = Promise.all(promises.filter(Boolean));
  
        setProdVariantsData(variantDataArray);
      } else {
        console.log("No guest cart data found.");
      }
    }
  }, []);

  return (
    <MyContext.Provider
      value={{
        data,
        setData,
        count,
        expanded,
        setExpanded,
        setCount,
        coffeeHistoryCheck,
        setCoffeeHistoryCheck,
        coffeeTrendingCheck,
        setCoffeeTrendingCheck,
        drawerOpen,
        setDrawerOpen,
        cartCount,
        setCartCount,
        userIdData,
        setUserIdData,
        prodVariantsData,
        setPageName,
        pageName,
        setProdVariantsData,
        totalProductAmount,
        setTotalProductAmount,
        shippingCharge,
        setShippingCharge,
        subTotal,
        setSubTotal,
        cartDetails,
        setcartDetails,
        variants,
        setVariants,
        sortOption,
        setSortOption,
        CategProductData,
        setCategProductData,
        searchResults,
        setSearchResults,
        isCanvasOpen,
        setIsCanvasOpen,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
