import axios from "axios";

export const createPointTransaction = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/pointsTransaction`,
    values
  );
};

export const listPointTransaction = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/pointsTransaction`
  );
};

export const getPointTransactionByUserId = async (_id, pointMaster) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/pointsTransactionByUser/${_id}`,
    { pointMaster }
  );
};

export const getUserPointTransaction = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/userPointsTransaction/${id}`
  );
};
