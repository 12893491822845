import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CiCoffeeBean } from "react-icons/ci";
import {
  GiCoffeeBeans,
  GiCoffeeCup,
  GiManualMeatGrinder,
} from "react-icons/gi";

import { Link } from "react-router-dom";
import { FaAngleDoubleRight, FaCoffee } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { listContent } from "../functions/CMS/cmsContent";

const HowItWokr = () => {
  const [cmsContent, setCmsContent] = useState([]);

  const loadContentData = () => {
    listContent()
      .then((res) => {
        console.log("res in content", res.data);
        setCmsContent(res.data);
      })
      .catch((err) => {
        console.log("error in list content");
      });
  };
  useEffect(() => {
    loadContentData();
  }, []);
  return (
    <React.Fragment>
      <section className="feature_primary_section">
        <Container>
          <div className="mainDivHowItWork">
            <div className="section_title text-uppercase ">
              <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                <FaCoffee className="fas fa-coffee" />
                SUBSCRIPTION
              </h2>
            </div>
            <Row>
              {cmsContent.map((c) => {
                return (
                  <React.Fragment key={c._id}>
                    {c.IsActive &&
                      c.ContentFor === "subscription step heading" && (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                  </React.Fragment>
                );
              })}
            </Row>
            <Row>
              <Col lg="3" md="6" className=" col-6">
                {cmsContent.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive && c.ContentFor === "subscription step 1" && (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* <div
                  className="feature_primary wow fadeInUp"
                  data-wow-delay=".1s"
                >
                  <div className="item_icon">
                    <h6>STEP 1 </h6>
                  </div>
                  <div>
                    <GiCoffeeBeans className="howItWorkIcon" />
                  </div>

                  <h3 className="item_title text-uppercase">
                    Choose Your Coffee{" "}
                  </h3>
                </div> */}
              </Col>
              <Col lg="3" md="6" className="col-6">
                {cmsContent.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive && c.ContentFor === "subscription step 2" && (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* <div
                  className="feature_primary wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="item_icon">
                    <h6>STEP 2 </h6>
                  </div>
                  <div>
                    <GiManualMeatGrinder className="howItWorkIcon" />
                  </div>
                  <h3 className="item_title text-uppercase">
                    Select your Grind Type
                  </h3>
                </div> */}
              </Col>
              <Col lg="3" md="6" className=" col-6">
                {cmsContent.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive && c.ContentFor === "subscription step 3" && (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* <div
                  className="feature_primary wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="item_icon">
                    <h6>STEP 3 </h6>
                  </div>
                  <div>
                    <TbTruckDelivery className="howItWorkIcon" />
                  </div>
                  <h3 className="item_title text-uppercase">
                    Select Your Delivery Frequency
                  </h3>
                </div> */}
              </Col>
              <Col lg="3" md="6" className="col-6">
                {cmsContent.map((c) => {
                  return (
                    <React.Fragment key={c._id}>
                      {c.IsActive && c.ContentFor === "subscription step 4" && (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: c.ContentUpload,
                            }}
                          ></p>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* <div
                  className="feature_primary wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <div className="item_icon">
                    <h6>STEP 4 </h6>
                  </div>
                  <div>
                    <GiCoffeeCup className="howItWorkIcon" />
                  </div>
                  <h3 className="item_title text-uppercase">
                    Enjoy Fresh Coffee Delivered to Your Door
                  </h3>
                </div> */}
              </Col>
              {/* <div className="subscribeDiv">
                <div
                  className="abtn_wrap text-lg-end text-md-end wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <Link
                    className="btn btn_border border_black"
                    to="/subscription"
                  >
                    Subscribe Now
                  </Link>
                </div>
              </div> */}
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HowItWokr;
