import React, { useEffect, useState } from "react";
import Hader from "../../Component/Hader";
// import MobileHader from "../Component/MobileHader";
import BannerTwo from "../../Component/BannerTwo";
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Footer from "../../Component/Footer";
import ProfileSideBar from "../../Component/profileSideBar";
import Cart from "../../Component/Cart";
import {
  getOrdersByUserId,
  downloadInvoicePDF,
  removeOrderByUser,
  updateOrderByUser,
  getOrderToUser,
} from "../../functions/Orders/Order";
import BradeCrum from "../../Component/BradeCrum";
import { Link } from "react-router-dom";
import { getUsers } from "../../functions/Auth/Users";
import moment from "moment";
import { Puff, TailSpin } from "react-loader-spinner";
import { FaTrashAlt } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import { listDeliveryOption } from "../../functions/DeliveryOptions/DeliveryOption";
import { toast } from "react-toastify";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import MobileHeader from "../../Component/MobileHeader";

const MyOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [buttonLoadingStates, setButtonLoadingStates] = useState(
    Array(orderData.length).fill(false)
  );
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [orderCancelData, setOrderCancelData] = useState([]);

  const [deliveryOptionsData, setDeliveryOptionsData] = useState([]);

  const loadDeliveryOptions = () => {
    listDeliveryOption().then((res) => {
      console.log("all delivery add", res.data);
      setDeliveryOptionsData(res.data);
    });
  };

  const userId = localStorage.getItem("NicksCoUser");

  const downloadPDF = (orderId, randomOrderId, index) => {
    const updatedButtonLoadingStates = [...buttonLoadingStates];
    updatedButtonLoadingStates[index] = true;
    setButtonLoadingStates(updatedButtonLoadingStates);

    console.log("orderId in downloadPDF", orderId);
    downloadInvoicePDF(orderId)
      .then((response) => {
        // Extract the data from the response Blob
        const reader = new FileReader();
        reader.onload = () => {
          const data = reader.result;
          const blob = new Blob([data], {
            type: "application/pdf",
          });
          console.log("Blob size:", blob.size);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `Invoice_${randomOrderId}.pdf`;
          a.click();

          const updatedButtonLoadingStates = [...buttonLoadingStates];
          updatedButtonLoadingStates[index] = false;
          setButtonLoadingStates(updatedButtonLoadingStates);
        };

        reader.readAsArrayBuffer(response.data);
      })
      .catch((error) => {
        console.log("error in downloadInvoicePDF", error);
        const updatedButtonLoadingStates = [...buttonLoadingStates];
        updatedButtonLoadingStates[index] = false;
        setButtonLoadingStates(updatedButtonLoadingStates);
      });
  };
  const loadOrderData = () => {
    getOrdersByUserId(userId)
      .then((res) => {
        console.log("res in order by userid", res.data);
        const resp = res.data;
        const invertedData = resp.reverse(); // Reverse the order of the array
        setOrderData(invertedData);

        const orders = resp
          .filter((order) => !order.IsActive)
          .map((ord) => ord._id);
        console.log("or", orders);
        setOrderCancelData((prevState) => [...prevState, ...orders]);
        getUsers(userId)
          .then((res) => {
            console.log("res of user", res.data);
            setFirstName(res.data.firstName);
            setLastName(res.data.lastName);
          })
          .catch((err) => {
            console.log("error in user", err);
          });
      })
      .catch((error) => {
        console.log("error in order by userid", error);
      });
  };

  useEffect(() => {
    loadOrderData();
    loadDeliveryOptions();
  }, []);

  const [randomOrderId, setRandomOrderId] = useState("");
  // const [deliverytime, setDeliverytime] = useState("");
  const [particularOrder, setParticularOrder] = useState([]);
  const [minDate, setMinDate] = useState(dayjs(new Date())); // Initialize minDate with today's date
  const [maxDate, setMaxDate] = useState(dayjs(new Date()));
  // const [totalAmount, setTotalAmount] = useState(0); // Initialize minDate with today's date
  // const [shippingCharge, setShippingCharge] = useState(0); // Initialize minDate with today's date

  const [deliveryData, setDeliveryData] = useState({
    deliveryTypeId: null,
    deliveryTime: dayjs(new Date()),
  });
  const handleStartTimeChange = (date, selectedItem) => {
    console.log("dd", dayjs(date).format("YYYY-MM-DDTHH:mm"));
    const formattedDateTime = dayjs(date.format("YYYY-MM-DDTHH:mm"));

    setDeliveryData({
      deliveryTypeId: selectedItem._id,
      deliveryTime: formattedDateTime,
    });
    console.log("deliveryData", deliveryData);
  };

  const handleTog_Edit = (orderid) => {
    console.log("orderid", orderid);
    getOrderToUser(orderid)
      .then((res) => {
        console.log(res.data);

        if (!res.data.isOk) {
          toast.error(res.data.message);
        } else {
          setmodal_list(true);
          const response = res.data.data;
          setParticularOrder(response);
          console.log("rr", response.deliveryData.deliveryTypeId);
          setRandomOrderId(response.randomOrderId);
          setDeliveryData({
            deliveryTypeId: response.deliveryData.deliveryTypeId,
            deliveryTime: dayjs(new Date(response.deliveryData.deliveryTime)),
          });
          // setShippingCharge(response.shippingCharge);
          // setTotalAmount(response.totalAmount);
          setMinDate(dayjs(new Date(response.deliveryData.deliveryTime)));
          setMaxDate(dayjs(new Date(response.deliveryData.deliveryTime)));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("orderCancelData", orderCancelData);
  }, []);
  const handleUpdate = (e) => {
    e.preventDefault();
    const dTime = deliveryData.deliveryTime;
    updateOrderByUser(particularOrder._id, dTime)
      .then((res) => {
        if (res.data.isOk) {
          toast.success("Order Updated Successfully");
          console.log("res updated", res.data.data);
          setmodal_list(false);
          loadOrderData();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error in update order", err);
      });
  };

  const handleRemoveItem = (orderid) => {
    removeOrderByUser(orderid)
      .then((res) => {
        console.log("res in remove", res.data);
        if (!res.data.isOk) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          console.log("ress", res.data.data._id);
          // setOrderCancelData(res.data.data._id);
          setOrderCancelData((prevState) => [...prevState, res.data.data._id]);
          loadOrderData();
        }
      })
      .catch((err) => {
        console.log("error in remove order", err);
      });
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Cart />
      <Hader Color="#000" />
          <MobileHeader />
          {/* <BradeCrum Title="My Orders" /> */}
          <BannerTwo Title="My Orders" subTitle="My Orders" />
          {/* <BannerTwo Title="My Orders" subTitle="My Orders" /> */}
          <Container className="sec_ptb_120 ">
            <Row>
              <Col lg="3">
                {" "}
                <ProfileSideBar />
              </Col>
              <Col lg="9">
                {orderData.length > 0 ? (
                  <Card className="myProfileCard  ">
                    <Table cla bordered responsive>
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Order Id</th>
                          <th>Order Date</th>
                          {/* <th>Recipient Name</th> */}
                          <th>Total Price</th>

                          <th>Get Invoice</th>
                          <th>Delivery Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData.map((order, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{order.randomOrderId}</td>
                            <td>
                              <>
                                {/* {moment(new Date(order.createdAt)).format(
                                  "MM-DD-YY hh:mm a"
                                )} */}
                                {/* <td> */}
                                  {moment
                                    .utc(order.createdAt)
                                    .tz("America/Los_Angeles")
                                    .format("MM-DD-YY hh:mm a")}
                                {/* </td> */}
                                {/* {orderCancelData.includes(order._id) && (
                                  <p className="text-danger">Order Cancelled</p>
                                )} */}
                                { order.OrderStatus == "Cancelled" &&  <p className="text-danger">Order Cancelled</p> }
                              </>
                            </td>
                            {/* <td>
                        {firstName} {lastName}
                      </td> */}
                            <td>$ {order.totalAmount.toFixed(2)}</td>

                            <td>
                              <button
                                onClick={() => {
                                  downloadPDF(
                                    order._id,
                                    order.randomOrderId,
                                    index
                                  );
                                }}
                                className="btn btn_primary text-uppercase invoiceBtn"
                              >
                                {buttonLoadingStates[index] ? (
                                  <span style={{ width: "200px" }}>
                                    {/* <TailSpin
                                      visible={true}
                                      height="20"
                                      width="90"
                                      color=""
                                      ariaLabel="tail-spin-loading"
                                      radius="2"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                    /> */}
                                    <CircularProgress
                                      size={20}
                                      sx={{ color: "white" }}
                                      className="hello"
                                    />
                                  </span>
                                ) : (
                                  <span>Get Invoice</span>
                                )}
                              </button>
                            </td>
                            {deliveryOptionsData.map((deliveryoption) => {
                              const d =
                                deliveryoption._id === order.DeliveryType
                                  ? deliveryoption
                                  : null;

                              return (
                                d && (
                                  <>
                                    <td>
                                      {d.DeliveryType}
                                      {/* <p>
                                        {!d.IsShippingAddress &&
                                        order.deliveryData
                                          ? `at ${moment(
                                              new Date(
                                                order.deliveryData.deliveryTime
                                              )
                                            ).format("MM-DD-YY hh:mm a")}`
                                          : null}
                                      </p> */}
                                    </td>
                                  </>
                                )
                              );
                            })}
                            {deliveryOptionsData.map((deliveryoption) => {
                              const d =
                                deliveryoption._id === order.DeliveryType
                                  ? deliveryoption
                                  : null;

                              return d &&
                                !d.IsShippingAddress &&
                                !orderCancelData.includes(order._id) ? (
                                <>
                                  <td>
                                    <div class="hstack gap-3 flex-wrap">
                                      <a
                                        onClick={() => {
                                          handleTog_Edit(order._id);
                                        }}
                                        // href="#!"
                                        style={{
                                          color: "green",
                                          fontSize: "24px",
                                          cursor: "pointer", // Apply cursor pointer on hover
                                        }}
                                      >
                                        <AiOutlineEdit />
                                      </a>
                                      <a
                                        onClick={() => {
                                          handleRemoveItem(order._id);
                                        }}
                                        // href="#!"
                                        style={{
                                          color: "#e73232",
                                          fontSize: "20px",
                                          cursor: "pointer", // Apply cursor pointer on hover
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </a>
                                    </div>
                                  </td>
                                </>
                              ) : null;
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                ) : (
                  <h5 className="text-center mt-3">
                    There are no orders to display. <hr />
                  </h5>
                )}
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        className="modal"
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setmodal_list(false);
          }}
        >
          Edit Order
        </ModalHeader>
        <form>
          <ModalBody>
            <Row>
              <Col lg="6">Order Id : {randomOrderId}</Col>
            </Row>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
                <Row style={{ marginTop: "20px" }}>
                  {deliveryOptionsData.map((item) => {
                    const deliveryOption =
                      item._id === particularOrder.DeliveryType ? item : null;

                    return (
                      deliveryOption && (
                        <>
                          {deliveryOption.DeliveryType === "Pickup" ? (
                            <DateTimePicker
                              label="Pickup Time"
                              // value={dateOption ? minDate : deliveryData.deliveryTime}
                              value={deliveryData.deliveryTime}
                              onChange={(st) => handleStartTimeChange(st, item)}
                              inputVariant="outlined"
                              InputProps={{
                                style: { width: "100%" },
                              }}
                              // minDate={minDate}
                              // minDate={deliveryData.deliveryTime}
                            />
                          ) : (
                            <DateTimePicker
                              label="Dine Time"
                              // value={dateOption ? minDate : deliveryData.deliveryTime}
                              value={deliveryData.deliveryTime}
                              onChange={(st) => handleStartTimeChange(st, item)}
                              // minDate={minDate}
                              minDate={deliveryData.deliveryTime}
                              inputVariant="outlined"
                              InputProps={{
                                style: { width: "100%" },
                              }}
                              maxDate={maxDate}
                              format="hh:mm" // Set the format to display only time
                              autoOk
                            />
                          )}
                        </>
                      )
                    );
                  })}
                </Row>
              </DemoContainer>
            </LocalizationProvider>

            {/* <Row style={{ marginTop: "20px" }}>
              <Col lg={6}></Col>
              <Col lg={6}>
                <div>
                  <label>Shipping Charge: </label>
                  {shippingCharge}
                </div>
              </Col>
            </Row> */}
            {/* <Row>
              <Col lg={6}></Col>
              <Col lg={6}>
                <div>
                  <label>Total Amount: </label>
                  {totalAmount.toFixed(2)}
                </div>
              </Col>
            </Row> */}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-dark"
                id="add-btn"
                onClick={handleUpdate}
                // onClick={handleAddOptions}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => {
                  setmodal_list(false);
                }}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default MyOrders;
