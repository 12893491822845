import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//import images
// import logoLight from "../../../assets/images/logo-light.png";
import tick from "../../assets/img/righttick.jpg";
import { getSetupByName, sendEmailOnOrderPlaced, } from "../../functions/Notifications/EmailNotifications";
import { checkSubscription, getOrderByToken } from "../../functions/Orders/Order";
const PaymentSuccess = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const payerID = urlParams.get("PayerID");
  const subscription_id = urlParams.get("subscription_id");
  const ba_token = urlParams.get("ba_token");


  console.log(subscription_id, ba_token, token, payerID);

  useEffect(() => {
    if (token && payerID && !subscription_id && !ba_token) {
      getOrderByToken(token, payerID).then((order) => {
        console.log("token order", order.data.orderId);
      getSetupByName("Order Email")
        .then((res) => {
          console.log("res in email", res);
          const response = res.data;
          sendEmailOnOrderPlaced(
            response.EmailFrom,
            response.EmailPassword,
            [],
            response.emailSubject,
            response.EmailSignature,
            response.OutgoingServer,
            response.OutgoingPort,
            order.data.orderId
          )
            .then((res1) => {
              console.log("res1", res1);
              console.log("email sent successfully");
            })
            .catch((err) => {
              console.log("error in email send", err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      })
    }
    if (subscription_id && token && ba_token) {
      checkSubscription(subscription_id, ba_token, token).then((order) => {
        console.log("subscriton  check order", order.data.orderId);
      getSetupByName("Order Email")
        .then((res) => {
          console.log("res in email", res);
          const response = res.data;
          sendEmailOnOrderPlaced(
            response.EmailFrom,
            response.EmailPassword,
            [],
            response.emailSubject,
            response.EmailSignature,
            response.OutgoingServer,
            response.OutgoingPort,
            order.data.orderId
          )
            .then((res1) => {
              console.log("res1", res1);
              console.log("email sent successfully");
            })
            .catch((err) => {
              console.log("error in email send", err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      })
    }
  }, []);
  document.title = "Payment Successful";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        {/* <ParticlesAuth> */}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  {/* <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div> */}
                  {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4 text-center">
                    <div className="avatar-lg mx-auto mt-2">
                      {/* <div className="avatar-title bg-light text-success display-3 rounded-circle"> */}
                      {/* <i className="ri-checkbox-circle-fill"></i> */}
                      <img src={tick} height="150px" width="150px" />
                      {/* </div> */}
                    </div>
                    <div className="mt-4 pt-2">
                      <h4>Payment Successful</h4>
                      <p className="text-muted mx-4">
                        We have received the payment successfully.
                      </p>
                      <div className="mt-4">
                        <Link
                          to="/thankyou"
                          className="btn btn-success w-100 text-light"
                        >
                          SEE DETAILS
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* </ParticlesAuth> */}
      </div>
    </React.Fragment>
  );
};

export default PaymentSuccess;
