import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/App.css";
import Homepage from "./Pages/Homepage";
import Login from "./Pages/Login";
import SignUp from "./Pages/Signup";
import ProductListing from "./Pages/ProductListing";
import ProductDetails from "./Pages/ProductDetails";
import ContactUs from "./Pages/ContactUs";
import OTPVerify from "./Pages/OTPVerify";
import ForgotPassword from "./Pages/ForgotPassword";
import { MyContextProvider } from "./MyContext";
import ChangeNewPasswordPage from "./Pages/ChangeNewPassword";
import MyAccount from "./Pages/Profile/MyAccount";
import Blog from "./Pages/blog";
import Subscription from "./Pages/Subscription";
import "../src/assets/css/blog.css";
import "../src/assets/css/fontawesome.css";
import "../src/assets/css/BannerTwo.css";
import AboutUs from "./Pages/AboutUs";
import MyAddress from "./Pages/Profile/MyAddress";
import BlogDetails from "./Pages/BlogDetails";
import Checkout from "./Pages/checkout";
import "../src/assets/css/responsive.css";
import ShoppingCart from "./Pages/ShoppingCart";
import "../src/assets/css/main.css";
import ThankYou from "./Pages/ThankYou";
import MyOrders from "./Pages/Profile/MyOrders";
import MyWallet from "./Pages/Profile/MyWallet";
import LoyalityPoint from "./Pages/LoyalityPoint";
import MySubscription from "./Pages/Profile/MySubscription";
import Faq from "./Pages/faq";
import Shipping from "./Pages/Shipping";
import StorePolicy from "./Pages/StorePolicy";
import MyRewards from "./Pages/Profile/MyRewards";
import MobileHeader from "./Component/MobileHeader";
import { FindInPage } from "@mui/icons-material";
import FindStore from "./Pages/FindStore";
import ReferFriends from "./Pages/ReferFriends";
import PaymentSuccess from "./Pages/Payment/PaymentSuccess";
import PaymentFailure from "./Pages/Payment/PaymentFailure";
import Franchise from "./Pages/Franchise";
import Location from "./Pages/Location";
import Beans from "./Pages/Beans";
import Category from "./Pages/Category";
import Merchandise from "./Pages/Merchandise";
import SuccessCheckout from "./Pages/SuccessCheckoutPage";
import ADAcompliance from "./Pages/ADAcompliance";

function App() {
  return (
    <div className="App">
      <MyContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/producListing/:cid" element={<ProductListing />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/success-checkout" element={<SuccessCheckout />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/productDetails/:pid" element={<ProductDetails />} />
            <Route path="/aboutUS" element={<AboutUs />} />
            <Route path="/loyalty" element={<LoyalityPoint />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/verify-otp" element={<OTPVerify />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/findStore" element={<FindStore />} />
            <Route path="/location" element={<Location />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/store-policy" element={<StorePolicy />} />
            <Route path="/accessibility" element={<ADAcompliance />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-details/:blogid" element={<BlogDetails />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/shopping-cart" element={<ShoppingCart />} />
            <Route path="/my-orders" element={<MyOrders />} />
            <Route path="/my-subscription" element={<MySubscription />} />
            <Route path="/merchandise" element={<Merchandise />} />

            {/* <Route path="/product-category/:cid" element={<ProductList />} /> */}
            <Route
              path="/change-password"
              element={<ChangeNewPasswordPage />}
            />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/my-address" element={<MyAddress />} />
            <Route path="/my-wallet" element={<MyWallet />} />
            <Route path="/my-rewards" element={<MyRewards />} />
            <Route path="/referFriend" element={<ReferFriends />} />
            <Route path="/mobileheader" element={<MobileHeader />} />
            <Route path="/subscriptions-list" element={<Beans />} />
            <Route path="/view-menu" element={<Category />} />

            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-failure" element={<PaymentFailure />} />
          </Routes>
        </BrowserRouter>
      </MyContextProvider>
    </div>
  );
}

export default App;
