import React from "react";
import { Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import Logo from "./../assets/img/logo_white_1x.png";
import "react-multi-carousel/lib/styles.css";
const FindStoreCaresol = ({ companyLocation }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1025, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <Carousel responsive={responsive}>
        {companyLocation.length > 0 &&
          companyLocation.map(
            (it) =>
              it.IsActive && (
                <div>
                  <Card className="findCard">
                    <img
                      className="logoImgFind"
                      // src={Logo}
                      src={`${process.env.REACT_APP_API_URL_COFFEE}/${it.StoreLogo}`}
                      alt="logo"
                    />
                    <h5>{it.CompanyName}</h5>
                    <p>{it.Address}</p>
                    {/* <p>
            Monday - Friday: 7:00 am - 6:00 pm

            </p>
            <p>
            Saturday - Sunday: 7:00 am - 5:00 pm
            </p> */}
                  </Card>
                </div>
              )
          )}
        {/* <div>
          <Card className="findCard">
            <img className="logoImgFind" src={Logo} alt="logo" />
            <h5>NICK'S ROASTING CO.</h5>
            <p>2558 Greenwood Lane, 120 Cameron Park, CA 95682</p>
            <p>
            Monday - Friday: 7:00 am - 6:00 pm

            </p>
            <p>
            Saturday - Sunday: 7:00 am - 5:00 pm
            </p>
          </Card>
        </div>
        <div>
          <Card className="findCard">
            <img className="logoImgFind" src={Logo} alt="logo" />
            <h5>NICK'S ROASTING CO.</h5>
            <p>2558 Greenwood Lane, 120 Cameron Park, CA 95682</p>
            <p>
            Monday - Friday: 7:00 am - 6:00 pm

            </p>
            <p>
            Saturday - Sunday: 7:00 am - 5:00 pm
            </p>
          </Card>
        </div>
        <div>
          <Card className="findCard">
            <img className="logoImgFind" src={Logo} alt="logo" />
            <h5>NICK'S ROASTING CO.</h5>
            <p>2558 Greenwood Lane, 120 Cameron Park, CA 95682</p>
            <p>
            Monday - Friday: 7:00 am - 6:00 pm

            </p>
            <p>
            Saturday - Sunday: 7:00 am - 5:00 pm
            </p>
          </Card>
        </div>
        <div>
          <Card className="findCard">
            <img className="logoImgFind" src={Logo} alt="logo" />
            <h5>NICK'S ROASTING CO.</h5>
            <p>2558 Greenwood Lane, 120 Cameron Park, CA 95682</p>
            <p>
            Monday - Friday: 7:00 am - 6:00 pm

            </p>
            <p>
            Saturday - Sunday: 7:00 am - 5:00 pm
            </p>
          </Card>
        </div>
        <div>
          <Card className="findCard">
            <img className="logoImgFind" src={Logo} alt="logo" />
            <h5>NICK'S ROASTING CO.</h5>
            <p>2558 Greenwood Lane, 120 Cameron Park, CA 95682</p>
            <p>
            Monday - Friday: 7:00 am - 6:00 pm

            </p>
            <p>
            Saturday - Sunday: 7:00 am - 5:00 pm
            </p>
          </Card>
        </div> */}
      </Carousel>
    </React.Fragment>
  );
};

export default FindStoreCaresol;
