import axios from "axios";

export const listActiveCategories = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list-active/categoryMaster`
  );
};

export const getCategoryMaster = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/categoryMaster/${_id}`
  );
};

export const getCategoryFromMenu = async (menuid) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list-from-menu/category/${menuid}`
  );
};
