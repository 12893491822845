import axios from "axios";

export const createTipMaster= async (values) => {
    return await axios.post(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/Tip`,
      values
    );
  };
  
  export const removeTipMaster= async (_id) => {
    return await axios.delete(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove/Tip/${_id}`
    );
  };
  
  export const listTipMaster= async () => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/Tip`
    );
  };
  
  
  export const updateTipMaster= async (_id, values) => {
    return await axios.put(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/Tip/${_id}`,
      values
    );
  };
  
  export const getTipMaster= async (_id) => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/Tip/${_id}`
    );
  };
  