import axios from "axios";

export const getSetupByName = async (formname) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/notification-setup-name/${formname}`
  );
};

// export const sendEmailOnOrderPlaced = async () => {
//   return await axios.post(
//     `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/email-on-order`
//   );
// };

export const sendEmailOnOrderPlaced = async (
  emailFrom,
  emailPassword,
  CCMail,
  emailSubject,
  emailBody,
  outServer,
  outPort,
  orderid
) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/email-on-order`,
    {
      emailFrom,
      emailPassword,
      CCMail,
      emailSubject,
      emailBody,
      outServer,
      outPort,
      orderid,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const sendEmail = async (
  emailFrom,
  emailPassword,
  CCMail,
  emailSubject,
  emailBody,
  outServer,
  outPort,
  emailTo
) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/send-email`,
    {
      emailFrom,
      emailPassword,
      CCMail,
      emailSubject,
      emailBody,
      outServer,
      outPort,
      emailTo
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
