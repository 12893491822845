import React, { useState, useEffect, useContext } from "react";
import "./../assets/css/productListing.css";
import BradeCrum from "../Component/BradeCrum";
import Hader from "../Component/Hader";
import ProductImg from "./../assets/img/productListingImg.png";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaCoffee } from "react-icons/fa";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CategoryProductListData } from "../functions/ProductDetails/Productlist";
import { Puff } from "react-loader-spinner";
import { MyContext } from "../MyContext";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SortSelect from "../Component/SortSelect";
import Filterdrawer from "../Component/Filterdrawer";
import { Button } from "@mui/material";
import productListingImg from "../assets/img/productListingImg.png";
import { getCategoryMaster } from "../functions/Category/category";
import Cart from "../Component/Cart";
import MobileHeader from "../Component/MobileHeader";

const ProductListing = () => {
  const {
    CategProductData,
    setCategProductData,
    sortOption,
    drawerOpen,
    setDrawerOpen,
  } = useContext(MyContext);

  const params = useParams();
  const CID = params.cid;
  getCategoryMaster(CID).then((res) => {
    console.log("categg", res.data);
    const d = res.data.categoryName;
    console.log("d", d);
    setCategName(d);
  });
  // const [pg, setPg] = useState("");
  const [catgeName, setCategName] = useState("");

  // const ScrollToTopOnMount = () => {
  //   useEffect(() => {
  //     window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  //   }, []);
  //   console.log("gg");
  //   return null;
  // };
  // useEffect(() => {
  //   loadProduct();
  // }, []);

  const loadProduct = (bid) => {
    window.location.replace(`/productDetails/${bid}`);
    // window.location.reload();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const loadProductData = () => {
    let varia = [];
    CategoryProductListData(sortOption, CID, varia).then((res) => {
      console.log("product Data", res.data.data);
      const resp = res.data.data;
      setCategProductData(resp);
    });
  };

  useEffect(() => {
    loadProductData();
  }, [CID]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, [CID]);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          {/* <ScrollToTopOnMount /> */}
          <Cart />
          <Filterdrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum
          // Title={catgeName}
          //  Title="From Bean to Brew, Excellence in Every Cup."
          />

          <Container className="productListinContainer">
            <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
              <FaCoffee className="fas fa-coffee" /> {catgeName}
            </h2>
            {/* <div>
              <div className="section_title text-uppercase text-center">
                <h1 className="small_title wow fadeInUp" data-wow-delay=".1s">
                  <FaCoffee className="fas fa-coffee" /> HOT DRINKS
                </h1>
              </div>
            </div> */}
            <section className="shop_section sec_ptb_120 bg_default_gray">
              <div>
                <div className="shop_filter_bar">
                  <div>
                    <div className="product-filter-sort-div">
                      <Button
                        endIcon={<FilterAltOutlinedIcon />}
                        variant="outlined"
                        sx={{
                          color: "black",
                          borderColor: "black",
                          "&:hover": {
                            color: "black",
                            borderColor: "black",
                          },
                        }}
                        onClick={toggleDrawer("left", true)}
                      >
                        Filters
                      </Button>
                      <SortSelect
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        CID={CID}
                        // handleSelectChange={handleSelectChange}
                      />
                    </div>
                  </div>
                </div>
                {/* <div
                  className="shop_filter_grid grid wow fadeInUp"
                  data-wow-delay=".3s"
                > */}
                <Row>
                  {CategProductData.length > 0 ? (
                    CategProductData.map((data) => (
                      <Col lg={3} md={6} key={data._id}>
                        <Link
                          to="#"
                          // to={`/productDetails/${data._id}`}
                          onClick={() => loadProduct(data._id)}
                        >
                          <Card className="productListingCard">
                            <div className="text-center">
                              <img
                                className="productImgListing"
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${data.productImage}`}
                                // src={productListingImg}
                                style={{ width: "250px", height: "300px" }}
                                alt="img"
                              />
                            </div>
                            <Link
                              className="productListingTitle"
                              to={`/productDetails/${data._id}`}
                            >
                              {data.productName}
                            </Link>
                            <div
                              className="text-center"
                              style={{ fontWeight: "bold" }}
                            >
                              ${data.basePrice}
                            </div>
                            {/* <Link className="shopNowBtn" to="#">
                              Shop Now
                            </Link> */}
                          </Card>
                        </Link>
                      </Col>
                    ))
                  ) : (
                    <h5 className="text-center text-dark">
                      {" "}
                      There is no items to display.{" "}
                    </h5>
                  )}
                </Row>
                {/* </div> */}
              </div>
            </section>
          </Container>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductListing;
