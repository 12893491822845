import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import tick from "../../src/assets/img/logo.png";

const SuccessCheckout = () => {

  document.title = "Order Checkout";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        {/* <ParticlesAuth> */}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  {/* <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 bg-dark">
                  <CardBody className="p-4 text-center">
                    <div className="avatar-lg mx-auto mt-2">
                      {/* <div className="avatar-title bg-light text-success display-3 rounded-circle"> */}
                      {/* <i className="ri-close-circle-fill"></i> */}
                      <img src={tick} height="150px" width="150px" />
                      {/* </div> */}
                    </div>
                    <div className="mt-4 pt-2 text-light">
                      {/* <h4>Order Checkout</h4> */}
                      <p className="text-light mx-4 ">
                        Thank you for you visiting us! 
                      </p>
                     
                      <div className="mt-4">
                        <Link
                          to="/"
                          className="btn btn-light w-100"
                          style={{ fontWeight: "bold" }}
                        >
                          Continue Browsing
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SuccessCheckout;
