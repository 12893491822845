import axios from "axios";

export const createUsers = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/users`,
    values
  );
};
export const OTPUsers = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/otp/users`,
    values
  );
};
export const OTPResend = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/resend-otp/users`,
    values
  );
};


export const getUsers = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/users/${_id}`
  );
};

export const updateUsers = async (_id, values) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/users/${_id}`,
    values
  );
};

export const ChangePasswordUser = async (valuesPassword) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/user-change-password`,
    valuesPassword
  );
};

export const checkRefCode = async (code) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/check/refCode/${code}`
  );
};
