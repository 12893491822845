import React, { useState, useEffect, useContext } from "react";
import Hader from "../Component/Hader";
import Footer from "../Component/Footer";
import BannerTwo from "../Component/BannerTwo";
import { FaComment, FaEye, FaRegCalendar, FaSearch } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";

import { Link, useNavigate } from "react-router-dom";
// import MobileHader from "../Component/MobileHader";
import { useParams } from "react-router-dom";
import { listActiveCategories } from "../functions/Category/category";
import axios from "axios";
import { createBlogsComment } from "../functions/CMS/blogComment";
import "../assets/css/blogdetails.css";
import BradeCrum from "../Component/BradeCrum";

import {
  listActiveBlogs,
  getBlogs,
  topPopularPosts,
} from "../functions/CMS/Blog";
import moment from "moment";
import Cart from "../Component/Cart";
import { Puff } from "react-loader-spinner";
import { Breadcrumb } from "react-bootstrap";
import MobileHeader from "../Component/MobileHeader";
import { MyContext } from "../MyContext";

const initialState = {
  blogId: "",
  text: "",
  user: "",
};

const BlogDetails = () => {
  const {
    searchResults,
    setSearchResults,
    setCoffeeHistoryCheck,
    setCoffeeTrendingCheck,
  } = useContext(MyContext);

  const params = useParams();
  const BlogID = params.blogid;
  const navigate = useNavigate();

  const User = localStorage.getItem("NicksCoUser");
  const [blogDetailsData, setBlogDetailsData] = useState([]);
  const [popularProducts, setpopularProducts] = useState([]);
  // const [blogData, setBlogData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [values, setValues] = useState(initialState);
  const { blogId, text, user } = values;

  const [searchTerm, setSearchTerm] = useState("");

  const [searchResultsDetails, setSearchResultsDetails] = useState([]);

  useEffect(() => {
    // Define an async function to perform the search operation
    const loadSearchResult = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/serachblogDetails/blogs?search=${searchTerm}`
        );
        console.log("Search results:", response.data);
        setSearchResultsDetails(response.data);
      } catch (error) {
        console.error("Error searching blogs:", error);
      }
    };

    // Call the search function whenever searchTerm changes
    loadSearchResult();
  }, [searchTerm]); // Dependency on searchTerm

  const highlightMatch = (text, highlight) => {
    const regex = new RegExp(`(${highlight})`, "gi");
    return text.split(regex).map((part, index) => {
      return part.toLowerCase() === highlight.toLowerCase() ? (
        <span
          key={index}
          className="highlight"
          style={{ backgroundColor: "#f0cca7" }}
        >
          {part}
        </span>
      ) : (
        part
      );
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update searchTerm as the user types
  };

  const AddComment = (e) => {
    e.preventDefault();

    console.log("values", User);

    createBlogsComment(values)
      .then((res) => {
        console.log("res", res.data);
        if (res.data.isOk) {
          setValues(initialState);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleCoffeeTrending = () => {
    // listActiveBlogs().then((res) => {
    //   console.log("res blog", res.data);
    //   setSearchResults(
    //     res.data.filter((blog) => blog.blogType === "coffeeTrending")
    //   );
    // });
    setCoffeeTrendingCheck(true);
    navigate("/blog");
  };

  const handleCoffeeHistory = () => {
    // listActiveBlogs().then((res) => {
    //   console.log("res blog", res.data);
    //   setSearchResults(
    //     res.data.filter((blog) => blog.blogType === "coffeeHistory")
    //   );
    // });
    setCoffeeHistoryCheck(true);
    window.location.replace("/blog");
  };

  const loadBlogDetailsData = () => {
    getBlogs(BlogID).then((res) => {
      console.log("res blog in details", res.data);
      setBlogDetailsData([res.data]);
    });
  };

  const handleChange = (e) => {
    if (User) {
      setValues({
        ...values,
        text: e.target.value,
        blogId: BlogID,
        user: User,
      });
    } else {
      window.location.replace("/login");
      // navigate("/login");
    }
  };

  const loadCategoryData = () => {
    listActiveCategories().then((res) => {
      console.log("res CATEG", res.data);
      const invertedData = res.data.reverse(); // Reverse the order of the array
      setCategoryData(invertedData);
    });
  };

  const loadPopularProducts = () => {
    topPopularPosts().then((res) => {
      console.log("res in popular pro", res.data);
      setpopularProducts(res.data);
    });
  };

  // const loadBlogData = () => {
  //   listActiveBlogs().then((res) => {
  //     console.log("res blog", res.data);
  //     setBlogData(res.data);
  //   });
  // };

  useEffect(() => {
    // loadBlogData();
    loadBlogDetailsData();
    loadPopularProducts();
    loadCategoryData();
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Cart />
      <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum Title="Blog Details" />
          {/* <MobileHader /> */}
          {/* <BannerTwo Title="Blog Details" subTitle="Blog Details" /> */}
          <section className="details_section blog_details sec_ptb_120 bg_default_gray">
            <div className="container">
              <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
                <div className="col-lg-8 col-md-8">
                  <div className="details_content mb-0">
                    {blogDetailsData?.map((bd) => (
                      <div
                        className="details_image wow fadeInUp"
                        data-wow-delay=".1s"
                      >
                        <img
                          // src={Blog1}
                          src={`${process.env.REACT_APP_API_URL_COFFEE}/${bd.blogImage}`}
                          alt="image_not_found"
                        />
                      </div>
                    ))}
                    {blogDetailsData?.map((bd) => (
                      <div className="wrap_space">
                        <ul className="post_meta ul_li">
                          <li>
                            <Link to="#!">
                              <FaEye className="fal fa-comment-alt" />
                              &nbsp; {bd.views} views
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="#!">
                              <FaComment className="fal fa-comment-alt" />
                              &nbsp;{bd.comments.length} Comments
                            </Link>
                          </li> */}
                          <li>
                            <FaRegCalendar className="fal fa-calendar-alt" />
                            &nbsp;
                            {/* {moment(new Date(bd.createdAt)).format(
                              "DD MMMM YYYY"
                            )} */}
                            {moment
                              .utc(bd.createdAt)
                              .tz("America/Los_Angeles")
                              .format("DD MMMM YYYY")}
                          </li>
                        </ul>
                        <h2
                          className="details_title text-uppercase wow fadeInUp"
                          data-wow-delay=".1s"
                        >
                          {/* {bd.blogTitle} */}
                          {highlightMatch(bd.blogTitle, searchTerm)}
                        </h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: bd.blogDesc,
                          }}
                        ></p>
                      </div>
                    ))}

                    {/* <hr className="m-0 wow fadeInUp" />
                    <div
                      className="comment_form wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="wrap_space">
                        <h3 className="area_title text-uppercase"> comment</h3>
                        <form action="#">
                          <div className="form_item">
                            <textarea
                              name="comment"
                              value={values.text}
                              placeholder="Enter Your Message :"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              onClick={AddComment}
                              className="btn btn_primary text-uppercase"
                            >
                              Add Comment
                            </button>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-8">
                  <aside className="sidebar_section">
                    <div
                      className="sb_widget sb_search wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h3 className="sb_widget_title text-uppercase">
                        Search here
                      </h3>
                      <form
                      // onSubmit={(e) => {
                      //   e.preventDefault();
                      //   handleSearch();
                      // }}
                      >
                        <div className="form_item mb-0">
                          <input
                            id="sb_search_input"
                            type="search"
                            name="search"
                            placeholder="Search"
                            value={searchTerm}
                            // onChange={(e) => setSearchTerm(e.target.value)}
                            onChange={handleSearch} // Call handleSearch on input change
                          />
                          <label
                            className="form_icon"
                            htmlFor="sb_search_input"
                          >
                            <FaSearch className="far fa-search" />
                          </label>
                        </div>
                      </form>
                    </div>
                    <div
                      className="sb_widget sb_category wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h3 className="sb_widget_title text-uppercase">
                        Categories
                      </h3>
                      <ul className="ul_li_block">
                        {/* {categoryData.map((cd) => (
                          <li>
                            <Link
                              key={cd._id} // Add key prop here
                              // to="#"
                              // to={`/product-category/${cd._id}`}
                              to={`/producListing/${cd._id}`}
                            >
                              <span>
                                <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                                {cd.categoryName}
                              </span>
                            </Link>
                          </li>
                        ))} */}
                        <li>
                          <Link
                            // to={`/producListing/${cd._id}`}
                            to="/blog"
                            onClick={() => {
                              setCoffeeTrendingCheck(true);
                              navigate("/blog");
                            }}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              {/* {cd.categoryName} */}
                              Coffee Trending
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            // to={`/producListing/${cd._id}`}
                            // to={`/producListing/${cd._id}`}
                            to="/blog"
                            onClick={() => {
                              setCoffeeHistoryCheck(true);
                              navigate("/blog");
                            }}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              {/* {cd.categoryName} */}
                              Coffee History
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="sb_widget sb_popular_post wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h3 className="sb_widget_title text-uppercase">
                        Popular Posts
                      </h3>
                      {searchResultsDetails
                        .filter((pp) => popularProducts.includes(pp._id)) // Filter blog posts based on popularProducts
                        .map((pp) => (
                          <div className="recent_post" key={pp._id}>
                            <Link
                              className="item_image"
                              to={`/blog-details/${pp._id}`}
                              onClick={() => window.location.reload()}
                            >
                              <img
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${pp.blogImage}`}
                                alt="image_not_found"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </Link>
                            <div
                              style={{ marginTop: "10px" }}
                              className="item_content"
                            >
                              <h6 className="item_title">
                                <Link
                                  // to="#"
                                  to={`/blog-details/${pp._id}`}
                                  onClick={() => window.location.reload()}
                                >
                                  {pp.blogTitle}
                                </Link>
                              </h6>
                              <span className="post_date text-uppercase">
                                {moment
                                  .utc(pp.createdAt)
                                  .tz("America/Los_Angeles")
                                  .format("MM-DD-YY hh:mm a")}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default BlogDetails;
