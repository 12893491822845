import axios from "axios";

export const listLocationDetails = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list-details/company-locations`
  );
};

export const findLocation = async (stateId, cityId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/find/company-locations/${stateId}/${cityId}`
  );
};
export const findLocationbyState = async (stateId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/find/company-location-state/${stateId}`
  );
};
