import axios from "axios";

export const listBlogs = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/blogs`
  );
};

export const listActiveBlogs = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/listActive/blogs`
  );
};

export const getBlogs = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/blogs/${_id}`
  );
};

export const getViewsData = async (bid) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/getViewsData/blogs/${bid}`
  );
};

export const updateViews = async (bid, views) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/updateViews/blogs/${bid}/${views}`
  );
};

export const AddLikes = async (blogId, userId) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/addLikes/blogs/${blogId}/${userId}`
  );
};

export const RemoveLikes = async (blogId, userId) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/removeLikes/blogs/${blogId}/${userId}`
  );
};

export const topPopularPosts = async () => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/topPopularPosts/blogs`
  );
};

export const listBlogCategory = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/list/blogCategory`
  );
};
