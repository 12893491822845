import axios from "axios";

export const createUserBillingAddress = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/userBillingAddress`,
    values
  );
};

// export const createUserBillingAddress = async ({
//   firstNameBA,
//   lastNameBA,
//   contactNoBA,
//   companyNameBA,
//   cityBA,
//   stateIdBA,
//   countryIdBA,
//   addressLine1BA,
//   addressLine2BA,
//   zipCodeBA,
//   userIdBA,
//   IsActiveBA,
//   isBillingSameBA,
// }) => {
//   return await axios.post(
//     `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/userBillingAddress`,
//     {
//       firstNameBA,
//       lastNameBA,
//       contactNoBA,
//       companyNameBA,
//       cityBA,
//       stateIdBA,
//       countryIdBA,
//       addressLine1BA,
//       addressLine2BA,
//       zipCodeBA,
//       userIdBA,
//       IsActiveBA,
//       isBillingSameBA,
//     }
//   );
// };

export const removeUserBillingAddress = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove/userBillingAddress/${_id}`
  );
};

export const updateDefaultBillingAddress = async (userId, addressId) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/updateDefaultBillAdd/${userId}/${addressId}`
  );
};

export const getDefaultBillingAddressByUser = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/getDefaultBillingAddress/${userId}`
  );
};
