import axios from "axios";

export const createUserCart = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/user-cart`,
    values
  );
};

export const getUserCartByUserId = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/user-cart/${userId}`
  );
};

export const RemoveFromCart = async (
  userId,
  productId,
  productVariantsId,
  subsId
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove-cart-item`,
    {
      userId,
      productId,
      productVariantsId,
      subsId,
    }
  );
};

export const updateQuantity = async (
  userId,
  productId,
  productVariantsId,
  quantity,
  subsId
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/cart-quantity-increment`,
    {
      userId,
      productId,
      productVariantsId,
      quantity,
      subsId,
    }
  );
};

export const increaseQuantityOne = async (
  userId,
  productId,
  productVariantsId,
  subsId,
  quantity
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/cart-quantity-increment-one`,
    {
      userId,
      productId,
      productVariantsId,
      subsId,
      quantity,
    }
  );
};

export const decreaseQuantityOne = async (
  userId,
  productId,
  productVariantsId,
  subsId,
  quantity
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/cart-quantity-decrement-one`,
    {
      userId,
      productId,
      productVariantsId,
      subsId,
      quantity,
    }
  );
};

export const increaseQuantityGuestCartData = async (
  productId,
  productVariantsId,
  subsId,
  guestCartData
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/guest-cart-quantity-increment-one`,
    {
      productId,
      productVariantsId,
      subsId,
      guestCartData
    }
  );
};

export const decreaseQuantityGuestCartData = async (
  productId,
  productVariantsId,
  subsId,
  guestCartData
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/guest-cart-quantity-decrement-one`,
    {
      productId,
      productVariantsId,
      subsId,
      guestCartData,
    }
  );
};

export const RemoveFromGuestCart = async (
  productId,
  productVariantsId,
  subsId,
  guestCartData
) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove-guest-cart-item`,
    {
      productId,
      productVariantsId,
      subsId,
      guestCartData
    }
  );
};
