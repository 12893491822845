import axios from "axios";

// export const CategoryProductList = async (option, categoryid) => {
//   return await axios.post(
//     `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/category-product/${option}/${categoryid}`
//   );
// };

export const CategoryProductListData = async (option, categories, variants) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/category-product-list`,
    {
      option,
      categories,
      variants,
    }
  );
};

export const CategoryProductListNew = async (CID) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/category-product-listing`,
    CID
  );
};

export const SubscriptionProductList = async (CID) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/subscription-product-list`,
    CID
  );
};

export const getProductsDetails = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/product-details/${_id}`
  );
};

export const getProductsOptions = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/product/product-options/${productId}`
  );
};
