import axios from "axios";

export const createOrderInOneGo = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/orders-in-one-go`,
    values
  );
};

export const createOrderInOneGoHeartLand = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/heartland/orders-in-one-go`,
    values
  );
};

export const getLatestOrderByUser = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/latest-order-by-user/${userId}`
  );
};
export const removeOrderByUser = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/remove/order-by-user/${_id}`
  );
};

export const getOrdersByUserId = async (userId) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/orders-by-user-id/${userId}`
  );
};

export const getOrderToUser = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/order-to-user/${_id}`
  );
};

export const updateOrderByUser = async (_id, value) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/update/order-by-user/${_id}`,
    { deliveryTime: value }
  );
};

export const cancelOrderSubscription = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/get/cancel-order-subscription/${id}`
  );
};

export const getOrderByToken = async (token, payerID) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/check/payment`,
    { token, payerID }
  );
};

export const checkSubscription = async (subscription_id, ba_token, token) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/check/subscriptions`,
    { subscription_id, ba_token, token }
  );
};

export const cancelSubscription = async (subscription_id) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/check/cancelSubscriptions`,
    { token: subscription_id }
  );
};

export const paymentCancel = async (token) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/check/cancelPayment`,
    { token }
  );
};

// export const downloadInvoicePDF = async (orderId) => {
//   return await axios.post(
//     `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/ordersInvoicePDF`,
//     orderId,
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//       responseType: "blob",
//     }
//   );
// };

export const downloadInvoicePDF = async (orderId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/ordersInvoicePDF`,
      { orderId },
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );

    console.log("response ", response);
    return response;
  } catch (error) {
    console.log("Error while generating PDF:", error);
    throw error;
  }
};
export const downloadOrderDetailsPDF = async (orderId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/orderDetailsPDF`,
      { orderId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const pdfUrl = response.data; // Assuming the server returns the PDF URL in the response

    return pdfUrl;
  } catch (error) {
    console.log("Error while generating PDF:", error);
    throw error;
  }
};

// export const downloadInvoicePDF = async (orderId) => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/ordersInvoicePDF`,
//       { orderId },
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     const pdfUrl = response.data; // Assuming the server returns the PDF URL in the response

//     console.log("PDF URL:", pdfUrl);
//     return pdfUrl;
//   } catch (error) {
//     console.log("Error while generating PDF:", error);
//     throw error;
//   }
// };
