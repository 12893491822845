import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import BradeCrum from "../Component/BradeCrum";
import Footer from "../Component/Footer";
import ProductImg from "../assets/img/productImg.png";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";

import { FaCoffee } from "react-icons/fa";
import MobileHeader from "../Component/MobileHeader";
import "./../assets/css/location.css";
import { FaLocationDot } from "react-icons/fa6";

import { Puff } from "react-loader-spinner";

import {
  findLocationbyState,
  listLocationDetails,
} from "../functions/Location/companyLocation";
import { listState, getState } from "../functions/Location/Location";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";

const Location = () => {
  const [CID, setCID] = useState("");

  const [stateData, setStateData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [stateName, setStateName] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [showAllLocations, setShowAllLocatinos] = useState(false);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    listCompanynDetails().then((res) => {
      setShowAllLocatinos(res.data[0].IsShowAllLocations);
    });
  }, []);

  useEffect(() => {
    let sortedData = "";
    listState().then((res) => {
      sortedData = res.data.sort((a, b) =>
        a.StateName.localeCompare(b.StateName)
      );
      setStateData(sortedData);
      console.log("sss", sortedData);
      const CID = sortedData[0]._id;
      findLocationbyState(CID).then((res) => {
        console.log("location", res.data);
        // if(res.data.length>0){
        setLocationData(res.data);

        getState(sortedData[0]._id).then((res1) => {
          console.log("state data", res1.data);
          setStateName(res1.data.StateName);
        });
        // }else{

        // }
      });
    });
  }, []);

  const loadStates = () => {
    listState().then((res) => {
      const sortedData = res.data.sort((a, b) =>
        a.StateName.localeCompare(b.StateName)
      );
      setStateData(sortedData);
      // getState(sortedData[0]._id).then((res1) => {
      //   console.log("state data", res1.data);
      //   setStateName(res1.data.StateName);
      // });
    });
  };

  const loadLocations = () => {
    if (CID == "ALL") {
      listLocationDetails().then((res) => {
        setLocationData(res.data);
        setStateName("All Locations");
      });
    } else {
      findLocationbyState(CID)
        .then((res) => {
          setLocationData(res.data);
          getState(CID)
            .then((res1) => {
              console.log("state data", res1.data);
              setStateName(res1.data.StateName);
            })
            .catch((err) => {
              console.log("error in find state", err);
            });
        })
        .catch((err) => {
          console.log("error in find location", err);
        });
    }

    // findLocationbyState(CID)
    // .then((res) => {
    //   console.log("location", res.data);
    //   setLocationData(res.data);

    //   listState().then((res2) => {
    //     res2.data.map((cd) => {
    //       console.log("inn", cd._id, CID);
    //       if (cd._id === CID) {
    //         getState(res.data[0].StateID)
    //           .then((res1) => {
    //             console.log("state data", res1.data);
    //             setStateName(res1.data.StateName);
    //           })
    //           .catch((err) => {
    //             console.log("error in find state", err);
    //           });
    //       }
    //     });
    //   });

    //   // }
    // })
    // .catch((err) => {
    //   console.log("error in find location", err);
    // });

    // let sortedData = "";
    // listState().then((res) => {
    //   sortedData = res.data.sort((a, b) =>
    //     a.StateName.localeCompare(b.StateName)
    //   );
    //   setStateData(sortedData);
    //   console.log("sss", sortedData);
    //   const CID = sortedData[0]._id;
    //   findLocationbyState(CID).then((res) => {
    //     console.log("location", res.data);
    //     // if(res.data.length>0){
    //     setLocationData(res.data);

    //     getState(sortedData[0]._id).then((res1) => {
    //       console.log("state data", res1.data);
    //       setStateName(res1.data.StateName);
    //     });
    //     // }else{

    //     // }
    //   });
    // });
  };

  useEffect(() => {
    loadLocations();
  }, [CID]);

  // const loadLocations = () => {
  //   listLocationDetails().then((res) => {
  //     setLocationData(res.data);
  //   });
  // };

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Hader />
          <MobileHeader />

          <BradeCrum />
          <Container className="sec_ptb_120">
            <Row>
              <Col lg={4}>
                <div
                  className="sb_widget sb_category wow fadeInUp"
                  data-wow-delay=".1s"
                >
                  <h3 className="sb_widget_title text-uppercase">Locations</h3>
                  <ul className="ul_li_block">
                    {showAllLocations && (
                      <li>
                        <Link onClick={() => setCID("ALL")}>
                          <span>
                            <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                            All Locations
                          </span>
                        </Link>
                      </li>
                    )}
                    {stateData.map((cd) => {
                      return (
                        <li key={cd._id}>
                          <Link
                            // to={`/productListing/${cd._id}`}
                            onClick={() => setCID(cd._id)}
                          >
                            <span>
                              <FaAnglesRight className="fal fa-angle-double-right" />{" "}
                              {cd.StateName}
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
              <Col>
                <Row>
                  <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                    {/* <FaCoffee className="fas fa-coffee" />  */}
                    <FaLocationDot />

                    {stateName}
                  </h2>
                  <Row>
                    {locationData.map((data) => {
                      return (
                        <Col lg={4}>
                          <Link

                          // onClick={(e) => {
                          //   if (data.LocationUrl && data.LocationUrl !== "#" && data.LocationUrl !== "") {
                          //     window.open(data.LocationUrl, "_blank");
                          //   } else {
                          //     e.preventDefault();
                          //    }
                          //  }}
                          >
                            <Card className="locationCardDiv">
                              <div>
                                {data.Location}
                              </div>
                              <div>
                                {data.Address}
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Location;
